import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { IMFPPStore } from '../../../modules/stores';
import styles from './../../../styles/pages.module.scss';
import disclaimerStyles from './reports.page.module.scss';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Helmet } from 'react-helmet-async';
import { useReportHeaders } from '../../../modules/hooks/theaders/reports.headers';
import { useReportsActions } from '../../../modules/hooks/actions/report.actions';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import {
	Entities,
	groupByMapper,
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsFilterType,
	IPerformanceOptionsSortBy,
	useReportsList,
} from '../../../modules/hooks/apis/use-reports-list';
import { PublifestoChart } from '../../../modules/components/chart/publifesto-chart';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { isAbortError } from '@monorepo/tools/src/lib/tools/ask/guards';
import { isFetchFromBackend } from '../../../modules/helpers/reports.helpers';
import { PaginationState } from '@tanstack/react-table';
import { PublishersApi } from '../../../modules/apis/publishers.api';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useSearchParams } from 'react-router-dom';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

dayjs.extend(utc);
dayjs.extend(timezone);

const dallasDate = dayjs().tz('America/Chicago').subtract(25, 'hours').format('MMM DD, YYYY');

const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'reports_table')] };

export const Reports: FC = observer(() => {
	const { publisherStore, toastsStore } = useStores<IMFPPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const [searchParam] = useSearchParams();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();
	const accountId = searchParam.get('accountId');
	const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);
	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val);

	const {
		fetchReports,
		reportsError: tableDataError,
		isLoading: isTableDataLoading,
		data: tableData,
		fetchParams,
	} = useReportsList(
		publisherStore.getReportsStore(),
		{
			limit: 5000,
			entity: Entities.Account,
			groupBys: groupByColumns as string[],
			segment: groupByColumns.includes('date') ? IPerformanceOptionsDateSegments.Daily : undefined,
			filters: accountId ? [{ column: 'id', value: [accountId as string], filterType: IPerformanceOptionsFilterType.In }] : [],
			sortBys: defaultSortBy?.map(sort => {
				return {
					column: sort.id as IPerformanceOptionsSortBy,
					order: sort.desc ? 'desc' : 'asc',
				};
			}),
		},
		undefined
	);

	const {
		fetchReports: fetchMetrics,
		reportsError: graphError,
		isLoading: isGraphDataLoading,
		data: graphData,
	} = useReportsList(
		publisherStore.getMetricsStore(),
		{
			limit: 5000,
			entity: Entities.Chart,
			segment: IPerformanceOptionsDateSegments.Daily,
			groupBys: groupByColumns as string[],
			// groupBys: [PerformancesColumns.Date, IPerformanceOptionsSortBy.AccountId],
			filters: accountId ? [{ column: 'id', value: [accountId as string], filterType: IPerformanceOptionsFilterType.In }] : [],
			sortBys: defaultSortBy?.map(sort => {
				return {
					column: sort.id as IPerformanceOptionsSortBy,
					order: sort.desc ? 'desc' : 'asc',
				};
			}),
		},
		undefined
	);

	const { columns } = useReportHeaders({ summary: publisherStore.getReportsStore().getData()?.getSummary() });
	const { FiltersActions } = useReportsActions();

	// TODO - uncomment and fix where backend is ready
	const { metrics, xLabels, legends } = useMetrics(graphData);

	const onDownloadReportFile = () => {
		const fileName = `Manifesto report ${dayjs(fetchParams.periodStart).format('YYYY/MM/DD')} - ${dayjs(fetchParams.periodEnd).format(
			'YYYY/MM/DD'
		)}`;
		const totalRows = tableData?.getTotalRows() || 0;
		if (totalRows <= 0) {
			toastsStore.addToast({
				msg: 'Cannot export empty file.',
				type: DispatchLogsTypes.Info,
			});
		} else if (totalRows >= 100000) {
			PublishersApi.requestCsv({ ...fetchParams, limit: 100000 }, { queryParams: { fileName } });

			toastsStore.addToast({
				msg: 'Exporting to CSV, an email will be sent to you when the file is ready.',
				type: DispatchLogsTypes.Success,
			});
		} else {
			setIsLoadingCsv(true);
			useDownload({
				httpStore: new HttpStore<undefined, File | null>({
					httpFunc: () => PublishersApi.downloadCsv({ ...fetchParams, limit: 100000 }).finally(() => setIsLoadingCsv(false)),
				}),
				fileName,
				extension: 'csv',
			});
		}
	};

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Manifesto - Statistics</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }} debugProps={{ dataAttrs: [new DataAttribute('id', 'pagebar')] }}>
				<PagebarTitle>Statistics</PagebarTitle>

				<PagebarActions>
					<ControlledDater minDate={dayjs().subtract(180, 'day').toDate()} />
				</PagebarActions>
			</Pagebar>
			<span className={disclaimerStyles.disclaimer}>Last update: {dallasDate}.</span>
			<span className={disclaimerStyles.disclaimer}>
				The data in the table is limited to 5K rows, to receive the full data please use the export button or the API reporting
			</span>
			<PublifestoChart
				isLoading={isGraphDataLoading}
				metrics={metrics}
				labels={xLabels}
				legendOptions={legends}
				isError={!!graphError && !isAbortError(graphError)}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}
			/>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				classes={{ wrapper: styles.padding }}
				debugProps={tableDebugProps}
				columns={columns}
				isGlobalFilter={false}
				data={tableData?.getData() || []}
				isLoading={isTableDataLoading}
				fetchReports={fetchReports}
				fetchMetrics={fetchMetrics}
				onExportToCsv={() => {
					sendGtagEvent({
						action: TrackingActions.Click,
						category: EVENTS.CLICK.INDEX_PAGE.ACTION_LINER.EXPORT,
						label: 'Export Reports CSV',
						value: window.location.href,
					});
					onDownloadReportFile();
				}}
				isLoadingCsv={isLoadingCsv}
				isSummary={true}
				defaultSortBy={defaultSortBy}
				isError={!!tableDataError && !isAbortError(tableDataError)}
				requestLimitedRows={5000}
				onPageChange={(state: PaginationState) => {
					if (isFetchFromBackend(tableData, state) && !isTableDataLoading) {
						fetchReports({ appendData: true, resetOffset: false });
					}
				}}>
				{() => {
					return {
						FiltersActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
