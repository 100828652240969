import { observer } from 'mobx-react';
import { FC } from 'react';
import styles from '../../../styles/pages.module.scss';
import { Helmet } from 'react-helmet-async';
import { Page } from '@monorepo/base/src/components/page/page';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { InventoryReportDocumantation, TitlesAndNotesComponent } from '../docs-content';

export const InventoryReport: FC = observer(() => {
	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Docs - Inventory Report</title>
			</Helmet>
			<Flex className={styles.markdown} padding={'0rem 4rem 4rem 4rem'} flexDirection={'column'} alignItems={'start'}>
				<TitlesAndNotesComponent />
				<InventoryReportDocumantation />
			</Flex>
		</Page>
	);
});
