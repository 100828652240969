import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { hotjarScript } from '@monorepo/tools/src/lib/scripts/hotjar';
import { Envs } from '@monorepo/tools/src/lib/enums/env';
import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { Analytics } from '@monorepo/tools/src/lib/scripts/analytics';

const _Head = () => {
	const config = getConfig();
	const callHotjarScript =
		config.env.projectEnv === Envs.Production && config.scripts.hotjar.id && config.scripts.hotjar.version ? (
			<script type="text/javascript">{hotjarScript(config.scripts.hotjar.id, config.scripts.hotjar.version)}</script>
		) : (
			<script type="text/javascript">{hotjarScript(config.scripts.hotjar.id, config.scripts.hotjar.version)}</script>
		);

	const ga4Instance = Analytics.getInstance({
		ga4: { gtagIds: config?.scripts?.ga4?.gtagIds, supportedEnvs: new Set<Envs>([Envs.Develop, Envs.Develop, Envs.Production]) },
	})?.getGa4Instance();

	return (
		<Helmet>
			{callHotjarScript}
			{ga4Instance?.ga4ExternalScript()}
			{ga4Instance?.ga4InternalScript()}
		</Helmet>
	);
};
export const Head = memo(_Head);
