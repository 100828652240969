import { Fragment } from 'react';
import { getConfig, IConfig } from '../get-config';
import { Envs } from '../enums/env';
import { AnalyticsEventMessageType } from './analytics';

export type Ga4ConstructorParamsType = { gtagIds: string | string[]; supportedEnvs?: Set<Envs> };

export class Ga4 {
	private static instance: Ga4;
	private readonly gtagId?: string;
	private readonly config: IConfig<unknown>;
	private readonly supportedEnvs = new Set<Envs>([Envs.Production, Envs.Staging]);

	private constructor(params: Ga4ConstructorParamsType) {
		this.config = getConfig();
		if (params?.supportedEnvs instanceof Set) {
			this.supportedEnvs = params.supportedEnvs;
		}
		if (!this.supportedEnvs.has(this.config.env.projectEnv as Envs)) {
			return;
		}
		let gtagIds = params?.gtagIds;

		if (typeof gtagIds === 'string' && gtagIds.length) {
			gtagIds = gtagIds.split(',');
		}

		if (Array.isArray(gtagIds)) {
			gtagIds = gtagIds.map(gtagId => gtagId?.trim?.() || '').filter(Boolean);
		}

		if (!Array.isArray(gtagIds) || !gtagIds.length) {
			return;
		}
		this.gtagId = gtagIds[Math.floor(Math.random() * gtagIds.length)];
	}

	ga4ExternalScript = () => {
		if (!this.gtagId) {
			return null;
		}

		return <script id={'ga4-init-script-url'} async src={`https://www.googletagmanager.com/gtag/js?id=${this.gtagId}`}></script>;
	};

	ga4InternalScript = () => {
		if (!this.gtagId) {
			return null;
		}

		const scriptContent = `
		console.log('GA4 init');
		window.dataLayer = window.dataLayer || [];
		function gtag(){ window.dataLayer.push(arguments); }
		if (!window.gtag){
			window.gtag = gtag;
		}
		
		gtag('js', new Date());
		gtag('config', '${this.gtagId}',{
			// sessionId:'' // Todo: add the session id somehow
		});
	`;

		return <script id={'ga4-init-script-local'}>{scriptContent}</script>;
	};

	getInitScript = () => {
		if (!this.gtagId) {
			return null;
		}
		const scriptContent = `
				console.log('GA4 init');
                window.dataLayer = window.dataLayer || [];
                function gtag(){ window.dataLayer.push(arguments); }
                if (!window.gtag){
                    window.gtag = gtag;
                }
                
                gtag('js', new Date());
                gtag('config', '${this.gtagId}',{
                    // sessionId:'' // Todo: add the session id somehow
                });
		`;

		return (
			<Fragment key={'ga4-init-script'}>
				<script id={'ga4-init-script-url'} async src={`https://www.googletagmanager.com/gtag/js?id=${this.gtagId}`}></script>
				<script id={'ga4-init-script-local'}>{scriptContent}</script>
			</Fragment>
		);
	};

	async event(event: AnalyticsEventMessageType): Promise<void> {
		if (!event?.action?.length) {
			return;
		}
		await this.events([event]);
	}

	async events(events: AnalyticsEventMessageType[]): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (!Array.isArray(events) || !events.length || typeof (window as any)?.gtag !== 'function' || !this.gtagId) {
			return;
		}
		const validEvents = [];
		for (const e of events) {
			if (!e?.action?.length) {
				continue;
			}
			validEvents.push(e);
		}

		if (!validEvents.length) {
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (!Array.isArray((window as any)?.dataLayer)) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).dataLayer = [];
		}

		for (const e of validEvents) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).gtag('event', e.action, e.params);
		}
	}

	static getInstance(params: Ga4ConstructorParamsType): Ga4 | undefined {
		if (!this.instance && params?.gtagIds?.length) {
			return this.init(params);
		}
		return this.instance;
	}

	static init(params: Ga4ConstructorParamsType): Ga4 {
		this.instance = new Ga4(params);
		return this.instance;
	}
}
