import { useState, useEffect } from 'react';

export type TimerClasses = {
	timerWrapper?: string;
	timer?: string;
	beforeText?: string;
	afterText?: string;
};

interface ITimer {
	initialTime: number;
	handleTimeup?: () => void;
	classNames?: TimerClasses;
	isDisappeaerOnTimeup?: boolean;
	beforeText?: string;
	afterText?: string;
}

export const Timer = (props: ITimer) => {
	const { initialTime, classNames, handleTimeup, isDisappeaerOnTimeup, beforeText, afterText } = props;
	const [time, setTime] = useState(initialTime);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime(prevTime => {
				if (prevTime === 0) {
					clearInterval(intervalId);
					handleTimeup && handleTimeup();
					// Optionally, you can add a callback function here when the timer reaches 0.
				}
				return prevTime > 0 ? prevTime - 1 : 0;
			});
		}, 1000);

		// Clean up the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, [initialTime, handleTimeup]);

	const formatTime = (timeInSeconds: number) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = timeInSeconds % 60;
		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	if (isDisappeaerOnTimeup && time === 0) {
		return null;
	}

	return (
		<div className={classNames?.timerWrapper}>
			{beforeText && <span className={classNames?.beforeText}>{beforeText}</span>}
			<span className={classNames?.timer}>{formatTime(time)}</span>
			{afterText && <span className={classNames?.afterText}>{afterText}</span>}
		</div>
	);
};

export default Timer;
