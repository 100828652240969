import { observer } from 'mobx-react';
import { Fragment, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../routes/index.routes';
import { IMFPPStore } from '../../stores';
import { ask, IRequestInterceptor, IResponseInterceptor } from '@monorepo/tools/src/lib/tools/ask/ask';
import { useUser } from '../../hooks/apis/use-user';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';

interface IRequireAuth {
	children: ReactNode;
}

/**
 * Redirect users to login page in case of user not authenticated
 */
export const RequireAuth = observer((props: IRequireAuth) => {
	const { children } = props;
	const { authStore, userStore } = useStores<IMFPPStore>();
	const token = authStore.getToken();
	const location = useLocation();
	const user = userStore.getUser(); // not permissions

	// Must be before requests
	const authInterceptor: IRequestInterceptor = {
		call: (url, requestOptions) => {
			if (token) {
				requestOptions.headers = {
					Authorization: `Bearer ${ActAsHelper.hasPUV() ? ActAsHelper.getToken() : token}`,
					...requestOptions.headers,
				};
			}
		},
	};
	ask.interceptors.requests.set('Auth', authInterceptor);
	const updateTokenInterceptor: IResponseInterceptor = {
		success: response => {
			if (ActAsHelper.hasPUV()) {
				return;
			}
			const userToken = response.headers.get('User-Token');
			if (!userToken) {
				return null;
			}
			authStore.setToken(userToken);
		},
	};
	ask.interceptors.responses.set('UpdateToken', updateTokenInterceptor);

	const { isLoading: isUserLoading, isSuccess: isUserSuccess, error: userError } = useUser();

	const authToken = authStore.getToken();
	if (!authToken || !user?.id || !user?.isVerified()) {
		console.warn('Error code 654655');
		userStore.removeUser();
		authStore.removeToken(false);
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to={routes.auth.login()} state={{ from: location }} replace />;
	}

	// User finished loading
	if (!isUserLoading && isUserSuccess) {
		//in case user doesn't have any permissions he will be kicked
		if (Object.keys(user?.getDomainPermissions() || {}).length === 0) {
			console.warn('Error code 759961');
			userStore.removeUser();
			authStore.removeToken(false);
			return <Navigate to={routes.auth.login()} state={{ from: location }} replace />;
		}
		return <Fragment>{children}</Fragment>; // The fragment is just for not typescript yelling
	}

	if (userError) {
		userStore.removeUser();
		authStore.removeToken(false);
		return <Navigate to={routes.auth.login()} state={{ from: location }} replace />;
	}

	return <BarLoader is={true} />;
});
