import { observer } from 'mobx-react';
import styles from '../billing-form.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Divider } from '@monorepo/base/src/components/divider/divider';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPPStore } from '../../../../stores';
import countriesJson from '@monorepo/tools/src/lib/consts/geos.json';
import { objectToSelectOptions } from '@monorepo/tools/src/lib/utils/object';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';

export const Wire = observer(() => {
	const { billingStore } = useStores<IMFPPStore>();
	const billingCrudStore = billingStore.getCrud();
	const formStore = billingCrudStore.getFormStore();
	const billing = billingCrudStore.getData();
	const contriesObj = countriesJson as { [key: string]: string };
	const defaultGeo = billing?.getGeo() || 'US';
	const isLoading = billingCrudStore.getIsLoading();

	return (
		<div className={styles.wire}>
			<span className={styles.disclaimer}>
				to receive payments via wire transfer, a payment request must be sent to&nbsp;
				<a href="mailto: accounting@intango.com">accounting@intango.com</a>&nbsp; for each payment no later than the 10th of every
				month.
			</span>

			<div className={styles.payeeInformation}>
				<h3>Payee information</h3>
				<div className={styles.row}>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Payee name'}
						required
						value={billing.getPayeeName()}
						onValue={value => {
							billing.setPayeeName(value);
							formStore.clearError('payeeName');
						}}
						error={formStore.getErrors().get('payeeName')?.getMsg()}
					/>

					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Billing address'}
						required
						value={billing.getBillingAddress()}
						onValue={value => {
							billing.setBillingAddress(value);
							formStore.clearError('billingAddress');
						}}
						error={formStore.getErrors().get('billingAddress')?.getMsg()}
					/>
				</div>
				<div className={styles.row}>
					<DropdownNew
						disabled={isLoading}
						classNames={{ dropdownWrapper: styles.dropdown }}
						required={true}
						defaultValues={[defaultGeo]}
						label={'Country'}
						autocomplete={true}
						autocompletePlaceholder={'Search Country'}
						options={objectToSelectOptions(contriesObj)}
						onSelect={geo => {
							billing?.setGeo((geo[0] as string) || 'US');
							formStore.clearError('geos');
						}}
						error={formStore.getErrors().get('geos')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'country_dropdown')] }}
					/>

					<Input
						type="number"
						min={500}
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Payment threshold'}
						value={billing.getPaymentThreshold()?.toString()}
						onValue={value => {
							billing.setPaymentThreshold(Number(value));
							formStore.clearError('paymentThreshold');
						}}
						error={formStore.getErrors().get('paymentThreshold')?.getMsg()}
						required
						bottomLabel={
							formStore.getErrors().get('paymentThreshold')?.getMsg()
								? undefined
								: 'Our minimum payment threshold is $500, you can increase it.'
						}
					/>
				</div>
			</div>

			<Divider id={styles.middleDivider} />

			<div className={styles.bankInformation}>
				<h3>Bank information</h3>
				<div className={styles.row}>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Bank Name'}
						required
						value={billing.getBankName()}
						onValue={value => {
							billing.setBankName(value);
							formStore.clearError('bankName');
						}}
						error={formStore.getErrors().get('bankName')?.getMsg()}
					/>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Bank Number'}
						required
						value={billing.getBankNumber()}
						onValue={value => {
							billing.setBankNumber(value);
							formStore.clearError('bankNumber');
						}}
						error={formStore.getErrors().get('bankNumber')?.getMsg()}
					/>
				</div>
				<div className={styles.textareaRow}>
					<Textarea
						disabled={isLoading}
						requiredAsterisk={false}
						rows={2}
						required={true}
						placeholder={'Bank Address'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'bank_address')] }}
						wrapperClassName={styles.textarea}
						labelClassName={styles.textareaLabel}
						value={billing.getBankAddress()}
						onValue={value => {
							billing.setBankAddress(value);
							formStore.clearError('bankAddress');
						}}
						error={formStore.getErrors().get('bankAddress')?.getMsg()}
					/>
				</div>

				<div className={styles.row}>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Bank Account Number'}
						required
						value={billing.getBankAccountNumber()}
						onValue={value => {
							billing.setBankAccountNumber(value);
							formStore.clearError('bankAccountNumber');
						}}
						error={formStore.getErrors().get('bankAccountNumber')?.getMsg()}
					/>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Bank Account Name'}
						required
						value={billing.getBankAccountName()}
						onValue={value => {
							billing.setBankAccountName(value);
							formStore.clearError('bankAccountName');
						}}
						error={formStore.getErrors().get('bankAccountName')?.getMsg()}
					/>
				</div>
				<div className={styles.row}>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Sort Code'}
						required
						value={billing.getSortCode()}
						onValue={value => {
							billing.setSortCode(value);
							formStore.clearError('sortCode');
						}}
						error={formStore.getErrors().get('sortCode')?.getMsg()}
					/>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Swift'}
						required
						value={billing.getSwift()}
						onValue={value => {
							billing.setSwift(value);
							formStore.clearError('swift');
						}}
						error={formStore.getErrors().get('swift')?.getMsg()}
					/>
				</div>
				<div className={styles.row}>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						wrapperId={styles.lastInput}
						className={styles.input}
						topLabel={'IBAN'}
						required
						value={billing.getIban()}
						onValue={value => {
							billing.setIban(value);
							formStore.clearError('iban');
						}}
						error={formStore.getErrors().get('iban')?.getMsg()}
					/>
				</div>
			</div>
		</div>
	);
});
