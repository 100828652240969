import { FC } from 'react';
import styles from './privacy-policy.module.scss';
import { Helmet } from 'react-helmet-async';

export const PrivacyPolicy: FC = () => {
	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>Manifesto - Privacy Policy</title>
			</Helmet>
			<div className={styles.paragraphsWrapper}>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Manifesto Publishers’ Platform Privacy Policy</div>
					<div className={styles.date}>Last updated: February 5th, 2024</div>
					<div className={styles.content}>
						<p>
							Manifesto Publishers’ Platform pays great attention to protecting your personal data and complying with the law
							when it collects, processes, and uses data. We want you to feel safe when you use our services – and that is why
							we are providing you with this Privacy Policy.
						</p>
						<p>(“Manifesto Publishers’ Platform”, “we”, “us”, “Services” or “Platform”).</p>
						<p>
							This Privacy Policy applies to our Manifesto Publishers’ Platform activities. This policy covers Manifesto
							Publishers’ Platform collection and usage of personal information for our platform functions. We may collect
							this information through your use of our platform. This policy details the process that we intake in order to
							collect this information, how such information may be used and/or shared with others, how we safeguard and
							retain it and how you may access and control its use in connection with our marketing communications and product
							usage.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>QUICK GUIDE TO CONTENT</div>
					<div className={styles.content}>
						<ol>
							<li>
								<a href={'#1'}>Information we collect</a>
							</li>
							<li>
								<a href={'#2'}>How we use the Information we collect</a>
							</li>
							<li>
								<a href={'#3'}>Sharing of Information</a>
							</li>
							<li>
								<a href={'#4'}>Notice to EU residents under “GDPR”</a>
							</li>
							<li>
								<a href={'#5'}>Notice to U.S. residents under the applicable privacy laws</a>
							</li>
							<li>
								<a href={'#6'}>Managing cookies (“opt-out”) or unsubscribing</a>
							</li>
							<li>
								<a href={'#7'}>Data retention and deletion</a>
							</li>
							<li>
								<a href={'#8'}>Children</a>
							</li>
							<li>
								<a href={'#9'}>Security of your information</a>
							</li>
							<li>
								<a href={'#10'}>Consent to processing and transfer of information</a>
							</li>
							<li>
								<a href={'#11'}>Changes</a>
							</li>
							<li>
								<a href={'#12'}>Contact us</a>
							</li>
						</ol>
					</div>
				</div>
				<div id={'1'} className={styles.paragraph}>
					<div className={styles.heading}>1. Information We Collect</div>
					<div className={styles.content}>
						<p>
							Upon your initial registration to our platform, we may collect the following: name and surname, e-mail address,
							company name, and company size.
						</p>
						<p>
							Whenever you visit or interact with our Platform, we may use a variety of technologies that automatically or
							passively collect information about how the Platform is accessed and used. Manifesto Publishers’ Platform uses
							both cookies and non-cookie technologies in order to collect and retain data.
						</p>
						<p>
							We may use cookies and other technologies on the Platform itself to enhance your personal experience as we aim
							to personalize our Platform to better meet your needs. We may also use this information for the purposes of
							analytics and monitoring of the effectiveness of our performance, including the collection of the aggregate
							usage data (such as the overall number of visitors or pages viewed etc.).
						</p>
						<p>
							We may collect the following data through the use of our Platform: preferred language, e-mail, registration
							date, company name, company URL, full name, messenger type, messenger id, referral channel, registration
							country, user agent, IP address, device, browser, browser version, operating system, page served, clicks, time,
							referring URLs, errors, GEO.
						</p>
						<p>
							Some of the user agent data above provides us with statistical data that contains information about the use of
							the Platform, such as how many visitors visit a specific page and how long they stay on that page.
						</p>
						<p>
							Usage information mentioned above is generally non-identifying, but to the extent Usage Information may be
							associated with you as a specific and identifiable person (for example, because you have registered for a
							Service) and thereby provided us with personal information (such as name or e-mail address) we treat such
							information as “Personal Information” and will only use it for the purposes described in this Privacy Policy.
						</p>
						<p>
							Our Platform uses several different technologies in relation to data collection. These may include, without
							limitation:
						</p>
					</div>
					<div className={styles.subHeading}>Cookies -</div>
					<div className={styles.content}>
						<p>
							We may place cookies or similar technologies like pixels on your Device for a variety of different reasons
							including security purposes, to facilitate site navigation, and analytics, and to personalize your experience
							while using the Platform.
						</p>
						<p>
							Why do we collect cookies? As cookies allow us to collect technical and navigational information, such as
							browser type, time spent on the Platform and pages visited. Cookies may include a device identifier along with
							information about the content in which you have shown interest. Cookies also allow us to select which content is
							most likely to appeal to you and display that content while you are using the Platform.
						</p>
						<p>
							If you would prefer not to accept cookies, you can do this by activating the settings on your browser that allow
							you to refuse the setting of all or some cookies refusing some cookies may affect your user experience.
						</p>
						<p>
							Our third-party affiliates/service providers may also use cookies, scripts, web beacons or other technologies
							for the purpose of tracking and analysing your use of the Platform, for example, but not limited to:
						</p>
						<p>
							<b>HotJar</b> - placed to track and analyse the use of our Platform, for internal purposes and to collect
							information about your online usage activities across our Platform. You can learn more about HotJar’s privacy
							practices at:&nbsp;
							<a target={'_blank'} href={'https://www.hotjar.com/privacy/'}>
								https://www.hotjar.com/privacy/
							</a>
							.
						</p>
						<p>
							<b>Google Analytics</b> – placed to track and analyse the use of our Platform, for internal purposes and to
							collect information about your online usage activities across our Platform. You can learn more about how Google
							uses data it collects via our use of Google Analytics Cookies, we recommend you review Google's Privacy Policy
							at:&nbsp;
							<a target={'_blank'} href={'https://www.google.com/policies/privacy/partners'}>
								https://www.google.com/policies/privacy/partners
							</a>
							.
						</p>
						<p>
							<b>Sentry.io</b> - placed to track, supervise and identify errors, bugs and other performance issues within the
							user's browser while interacting with our Services. You can learn more about Sentry.io privacy practices
							at:&nbsp;
							<a target={'_blank'} href={'https://sentry.io/privacy'}>
								https://sentry.io/privacy
							</a>
							.
						</p>
					</div>
					<div className={styles.subHeading}>Log Files -</div>
					<div className={styles.content}>
						<p>We may gather certain information automatically and store it in log files.</p>
						<p>
							This information may include geo, email, first name, last name, IP addresses, browser type, internet service
							provider, referring/exit pages, operating system, date/time stamp and clickstream data.
						</p>
						<p>
							We use this information, which does not identify individual users, to analyze trends, to detect fraud,
							administer the site, to track user's actions around the Platform and to gather demographic information about our
							entire user base.
						</p>
					</div>
					<div className={styles.subHeading}>Pixels -</div>
					<div className={styles.content}>
						<p>
							A pixel tag is a transparent graphic image, sometimes called a web beacon or tracking beacon, placed on a web
							page or in an e-mail, which indicates that a page or e-mail has been viewed. We do not link Personal Information
							provided to us through the Platform to Personal Information. In addition, a pixel tag may tell your browser to
							get content from another server.
						</p>
					</div>
				</div>
				<div id={'2'} className={styles.paragraph}>
					<div className={styles.heading}>2. How we use the Information we collect</div>
					<div className={styles.content}>
						<p>
							We use the information we collect about and from you for a variety of business purposes, including but not
							limited to the following examples:
						</p>
						<ul style={{ listStyleType: 'disc', paddingInlineStart: '24px' }}>
							<li>
								To respond to your questions and requests; to provide you with access to certain functions and features of
								the Platform;
							</li>
							<li>To provide and maintain your Platform account(s);</li>
							<li>
								To verify your identity; to communicate with you about your account and activities and for marketing or
								solicitation purposes or to communicate changes to one of our policies;
							</li>
							<li>To tailor content or offer(s) we serve you;</li>
							<li>To improve the services for internal business purposes; to process applications and transactions;</li>
							<li>
								And for other lawful purposes we may disclose at the time you provide your Personal Information or to which
								you otherwise consent.
							</li>
						</ul>
					</div>
				</div>
				<div id={'3'} className={styles.paragraph}>
					<div className={styles.heading}>3. Sharing of Information</div>
					<div className={styles.content}>
						<p>We may disclose Personal Information as follows:</p>
						<ol className={styles.underTenOrderedList}>
							<li>With affiliates, parent companies, or subsidiaries.</li>
							<li>
								To vendors, service providers, agents, contractors, or others who perform functions (e.g., cloud vendors,
								hosting services, data analysis and analytics, customer relationship management, email marketing, delivering
								emails/messages, surveys, or fraud detection) on our behalf.
							</li>
						</ol>
						<p>
							These third parties have access to your Personal Information only to perform specific tasks on our behalf and
							are under written obligation not to disclose or use your Personal Information for any purpose other than those
							disclosed in this Privacy Policy.
						</p>
						<p>
							We may also disclose Personal Information when legally required to do so, to cooperate with law enforcement
							investigations or other legal proceedings, to protect against misuse or unauthorized use of the Platform, to
							limit our legal liability and protect our rights or to protect the rights, property or safety of users of this
							Platform, the Service or the public.
						</p>
						<p>
							In addition, we may share non-personal information, such as aggregate user statistics, demographic information
							and usage information with third parties. We may combine your usage information with those of other users of the
							Platform to share trend information with third parties, in aggregated and anonymized form.
						</p>
					</div>
				</div>
				<div id={'4'} className={styles.paragraph}>
					<div className={styles.heading}>4. Notice to EU Residents under “GDPR”</div>
					<div className={styles.content}>
						<p>
							This section applies to you if you are a national of a European Economic Area (“EEA”) member state. This section
							describes our good-faith effort to meet our obligations under the GDPR.
						</p>
						<div className={styles.subHeading}>Personal Data</div>
						<p>
							The GDPR, defines “personal data” “as …any information relating to an identified or identifiable natural person
							(‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in
							particular by reference to an identifier such as a name, an identification number, location data, an online
							identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic,
							cultural or social identity of that natural person” For the purposes of our Privacy Policy, the “data subject”
							is “you” (“your”, “user”). The personal data we collect may include, inter alia, IP Address and a unique User ID
							we may assign you, as described in this privacy policy.
						</p>
						<p>
							The personal data we collect will only be used and disclosed for the purposes detailed in sections 3 and 4 to
							this Privacy Policy.
						</p>
						<p>
							Our basis for processing personal data: GDPR Article 6.1(a):
							<i>
								“the data subject has given consent to the processing of his or her personal data for one or more specific
								purposes”; GDPR Article 6.1(b): “processing is necessary for the performance of a contract to which the data
								subject is party or in order to take steps at the request of the data subject prior to entering into a
								contract”; GDPR Article 6(1)(f): “processing is necessary for the purposes of the legitimate interests
								pursued by the controller or by a third party…”
							</i>
						</p>
						<div className={styles.subHeading}>Your Rights</div>
						<p>
							Please contact us by submitting an inquiry to the following email{' '}
							<a href="mailto:privacy@mani-festo.co">privacy@mani-festo.co</a> in case you wish to exercise your rights as a
							GDPR data subject, including:
						</p>
						<ol style={{ listStyleType: 'lower-alpha' }}>
							<li>Request a copy of the information, if any, stored about you.</li>
							<li>Correct information, if any, stored about you.</li>
							<li>Erase information, if any, stored about you.</li>
							<li>Restrict how information, if any, about you is used.</li>
							<li>Transfer the information, if any, about you.</li>
							<li>Object to how your information, if any, is used in certain cases.</li>
							<li>Request why we are unable to fulfill your request, if applicable.</li>
						</ol>
						<p>
							Please note that these rights are not absolute, and requests are subject to any applicable legal requirements,
							including gambling regulations and other legal and ethical reporting or document retention obligations. We may
							also rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion,
							in accordance with our internal policies.
						</p>
					</div>
				</div>
				<div id={'5'} className={styles.paragraph}>
					<div className={styles.heading}>5. Notice to U.S. Residents under the Applicable Privacy Laws</div>
					<div className={styles.content}>
						This Section applies to you if you are a natural person/consumer who is a resident of one of the states mentioned in
						this section, inter alia, California resident, as defined in the applicable laws and regulations.
					</div>
					<div className={styles.subHeading}>5.1. Notice to California Residents Under CCPA/CPRA</div>
					<div className={styles.content}>
						<p>
							We adopted this section to meet our obligations to comply with the California Consumer Privacy Act (the “CCPA”)
							and the California Privacy Right Act (the “CPRA”).
						</p>
						<p>Certain terms used in this section have the meanings given to them in the CCPA and the CPRA.</p>
					</div>
					<div className={styles.subHeading}>Personal Information We Collect</div>
					<div className={styles.content}>
						<p>
							We collect “Personal Information” which is defined under the CCPA as: “…information that identifies, relates to,
							describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a
							particular consumer or household.”
						</p>
						<p>For the purposes of our Privacy Policy, the “person” is “you” (“your”, “user”).</p>
					</div>
					<div className={styles.subHeading}>Collection and Disclosure</div>
					<div className={styles.content}>
						<p>During the 12-month period prior to the effective date of this Policy, we may have:</p>
					</div>
					<div className={styles.subHeading}>Collected the following categories of Personal Information about you:</div>
					<div className={styles.content}>
						<ul style={{ listStyleType: 'circle', paddingInlineStart: '24px' }}>
							<li>
								Identifiers (personal) (including, for example, name, online and device identifier, IP address, email
								address, account name and number).
							</li>
							<li>
								Commercial Information (including, for example, online browsing and website/platform interaction histories).
							</li>
							<li>
								Computing or mobile-device information and internet or other electronic-network-activity information
								(including, for example, login credentials; online advertisement engagements; and cookies, tags, and similar
								device or user identifying information).
							</li>
							<li>Geolocation information.</li>
						</ul>
					</div>
					<div className={styles.subHeading}>
						Collected Personal Information about you from the following categories of sources:
					</div>
					<div className={styles.content}>
						<ul style={{ listStyleType: 'circle', paddingInlineStart: '24px' }}>
							<li>You (for example, through your use of our Product).</li>
							<li>Your computing or mobile devices.</li>
							<li>Our technology (for example, through observed consumer interactions with us and through our Platform).</li>
							<li>Our services and systems.</li>
							<li>Our vendors.</li>
							<li>Public records.</li>
							<li>Unaffiliated third parties.</li>
						</ul>
					</div>
					<div className={styles.subHeading}>
						Collected Personal Information about you for the following business or commercial purposes (supplementing the
						information described in section 2 above to this Privacy Policy):
					</div>
					<div className={styles.content}>
						<ul style={{ listStyleType: 'circle', paddingInlineStart: '24px' }}>
							<li>Operating our Platform and services.</li>
							<li>
								Auditing related to a current interaction with you and concurrent transactions, including, but not limited
								to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and
								auditing compliance.
							</li>
							<li>
								Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and
								prosecuting those responsible for that activity.
							</li>
							<li>Undertaking internal research for technological development and optimization.</li>
							<li>Facilitating accounting, auditing, and reporting.</li>
							<li>
								Administering technology and ensure technology integrity (including, for example, by maintaining and
								improving networks; and identifying and fixing problems).
							</li>
							<li>
								To fulfill or meet the reason for which you provided the Personal Information (respond to a query, provide
								you with access to certain functions and features, support).
							</li>
						</ul>
					</div>
					<div className={styles.subHeading}>
						Shared your Personal Information for business or commercial purposes, with the following categories of third
						parties:
					</div>
					<div className={styles.content}>
						<ul style={{ listStyleType: 'circle', paddingInlineStart: '24px' }}>
							<li>Vendors who provide services on our behalf, including:</li>
						</ul>
						<ol style={{ paddingInlineStart: '24px', marginBlockStart: '-4px' }}>
							<li>Data center/host/cloud-service provider.</li>
							<li>Vendor (miscellaneous) services.</li>
						</ol>
					</div>
					<div className={styles.subHeading}>
						Disclosed to third parties for a business purpose the following categories of Personal Information about you:
					</div>
					<div className={styles.content}>
						<ul style={{ listStyleType: 'circle', paddingInlineStart: '24px' }}>
							<li>
								Identifiers (personal) (including, for example, name and surname, online and device identifier, IP address,
								email address, account name and number).
							</li>
							<li>
								Commercial Information (including, for example, online browsing and website interaction histories; and
								direct marketing histories).
							</li>
							<li>
								Internet or other electronic-network-activity information (including, for example, online advertisement
								engagements; cookies, tags, and similar device or user identifying information).
							</li>
							<li>Geolocation information.</li>
						</ul>
					</div>
					<div className={styles.subHeading}>
						Categories of third parties to whom Personal Information has been disclosed for a business purpose:
					</div>
					<div className={styles.content}>
						<ul style={{ listStyleType: 'circle', paddingInlineStart: '24px' }}>
							<li>Internet Service Providers.</li>
							<li>Data Analytics Providers.</li>
							<li>Operating Systems and Platforms.</li>
						</ul>
						<p>In the preceding twelve (12) months, we have not sold personal information.</p>
						<p>
							We also do not use or disclose any sensitive Personal Information for purposes other than those authorized by
							you or as permitted by law.
						</p>
					</div>
					<div className={styles.subHeading}>Your Rights</div>
					<div className={styles.content}>
						<p>
							You may be entitled to the following specific rights under the CCPA and the CPRA regarding your Personal
							Information:
						</p>
						<p>You have the right to opt-out of the “selling” or “sharing” of your Personal Information</p>
						<p>
							You have the right to opt out of “targeted ads” or “profiling,” as these terms are defined under the privacy
							laws
						</p>
						<p>
							You may have the right to request, twice in a 12-month period (depending on the law in your state), the
							following information about the Personal Information we have collected about you during the past 12 months:
						</p>
						<ol style={{ paddingInlineStart: '24px' }}>
							<li>The categories and specific pieces of Personal Information we have collected about you;</li>
							<li>The categories of sources from which we collected the Personal Information;</li>
							<li>The business or commercial purpose for which we collected the Personal Information;</li>
							<li>The categories of third parties with whom we shared the Personal Information;</li>
							<li>
								The categories of Personal Information about you that we disclosed for a business purpose, and the
								categories of third parties to whom we disclosed that information for a business purpose.
							</li>
						</ol>
						<p>
							In addition, you have the right to request that we delete or correct the Personal Information we have collected
							from you.
						</p>
						<p>
							To help protect your privacy and maintain security, we take steps to verify your identity before granting access
							to information or complying with a request. To the extent permitted by applicable law, we may charge a
							reasonable fee to comply with your request.
						</p>
						<p>
							Right to Non-Discrimination or Retaliatory Treatment for Exercise of Privacy Rights: You have the right to
							exercise your privacy rights without receiving discriminatory or retaliatory treatment. If you exercise any of
							your privacy rights, including the right to restrict the selling or sharing of your Personal Information, you
							will not be treated differently from those who do not exercise their privacy rights.
						</p>
					</div>
					<div className={styles.subHeading}>
						5.2. Additional Rights and Provisions for Colorado, Connecticut and Virginia Residents
					</div>
					<div className={styles.content}>
						<p>
							This section applies to Colorado, Connecticut and Virginia Residents as defined by the Colorado Privacy Act
							(“CPA”), Connecticut Data Privacy Act (“CTDPA”), and Virginia Consumer Data Protection Act (“VCDPA”).
							Capitalized terms defined in the CTDPA, CPA, VCDPA that are used in this section shall have the same meaning as
							in the CTDPA, CPA and VCDPA.
						</p>
						<p>
							This section describes our collection, use, and disclosure of personal information. You can learn more about the
							personal information we process, how we process the personal information, the purpose for processing such
							personal information and the categories of third parties with whom we share such data, in Section 5.1. above.
						</p>
						<p>We do not sell personal information (as defined under the CTDPA, CPA, VCDPA) for monetary consideration.</p>
						<p>
							If you are a Colorado, Connecticut, or Virginia Consumer, subject to certain conditions and restrictions set out
							in the CTDPA, CPA, VCDPA and other applicable laws, you may have certain rights regarding your personal
							information (as applicable, depending on where you reside), as described below:
						</p>
						<ol style={{ paddingInlineStart: '24px' }}>
							<li>Access the personal information that we may hold about you.</li>
							<li>Request that we correct inaccuracies in your personal information.</li>
							<li>Request that we delete personal information that we have collected from or obtained about you.</li>
							<li>
								Opt-out of the processing of your personal information for purposes of, targeted advertising or the sale of
								your personal information.
							</li>
							<li>
								You may have the right to opt-out of the processing of personal information for the purposes of profiling.
							</li>
							<li>
								Right to appeal if we refuse to take action on your rights request. Instructions on how to appeal will be
								provided to you upon such a denial. The appeal should be submitted in accordance with section 5.3 below.
							</li>
						</ol>
						<p>
							Please note that, depending on the nature of your request, you may be asked to provide information to verify
							your identity before your request can be processed.
						</p>
						<p>You may exercise your privacy rights under the CTDPA, CPA, and VCDPA no more than twice in a 12-month period.</p>
					</div>
					<div className={styles.subHeading}>5.3. Exercising Your Rights</div>
					<div className={styles.content}>
						<p>To exercise the rights described above, please submit your inquiry or request via the following two methods:</p>
						<ul style={{ listStyleType: 'disc', paddingInlineStart: '24px' }}>
							<li>
								Submitting an inquiry to <a href="mailto:privacy@mani-festo.co">privacy@mani-festo.co</a>.
							</li>
							<li>Sending a physical inquiry to Habarzel 38, Tel Aviv, Israel. 6971054</li>
						</ul>
						<p>We will not discriminate against you for exercising any of your rights.</p>
						<p>
							Please note, that we do not explicitly sell your personal information, therefore, there is no need for you to
							opt-out of the potential sale of your personal information.
						</p>
						<p>
							Only you or a person authorized to act on your behalf, may make a request related to your Information. You may
							also make a verifiable consumer request on behalf of your minor child. If you submit your request through an
							authorized agent, we may request that the agent provide proof of your prior authorization, as well as
							information necessary to verify your identity.
						</p>
						<p>
							We can meet sufficiently verifiable user requests. Sufficiently verifiable user requests refer to requests that
							allow us to reasonably match the information provided by the user making the request with the information
							already available with us, insofar non-pseudonymous Personal Information is available.
						</p>
						<p>Any disclosures we provide will only cover the 12-month period preceding receipt of your request.</p>
					</div>
				</div>
				<div id={'6'} className={styles.paragraph}>
					<div className={styles.heading}>6. Managing Cookies (“Opt-Out”) or Unsubscribing</div>
					<div className={styles.content}>
						<p>
							If you do not want to receive interest-based ads displayed by us based on our use of cookies, you can disable or
							delete them.
						</p>
						<p>
							Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from
							browser to browser, and from version to version. You can however obtain up-to-date information about blocking
							and deleting cookies via these links
						</p>
						<p>
							<ol style={{ listStyleType: 'lower-alpha' }}>
								<li>
									<a target={'_blank'} href={'https://support.google.com/chrome/answer/95647?hl=en'}>
										https://support.google.com/chrome/answer/95647?hl=en (Chrome)
									</a>
								</li>
								<li>
									<a
										target={'_blank'}
										href={'https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'}>
										https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);
									</a>
								</li>
								<li>
									<a target={'_blank'} href={'http://www.opera.com/help/tutorials/security/cookies/'}>
										http://www.opera.com/help/tutorials/security/cookies/ (Opera);
									</a>
								</li>
								<li>
									<a
										target={'_blank'}
										href={
											'https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'
										}>
										https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
										(Internet Explorer);
									</a>
								</li>
								<li>
									<a href={'https://support.apple.com/kb/PH21411'} target={'_blank'}>
										https://support.apple.com/kb/PH21411 (Safari);
									</a>
								</li>
								<li>
									<a href={'https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy'} target={'_blank'}>
										https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge)
									</a>
								</li>
							</ol>
						</p>
						<p>Blocking all cookies will have a negative impact upon the usability of many websites.</p>
						<p>If you block cookies, you might not be able to use all the features on our Platform.</p>
						<p>
							You may unsubscribe from our marketing communications by sending us an email at{' '}
							<a href="mailto:privacy@mani-festo.co">privacy@mani-festo.co</a>. Please note that Customers cannot opt out of
							receiving transactional emails related to their account with us or the subscription Service.
						</p>
					</div>
				</div>
				<div id={'7'} className={styles.paragraph}>
					<div className={styles.heading}>7. Data Retention and Deletion</div>
					<div className={styles.content}>
						<p>
							We retain different types of information for different periods, depending on the purposes for processing the
							information, our legitimate business purposes as well as pursuant to legal requirements under the applicable
							laws. We will not retain data longer than is necessary to fulfill the purposes for which it was collected or as
							required by applicable laws or regulations.
						</p>
						<p>
							We retain identifiable data, which is directly collected for purposes of providing our Services for twenty-four
							(24) months from the end-user's last and final interaction with our Services, after which time we will either
							de-identify the data by removing unique identifiers or aggregating the data, or simply delete it.
						</p>
						<p>
							We may retain aggregated data, which cannot identify an individual or device and is used for purposes of
							reporting and analysis, for as long as commercially necessary.
						</p>
						<p>
							In any case, as long as you use the Platform, we will retain identifiable data, unless we are legally required
							to delete it, or if you exercise your rights to delete the information in accordance with applicable laws.
						</p>
					</div>
				</div>
				<div id={'8'} className={styles.paragraph}>
					<div className={styles.heading}>8. Children</div>
					<div className={styles.content}>
						<p>
							The Site and/or Service is not directed to children under 13. We do not knowingly collect Personal Information
							from anyone under 13 years of age. If we determine upon collection that a user is under 13, we will not use or
							maintain his/her Personal Information without the parent/guardian’s consent. If we become aware that we have
							unknowingly collected Personal Information from a child under the age of 13, we will make reasonable efforts to
							delete such information from our records.
						</p>
						<p>If you’re a kid, please don’t use the Platform nor register to any of our services.</p>
					</div>
				</div>
				<div id={'9'} className={styles.paragraph}>
					<div className={styles.heading}>9. Security of your Information</div>
					<div className={styles.content}>
						<p>
							We maintain tight controls over all the data we collect, retaining it in secured databases with limited and
							controlled access rights, to ensure it is secure.
						</p>
						<p>
							Where we have given you or where you have chosen a password that enables you to access certain features of the
							Service, you are responsible for keeping this password confidential.
						</p>
						<p>
							Please remember that unfortunately, the transmission of information via the internet is not completely secure,
							so although we will do our best to protect your personal data, we cannot guarantee the security of your data
							transmitted via the Service; any transmission is at your own risk.
						</p>
					</div>
				</div>
				<div id={'10'} className={styles.paragraph}>
					<div className={styles.heading}>10. Consent to Processing and Transfer of Information</div>
					<div className={styles.content}>
						<p>
							Given that we are an international business, our use of your information may involve the transmission of data on
							an international basis. If you are located in the European Union, please be aware that information we collect
							may be transferred to and processed outside the European Union. By using the Service, or providing us with any
							information, you consent to the collection, processing, maintenance and transfer of such information in and to
							the United States and other applicable territories in which the privacy laws may not be as comprehensive as or
							equivalent to those in the country where you reside and/or are a citizen.
						</p>
					</div>
				</div>
				<div id={'11'} className={styles.paragraph}>
					<div className={styles.heading}>11. Changes</div>
					<div className={styles.content}>
						<p>
							Please note, we may modify information presented via the Site and/or this Privacy Policy from time to time. You
							should check back here periodically to see if the Privacy Policy has been updated. We will always show the date
							of the latest modification of the Privacy Policy so you can tell when it was last revised.
						</p>
					</div>
				</div>
				<div id={'12'} className={styles.paragraph}>
					<div className={styles.heading}>12. Contact Us</div>
					<div className={styles.content}>
						<p>
							We regularly review our compliance with this Privacy Policy. Questions, comments and requests regarding this
							Privacy Policy are welcomed and should be addressed to{' '}
							<a href="mailto:privacy@mani-festo.co">privacy@mani-festo.co</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
