import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AccountModel } from '../../models/account.model';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import styles from './main-breadcrumbs.module.scss';
import { IMFPPStore } from '../../stores';
// import { SkeletonBreadCrumb } from './main-breadcrumbs';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { SkeletonBreadCrumb } from './main-breadcrumbs';
import { useSearchParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { ActedAsBreadcrumbs } from './acted-as.breadcrumbs';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';
import { queryParamsToObject } from '@monorepo/tools/src/lib/utils/object';
import { SelectNew } from '@monorepo/base/src/components/select-new/select-new';

interface IAccountBreadcrumb {
	account?: AccountModel;
	isActive?: boolean;
}

const All = 'All';

export const AccountBreadcrumb = observer((props: IAccountBreadcrumb) => {
	const { account, isActive = false } = props;
	const [, setSearchParams] = useSearchParams();
	const { publisherStore } = useStores<IMFPPStore>();
	const accountListStore = publisherStore.getListStore();
	const isLoadingAccounts = accountListStore.getIsLoading();
	const isError = accountListStore.getHttpError();
	const accounts = accountListStore.getData();
	const accountName = account?.name;

	if (!accounts) {
		return null;
	}

	const onListItemAccount = (accountsId: (string | number)[]) => {
		const _searchParams = new URLSearchParams(window.location.search);
		const accountId = accountsId[0];

		if (accountId === All) {
			const PUV = _searchParams.get('PUV');
			if (PUV) {
				setSearchParams({ PUV });
			} else {
				const currentSearchParamsObj = queryParamsToObject(_searchParams.toString());
				delete currentSearchParamsObj.accountId;

				setSearchParams(currentSearchParamsObj);
			}
			return null;
		}
		if (!accountId) {
			// TODO - log
			return null;
		}

		if (_searchParams.get('accountId') != accountId) {
			// const _searchParams = new URLSearchParams(window.location.search);
			const PUV = _searchParams.get('PUV');
			if (PUV) {
				setSearchParams({ accountId: accountId as string, PUV });
			} else {
				setSearchParams({ ...queryParamsToObject(_searchParams.toString()), accountId: accountId as string });
			}
		}
	};

	const onBreadcrumbLink = () => {
		const accountId = account?.id;
		if (accountId) {
			// navigate(`${routes.accounts.advertisers(accountId)}`);
		}
	};

	return (
		<BreadcrumbMenu
			label={'Account'}
			title={<span className={isActive ? styles.active : ''}>{accountName || All}</span>}
			isArrow={false}
			navigateToModel={onBreadcrumbLink}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'account_breadcrumbs')] }}
			isBarLoader={isLoadingAccounts}
			isLoading={isLoadingAccounts && accounts.length === 0}
			isError={Boolean(isError)}
			isSingleAction={true}
			openMenuOnModelClick={true}>
			<div className={styles.menuWrapper}>
				{accounts.length > 0 ? (
					<SelectNew
						placeholder={'Search Account'}
						classNames={{ list: styles.list }}
						options={[
							{ label: 'All', value: 'All' },
							...accounts.map(account => {
								return {
									label: account.name,
									value: account.id,
								};
							}),
						]}
						required
						debugProps={{ dataAttrs: [new DataAttribute('id', 'account_breadcrumbs_select')] }}
						onSelect={onListItemAccount}
						disabled={isLoadingAccounts}
						defaultValues={[account?.id || 'All']}
					/>
				) : null}
			</div>
		</BreadcrumbMenu>
	);
});

export const AccountPageBreadcrumbs = observer(() => {
	const { publisherStore } = useStores<IMFPPStore>();
	const [searchParams] = useSearchParams();
	const [account, setAccount] = useState<AccountModel>();
	const actAsUser = ActAsHelper.getUser();

	useEffect(() => {
		setAccount(
			publisherStore
				.getListStore()
				.getData()
				?.find(account => account.id == searchParams.get('accountId'))
		);
	}, [searchParams]);

	return (
		<div className={styles.wrapper}>
			{!publisherStore.getListStore().getIsLoading() ? (
				<Fragment>
					{actAsUser && <ActedAsBreadcrumbs actAsUser={actAsUser} />}
					<AccountBreadcrumb account={account} isActive={true} />
				</Fragment>
			) : (
				<SkeletonBreadCrumb />
			)}
		</div>
	);
});
