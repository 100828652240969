import styles from './docs-menu.module.scss';
import { observer } from 'mobx-react';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { useState } from 'react';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import routes from '../../routes/index.routes';
import { Divider } from '@monorepo/base/src/components/divider/divider';
import { PrimaryText } from '@monorepo/base/src/components/buttons/buttons';
// import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { Guard } from '../guard/guard';
import { validateAction } from '../../hooks/tools/use-guard';
import { PermissionActions } from '../../enums/permissions';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

interface IDocListItem {
	title?: JSX.Element | string;
	route: string;
	icon?: string;
	color?: string;
}

export const DocsMenu = observer(() => {
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
	// const navigate = useNavigateParams();

	const DocsMenuButton = () => {
		return (
			<Flex
				onClick={() => {
					sendGtagEvent({
						action: TrackingActions.Click,
						category: EVENTS.CLICK.NAVBAR.DOCS.OPEN,
					});
					setIsOpenMenu(!isOpenMenu);
				}}
				padding="0rem 1rem"
				height="100%"
				justifyContent="center"
				alignItems="center"
				className={styles.docsBar}>
				<PrimaryText>Docs</PrimaryText>
			</Flex>
		);
	};

	const DocListItem = ({ title, route, icon }: IDocListItem) => {
		const { indexRoute, currentRoute } = useRoute();
		const isActiveRoute = currentRoute === route || currentRoute === `/${route}` || indexRoute === route;

		return (
			<div className={`${styles.action} ${isActiveRoute ? styles.activeItem : ''}`}>
				<LinkWithParams
					onClick={() => {
						sendGtagEvent({
							action: TrackingActions.Click,
							category: EVENTS.CLICK.NAVBAR.DOCS.SELECT_OPTION,
							value: title?.toString(),
						});
						setIsOpenMenu(false);
					}}
					to={route}
					className={styles.itemLink}>
					{icon ? (
						<Icon
							className={styles.icon}
							isMFP={true}
							size={'20px'}
							color={isActiveRoute ? getTheme().primaryColor : undefined}>
							{icon}
						</Icon>
					) : null}
					<span className={styles.itemTitle}>{title}</span>
				</LinkWithParams>
			</div>
		);
	};

	return (
		<div className={styles.wrapper}>
			<DocsMenuButton />
			<Menu alignToRight unstyled={true} className={styles.menu} isOpen={isOpenMenu} onClose={() => setIsOpenMenu(false)}>
				<div className={styles.docsMenu}>
					<div className={styles.action}>Report API Documentation</div>
				</div>
				<Spacer y={1} />
				<Divider classNames={styles.divider} />
				<div className={styles.actions}>
					<DocListItem route={routes.apiDocumentations.byCampaign()} title={'By Campaign'} icon={'announcement-01'} />
					<DocListItem route={routes.apiDocumentations.bySales()} title={'By Sales'} icon={'coins-hand'} />
					<DocListItem route={routes.apiDocumentations.byClicks()} title={'By Clicks'} icon={'cursor-click-02'} />
					<Guard validate={validateAction([PermissionActions.inventoryDocs])}>
						<DocListItem route={routes.apiDocumentations.inventory()} title={'Inventory'} icon={'package'} />
					</Guard>
				</div>
			</Menu>
		</div>
	);
});
