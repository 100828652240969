import { Fragment } from 'react';
import styles from './main-breadcrumbs.module.scss';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IUser } from '../../models/user.model';

interface IActedAsBreadcrumbs {
	actAsUser: IUser;
}

export const ActedAsBreadcrumbs = (props: IActedAsBreadcrumbs) => {
	const { actAsUser } = props;

	return (
		<Fragment>
			<Icon className={styles.actAsIcon} font={IconFonts.Outlined} color={getTheme().primary600} isMFP>
				user-right-02
			</Icon>
			<div className={styles.actAs}>
				<div className={styles.actAsUserInfo}>
					<div className={styles.text}>Acting as</div>
					<div className={styles.id}>{actAsUser.email}</div>
				</div>
			</div>
			<div className={styles.actAsDivider}>/</div>
		</Fragment>
	);
};
