import { Billing } from '../../pages/billing/index/billing.page';
import { GuardRoute } from '../components/guard/guard-route';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { PermissionActions } from '../enums/permissions';
import { validateAction } from '../hooks/tools/use-guard';
import routes from '../routes/index.routes';

export const BillingRouter = [
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.billing.index(),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.BillingRead, PermissionActions.BillingWrite])}>
						<Billing />
					</GuardRoute>
				),
			},
		],
	},
];
