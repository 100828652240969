import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { IMFPPStore } from '../../../modules/stores';
import { UserForm } from '../../../modules/components/forms/user/user.form';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import styles from './create-user.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { getUrlLevelUp } from '@monorepo/tools/src/lib/utils/url';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export const CreateUser = observer(() => {
	const { usersStore } = useStores<IMFPPStore>();
	const { dispatchLog } = useLogs();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const userCrudStore = usersStore.getCrud();
	const user = userCrudStore.getData();

	const navigate = useNavigateParams();
	const location = useLocation();
	const closeSiderSlider = (isBlockRequest = false) => {
		navigate(getUrlLevelUp({ pathname: location.pathname }), { state: { isBlockRequest } as BetweenPagesStateProps });
	};

	useEffect(() => {
		return () => {
			userCrudStore.reset();
		};
	}, [location]);

	const formStore = userCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = userCrudStore.getIsLoading();
	const isSuccess = userCrudStore.getIsSuccess();
	const error = userCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			closeSiderSlider();
			dispatchLog({
				msg: `User ${user.getFirstName()} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			userCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onCreateUser = () => {
		setFormClicked(true);
		// console.log({ data: userCrudStore.getCreateFormData() });
		usersStore.getCrud().create();
	};
	return (
		<SiderSlider className={styles.userSiderSlider} onClose={() => closeSiderSlider(true)} isOpen={true}>
			<Helmet>
				<title>Create User</title>
			</Helmet>
			<Disable is={isLoading}>
				<Page unstyled className={styles.wrapper}>
					<Pagebar>
						<PagebarTitle classes={{ title: styles.title }} unstyled>
							Create New User
						</PagebarTitle>
					</Pagebar>
					<UserForm />
					<BottomBar classes={{ barClassses: styles.userBottomBar, wrapperClassses: styles.userBottomBarWrapper }}>
						{error ? <ErrorMsg error={error} classes={{ error: styles.errorMsg }} /> : null}
						<BottomBarActions>
							<SecondaryButton
								onClick={() => {
									sendGtagEvent({
										action: TrackingActions.Click,
										category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.FORM.CANCEL,
										label: 'Cancel user creation',
									});
									closeSiderSlider(true);
								}}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'cancel_user_button')] }}>
								Cancel
							</SecondaryButton>
							<PrimaryButton
								onClick={() => {
									sendGtagEvent({
										action: TrackingActions.Click,
										category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.FORM.CREATE,
										label: 'Create user',
									});
									onCreateUser();
								}}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'save_user_button')] }}>
								Save
							</PrimaryButton>
						</BottomBarActions>
					</BottomBar>
				</Page>
			</Disable>
		</SiderSlider>
	);
});
