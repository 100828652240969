import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { CrudEndpoint } from './crud.endpoint';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { url } from '@monorepo/tools/src/lib/types/url';

export class BillingEndpoint extends CrudEndpoint {
	sherrifRoute: string;
	bouncerRoute: url;

	constructor() {
		super('billing');
		this.sherrifRoute = getConfig().env.sheriffRootApi;
		this.bouncerRoute = `${this.sherrifRoute}/bouncer/${this.component}`;
	}

	get(id: id) {
		return `${this.baseUrl}/${id}`;
	}

	edit(id: id) {
		// Token necessary
		return `${this.baseUrl}/${id}`;
	}
}
