import { HttpError } from '../../models/http-error.model';
import { List, ListItem } from '@monorepo/base/src/components/list/list';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './error-msg.module.scss';
import Timer, { TimerClasses } from '@monorepo/base/src/components/timer/timer';
import { useState } from 'react';

interface IErrorMsg {
	error: HttpError;
	classes?: { error: string };
	isIcon?: boolean;
}

interface IErrorWithTimeLeft {
	error: HttpError;
	classes?: {
		error?: string;
		timerClasses?: TimerClasses;
	};
	beforeTimerText?: string;
	afterTimerText?: string;
	isIcon?: boolean;
	isDisappeaerOnTimeup?: boolean;
}

export const ErrorMsg = (props: IErrorMsg) => {
	const { error, classes, isIcon = true } = props;
	return (
		<div className={`${styles.error} ${classes?.error}`}>
			{isIcon ? <Icon isMFP={true}>alert-circle</Icon> : null}
			<List>
				<ListItem key={error.getMessage()}>{error.getMessage()}</ListItem>
			</List>
		</div>
	);
};

export const ErrorWithTimeLeft = (props: IErrorWithTimeLeft) => {
	const { error, classes, isIcon, isDisappeaerOnTimeup, beforeTimerText, afterTimerText } = props;
	const [isDisplay, setIsDisplay] = useState<boolean>(true);

	if (!isDisplay) {
		return null;
	}

	return (
		<div className={`${styles.error} ${classes?.error}`}>
			{isIcon ? <Icon isMFP={true}>alert-circle</Icon> : null}
			<List>
				<ListItem className={`${styles.listItemTimer}`} key={error.getMessage()}>
					<span>{`${error.getMessage()}`}&nbsp;</span>
					<Timer
						beforeText={beforeTimerText}
						afterText={afterTimerText}
						handleTimeup={() => setIsDisplay(false)}
						isDisappeaerOnTimeup={isDisappeaerOnTimeup}
						initialTime={error.payload?.timeLeft || 0}
						classNames={classes?.timerClasses}
					/>
				</ListItem>
			</List>
		</div>
	);
};
