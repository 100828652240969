import { BaseAndCrudRoutes } from '@monorepo/tools/src/lib/routes/base.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export class UsersManagementRoutes extends BaseAndCrudRoutes {
	constructor() {
		super('users-management');
	}

	changePassword() {
		return '/change-password';
	}

	actAs(id: id) {
		return `${this.baseRoute}/act-as/${id}`;
	}

	actAsVerify(id: id) {
		return `${this.baseRoute}/act-as/verify/${id}`;
	}
}
