import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPPStore } from '../../stores/index';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { UsersFiltersStoreKeys } from '../../stores/users/users.store';
import { usersFilterPrototypes } from '../../components/filters';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { ElementType, Fragment } from 'react';
import { UserModel } from '../../models/user.model';
import { IDefaultActionsProps, IEntityActions, ITableActions, IUseEntityActions, useDefaultActions } from './default.actions';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IButton, SecondaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { Guard } from '../../components/guard/guard';
import { useGuard, validateAction } from '../tools/use-guard';
import { PermissionActions } from '../../enums/permissions';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

interface IUsersExtraActionsProps {
	ActAsAction: ElementType;
}

const theme = getTheme();

export function useUsersExtraActions(props: IDefaultActionsProps<UserModel>): IUsersExtraActionsProps {
	const { entities } = props;

	const onActAsVerifyRoute = (userId: string) => {
		ActAsHelper.deleteUser(userId);
		return `act-as/verify/${userId}?PUV=${userId}`;
	};

	const ActAsAction = (props: IButton<HTMLAnchorElement>) => {
		const { textColor = theme.actionLinerIconTextColor, iconColor = theme.actionLinerIconColor, className } = props;
		const actAsId = entities[0]?.getId();
		if (!onActAsVerifyRoute || !actAsId) {
			return null;
		}

		return (
			<SecondaryLink
				onClick={() => {
					sendGtagEvent({
						action: TrackingActions.Click,
						category: EVENTS.CLICK.INDEX_PAGE.ACTION_LINER.ACT_AS,
						value: `user id: ${actAsId}`,
					});
				}}
				disabled={entities.length !== 1}
				icon={'users-03'}
				font={IconFonts.Outlined}
				to={onActAsVerifyRoute(actAsId)}
				newWindow={true}
				textColor={textColor}
				iconColor={iconColor}
				className={className}
				delSearchParams={true}
				text={true}
				{...props}>
				Act as
			</SecondaryLink>
		);
	};

	return {
		ActAsAction,
	};
}

export const useUsersActions = (props: IUseEntityActions = {}) => {
	const { settingsStore } = useStores<IMFPPStore>();
	const { onEdit } = props;
	const { currentRouteWithoutSpecialChars } = useRoute();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars as UsersFiltersStoreKeys);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [...usersFilterPrototypes];

	const onEditRoute = (userId: id) => (onEdit ? onEdit(userId) : routes.usersManagement.edit(userId));

	const funcActions = {
		onEditRoute,
	};

	const isActAsPermission = useGuard({ validate: validateAction([PermissionActions.ActAs]) }); //remove this 2 lines in case of adding more actions
	const isUserWritePermission = useGuard({ validate: validateAction([PermissionActions.UsersWrite]) });

	const SelectedActions = (props: ITableActions<UserModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new UserModel(row.original));
		const { EditAction } = useDefaultActions({ entities, ...funcActions });
		const { ActAsAction } = useUsersExtraActions({ entities });

		if (!isActAsPermission && !isUserWritePermission) {
			return null;
		}

		return (
			<Fragment>
				<Guard validate={validateAction([PermissionActions.UsersWrite])}>
					<SelectedLinerButton>
						<EditAction />
					</SelectedLinerButton>
				</Guard>
				<Guard validate={validateAction([PermissionActions.ActAs])}>
					<SelectedLinerButton>
						<ActAsAction />
					</SelectedLinerButton>
				</Guard>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<UserModel>) => {
		const { entity } = props;
		const { EditAction } = useDefaultActions({ entities: [entity], ...funcActions });
		const { ActAsAction } = useUsersExtraActions({ entities: [entity] });

		if (!isActAsPermission && !isUserWritePermission) {
			return null;
		}

		return (
			<Fragment>
				<Guard validate={validateAction([PermissionActions.UsersWrite])}>
					<QuickActionButton>
						<EditAction />
					</QuickActionButton>
				</Guard>
				<Guard validate={validateAction([PermissionActions.ActAs])}>
					<QuickActionButton>
						<ActAsAction />
					</QuickActionButton>
				</Guard>
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	return {
		FiltersActions,
		SelectedActions,
		EntityActions,
	};
};
