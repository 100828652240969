// TODO - contact, faq, about, etc.. will be here
export class GeneralRoutes {
	home() {
		return '/';
	}

	maintenance() {
		return '/maintenance';
	}

	termsAndConditions() {
		return '/terms-and-conditions';
	}

	privacyPolicy() {
		return '/privacy-policy';
	}
}
