import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './page-layout.module.scss';
import { MainNavbar } from '../../main-navbar/main-navbar';
import { IMFPPStore } from '../../../stores';
import { ControlledSidebar } from '../../sidebar/sidebar';
import { ManifestoLogo } from '../../logo/logo';
import { Logo } from '@monorepo/base/src/components/logo/logo';
import routes from '../../../routes/index.routes';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

interface IPageLayout {
	isSidebar?: boolean;
}

export const PageLayout = observer((props: IPageLayout) => {
	const { sidebarStore } = useStores<IMFPPStore>();
	const { isSidebar = true } = props;

	return (
		<main className={`${styles.main} ${isSidebar ? styles.sidebar : ''}`}>
			{isSidebar ? <ControlledSidebar /> : null}
			<div className={sidebarStore.getIsSideBarOpen() ? styles.minimize : styles.maximize}>
				<MainNavbar
					BeginNavbar={
						<div
							onClick={() => {
								sendGtagEvent({
									action: TrackingActions.Click,
									category: EVENTS.CLICK.NAVBAR.LOGO,
								});
							}}
							className={styles.logoWrapper}>
							<Logo to={routes.general.home()}>
								<ManifestoLogo />
							</Logo>
						</div>
					}
				/>
				<Outlet />
			</div>
		</main>
	);
});
