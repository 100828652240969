import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { createColumnHelper, sortingFns } from '@tanstack/react-table';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { IMFPPStore } from '../../stores';
import { IEntityHeaders, IMergedHeadersReturn } from './index.headers';
import { IPublisher } from '../../models/publisher.model';
import { CellTypes, renderCell } from '../../helpers/cells.helper';
import { useWithPerformanceHeaders } from './with-performance.headers';

const columnHelper = createColumnHelper<IPublisher>();

export const useReportHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<IPublisher> => {
	const { summary } = props;
	const { settingsStore } = useStores<IMFPPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;

	useEffect(() => {
		if (tableStore) {
			if (Object.keys(tableStore.getColumnVisibility()).length === 0) {
				tableStore.setDefaultColumnVisibility({
					id: false,
					name: true,
					campaignId: false,
					campaignName: false,
					type: true,
					geo: false,
					domain: true,
					device: false,
					isDeepLink: true,
					hour: true,
					clicks: true,
					revenue: true,
					epc: true,
					conversionsRate: true,
					sales: true,
					date: false,
				});
				tableStore.setColumnVisibility(tableStore.getDefaultColumnVisibility());
			}

			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'revenue',
						desc: true,
					},
				]);
			}
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('date', {
				header: 'Date',
				sortingFn: (rowA, rowB, columnId) => sortingFns.datetime(rowA, rowB, columnId),
			}),
			columnHelper.accessor('id', {
				header: 'Account ID',
				//enableHiding: isInternal, //which means that only internal users can hide this column via column picker
			}),
			columnHelper.accessor('name', {
				header: 'Account Name',
			}),
			columnHelper.accessor('type', {
				header: 'Account Type',
			}),
			columnHelper.accessor('campaignId', {
				header: 'Campaign ID',
			}),
			columnHelper.accessor('campaignName', {
				header: 'Campaign Name',
			}),
			columnHelper.accessor('domain', {
				header: 'Domain',
				cell: ({ row }) => {
					return renderCell(CellTypes.Domain, row, 'domain');
				},
			}),
			columnHelper.accessor('geo', {
				header: 'Geo',
				cell: ({ row }) => {
					return renderCell(CellTypes.Geo, row, 'geo');
				},
			}),
			columnHelper.accessor('device', {
				header: 'Device',
				cell: ({ row }) => {
					return renderCell(CellTypes.Device, row, 'device');
				},
			}),
			columnHelper.accessor('isDeepLink', {
				header: 'Deep Link',
				cell: ({ row }) => {
					return <span>{renderCell(CellTypes.DL, row, 'isDeepLink')}</span>;
				},
			}),
			...mergedPerforamnceHeaders,
		],
	};
};
