import { objectToSelectOptions } from '@monorepo/tools/src/lib/utils/object';
import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters';
import countriesJson from '@monorepo/tools/src/lib/consts/geos.json';

const verticalList = [
	'Computers_Electronics_and_Technology/Telecommunications',
	'Food_and_Drink/Food_and_Drink',
	'Science_and_Education/Social_Sciences',
	'Finance/Financial_Planning_and_Management',
	'Lifestyle/Jewelry_and_Luxury_Products',
	'Computers_Electronics_and_Technology/Search_Engines',
	'Community_and_Society/Romance_and_Relationships',
	'Health/Biotechnology_and_Pharmaceuticals',
	'Finance/Investing',
	'Business_and_Consumer_Services/Marketing_and_Advertising',
	'Home_and_Garden/Home_and_Garden',
	'Sports/Baseball',
	'Hobbies_and_Leisure/Ancestry_and_Genealogy',
	'Sports/Winter_Sports',
	'Law_and_Government/Government',
	'Law_and_Government/Legal',
	'Vehicles/Vehicles',
	'Arts_and_Entertainment/Arts_and_Entertainment',
	'Heavy_Industry_and_Engineering/Aerospace_and_Defense',
	'Arts_and_Entertainment/Animation_and_Comics',
	'Arts_and_Entertainment/TV_Movies_and_Streaming',
	'Gambling/Sports_Betting',
	'Sports/Soccer',
	'Business_and_Consumer_Services/Real_Estate',
	'Gambling/Lottery',
	'Finance/Insurance',
	'Business_and_Consumer_Services/Business_Services',
	'Vehicles/Automotive_Industry',
	'Food_and_Drink/Restaurants_and_Delivery',
	'Sports/American_Football',
	'Finance/Banking_Credit_and_Lending',
	'Arts_and_Entertainment/Music',
	'Travel_and_Tourism/Accommodation_and_Hotels',
	'Games/Games',
	'Sports/Running',
	'E-commerce_and_Shopping/Auctions',
	'Science_and_Education/Libraries_and_Museums',
	'Business_and_Consumer_Services/Shipping_and_Logistics',
	'Business_and_Consumer_Services/Publishing_and_Printing',
	'Hobbies_and_Leisure/Models',
	'Food_and_Drink/Cooking_and_Recipes',
	'Lifestyle/Beauty_and_Cosmetics',
	'Games/Board_and_Card_Games',
	'Computers_Electronics_and_Technology/Consumer_Electronics',
	'Health/Health_Conditions_and_Concerns',
	'Pets_and_Animals/Animals',
	'Science_and_Education/Chemistry',
	'Home_and_Garden/Gardening',
	'Reference_Materials/Reference_Materials',
	'Science_and_Education/Science_and_Education',
	'Computers_Electronics_and_Technology/File_Sharing_and_Hosting',
	'Business_and_Consumer_Services/Online_Marketing',
	'Health/Mental_Health',
	'Computers_Electronics_and_Technology/Web_Hosting_and_Domain_Names',
	'Community_and_Society/Holidays_and_Seasonal_Events',
	'Travel_and_Tourism/Tourist_Attractions',
	'Computers_Electronics_and_Technology/Graphics_Multimedia_and_Web_Design',
	'Lifestyle/Fashion_and_Apparel',
	'Computers_Electronics_and_Technology/Social_Networks_and_Online_Communities',
	'Vehicles/Makes_and_Models',
	'Heavy_Industry_and_Engineering/Metals_and_Mining',
	'Lifestyle/Childcare',
	'Law_and_Government/Law_and_Government',
	'Hobbies_and_Leisure/Photography',
	'Lifestyle/Gifts_and_Flowers',
	'Vehicles/Motorcycles',
	'Health/Addictions',
	'Jobs_and_Career/Jobs_and_Employment',
	'Health/Medicine',
	'Pets_and_Animals/Pets',
	'Health/Womens_Health',
	'Reference_Materials/Dictionaries_and_Encyclopedias',
	'Arts_and_Entertainment/Performing_Arts',
	'Community_and_Society/Decease',
	'E-commerce_and_Shopping/Classifieds',
	'Science_and_Education/Universities_and_Colleges',
	'Pets_and_Animals/Pet_Food_and_Supplies',
	'Sports/Hunting_and_Shooting',
	'Jobs_and_Career/Jobs_and_Career',
	'Sports/Sports',
	'Heavy_Industry_and_Engineering/Chemical_Industry',
	'Health/Alternative_and_Natural_Medicine',
	'Lifestyle/Weddings',
	'Arts_and_Entertainment/Books_and_Literature',
	'Arts_and_Entertainment/Visual_Arts_and_Design',
	'Computers_Electronics_and_Technology/Computer_Hardware',
	'Finance/Finance',
	'Sports/Boxing',
	'Business_and_Consumer_Services/Business_and_Consumer_Services',
	'Sports/Extreme_Sports',
	'Finance/Accounting_and_Auditing',
	'Pets_and_Animals/Pets_and_Animals',
	'Heavy_Industry_and_Engineering/Heavy_Industry_and_Engineering',
	'Computers_Electronics_and_Technology/Computer_Security',
	'Vehicles/Boats',
	'Law_and_Government/National_Security',
	'Sports/Cycling_and_Biking',
	'Science_and_Education/Math',
	'Science_and_Education/Biology',
	'Health/Developmental_and_Physical_Disabilities',
	'Computers_Electronics_and_Technology',
	'Sports/Water_Sports',
	'Reference_Materials/Public_Records_and_Directories',
	'Hobbies_and_Leisure/Camping_Scouting_and_Outdoors',
	'Home_and_Garden/Furniture',
	'Heavy_Industry_and_Engineering/Energy_Industry',
	'Vehicles/Aviation',
	'Heavy_Industry_and_Engineering',
	'Food_and_Drink/Groceries',
	'Community_and_Society/Philanthropy',
	'Gambling/Bingo',
	'Computers_Electronics_and_Technology/Advertising_Networks',
	'Heavy_Industry_and_Engineering/Architecture',
	'Sports/Fishing',
	'Health/Pharmacy',
	'Hobbies_and_Leisure/Crafts',
	'Heavy_Industry_and_Engineering/Agriculture',
	'Home_and_Garden/Home_Improvement_and_Maintenance',
	'Travel_and_Tourism/Transportation_and_Excursions',
	'Gambling/Gambling',
	'Home_and_Garden',
	'Science_and_Education/Earth_Sciences',
	'Vehicles/Motorsports',
	'Food_and_Drink/Beverages',
	'Sports/Tennis',
	'Travel_and_Tourism/Air_Travel',
	'Health/Dentist_and_Dental_Services',
	'E-commerce_and_Shopping/Price_Comparison',
	'E-commerce_and_Shopping/Coupons_and_Rebates',
	'E-commerce_and_Shopping/Marketplace',
	'Science_and_Education/Physics',
	'Law_and_Government/Immigration_and_Visas',
	'Health/Nutrition_Diets_and_Fitness',
	'Science_and_Education/Philosophy',
	'Sports/Basketball',
	'Pets_and_Animals/Fish_and_Aquaria',
	'Business_and_Consumer_Services/Textiles',
	'Arts_and_Entertainment/Humor',
	'E-commerce_and_Shopping/Tickets',
	'Sports/Climbing',
	'Pets_and_Animals/Horses',
	'Science_and_Education/Astronomy',
	'Sports',
	'Home_and_Garden/Interior_Design',
	'Sports/Golf',
	'Lifestyle/Tobacco',
	'Science_and_Education/History',
	'Science_and_Education/Environmental_Science',
	'Science_and_Education/Grants_Scholarships_and_Financial_Aid',
	'Reference_Materials/Maps',
	'Health/Geriatric_and_Aging_Care',
	'Reference_Materials',
	'Sports/Fantasy_Sports',
	'Hobbies_and_Leisure/Antiques_and_Collectibles',
	'Gambling',
	'Travel_and_Tourism/Car_Rentals',
	'Sports/Martial_Arts',
	'Jobs_and_Career/Human_Resources',
	'Gambling/Poker',
	'Law_and_Government',
	'Pets_and_Animals/Birds',
	'Health',
	'Games/Roleplaying_Games',
	'Law_and_Government/Law_Enforcement_and_Protective_Services',
	'Food_and_Drink/Vegetarian_and_Vegan',
	'Health/Public_Health_and_Safety',
	'Health/Mens_Health',
	'Gambling/Casinos',
	'Sports/Rugby',
	'Vehicles',
	'Lifestyle/Lifestyle',
	'Business_and_Consumer_Services',
	'Community_and_Society/LGBTQ',
	'Science_and_Education',
	'Science_and_Education/Literature',
	'Travel_and_Tourism/Ground_Transportation',
	'Travel_and_Tourism',
	'E-commerce_and_Shopping',
	'Science_and_Education/Business_Training',
	'Health/Childrens_Health',
	'Computers_Electronics_and_Technology/Email',
	'Community_and_Society',
	'Arts_and_Entertainment',
	'Games/Puzzles_and_Brainteasers',
	'Business_and_Consumer_Services/Relocation_and_Household_Moving',
	'Hobbies_and_Leisure',
	'Pets_and_Animals',
	'Science_and_Education/Weather',
	'Sports/Volleyball',
	'Heavy_Industry_and_Engineering/Waste_Water_and_Environmental',
	'Community_and_Society/Community_and_Society',
	'Science_and_Education/Public_Records_and_Directories',
	'Food_and_Drink',
	'Finance',
	'Lifestyle',
	'Games',
	'Jobs_and_Career',
	'Adult',
	'Health/Health',
	'News_and_Media',
	'Science_and_Education/Education',
	'Travel_and_Tourism/Travel_and_Tourism',
	'Community_and_Society/Faith_and_Beliefs',
	'Games/Video_Games_Consoles_and_Accessories',
	'E-commerce_and_Shopping/E-commerce_and_Shopping',
	'Heavy_Industry_and_Engineering/Construction_and_Maintenance',
	'Computers_Electronics_and_Technology/Programming_and_Developer_Software',
	'Computers_Electronics_and_Technology/Computers_Electronics_and_Technology',
	'Hobbies_and_Leisure/Hobbies_and_Leisure',
];

export const inventoryFilters = new Map<FilterTypeKey, FilterType>([
	[
		'numberFilters',
		{
			clickCap: { columnName: 'Click Cap', cellKey: 'clickCap' },
			cpc: { columnName: 'CPC', cellKey: 'cpc' },
			commission: { columnName: 'Commission', cellKey: 'commission', isRange: true, isInventoryCommission: true },
		} as INumberFilter,
	],
	[
		'stringFilters',
		{
			domain: { columnName: 'Domain', cellKey: 'domain' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			geo: {
				columnName: 'Geo',
				cellKey: 'geo',
				enumOptions: objectToSelectOptions(countriesJson),
				deletable: true,
			},
			isDeepLink: {
				columnName: 'Deep Link',
				cellKey: 'deepLink',
				enumOptions: [
					{ value: 'True', label: 'True' },
					{ value: 'False', label: 'False' },
				],
				deletable: true,
			},
			vertical: {
				columnName: 'Vertical',
				cellKey: 'vertical',
				enumOptions: verticalList.map(_vertical => ({ value: _vertical, label: _vertical })),
				multi: true,
				deletable: true,
			},
		} as IEnumFilter,
	],
]);
