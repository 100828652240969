// import { spy } from 'mobx';
import { ModalsContainer } from '@monorepo/controlled/src/components/modal/modals-container/modals-container';
import { TooltipsContainer } from '@monorepo/controlled/src/components/tooltip/tooltips-container/tooltips-container';
import { AnnouncementsContainer } from '@monorepo/controlled/src/components/announcement/announcements-container/announcements-container';
import { ToastsContainer } from '@monorepo/controlled/src/components/toast/toasts-container/toasts-container';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import './app.scss';
import { Envs } from '@monorepo/tools/src/lib/enums/env';
import { useEffect } from 'react';
import packageJson from '../../package.json';
import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { ErrorsContainer } from '../modules/components/errors-container/errors-container';
import { MfpRouter } from '../modules/router/router';
import { generateStorageKey, removeOldPerssistableStores } from '@monorepo/controlled/src/stores/helper';
import { Head } from '../modules/components/head/head';
import { ask, IRequestInterceptor } from '@monorepo/tools/src/lib/tools/ask/ask';
import { hash } from '@monorepo/tools/src/lib/tools/hash';
import { REQUEST_ID, SESSION_ID } from '../lib/consts/storage';
import { SearchParamsGlobalDecleration } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';
import('@monorepo/tools/src/lib/tools/wasted-clicks');
import('@monorepo/tools/src/lib/tools/loggers/track-error');

// Mobx Event listener
// spy(event => {
// 	if (event.type === 'action') {
// console.log(`TCL ~ ${event.name} with args: ${event.arguments}`);
// 	}
// });

if (getConfig().env.projectEnv === Envs.Production || getConfig().env.projectEnv === Envs.Staging) {
	Sentry.init({
		dsn: 'https://f35c35324984f4f14fdb1879c0396914@o206854.ingest.sentry.io/4505917461823488',
		environment: getConfig().env.projectEnv === Envs.Production ? 'production' : 'staging',
	});
}

function App() {
	useEffect(() => {
		console.log('v' + packageJson.version);
		removeOldPerssistableStores();
	}, []);

	let sessionId = sessionStorage.getItem(generateStorageKey(SESSION_ID)) || '';

	//uncomment for maintenance
	// const _queryParams = new URLSearchParams(window.location.search);
	// if (_queryParams.get('daii') === 'imhamimim') {
	// 	sessionId = 'blameitonsemamama';
	// 	sessionStorage.setItem(generateStorageKey(SESSION_ID), sessionId);
	// }

	if (!sessionId) {
		sessionId = hash.uuid();
		sessionStorage.setItem(generateStorageKey(SESSION_ID), sessionId);
	}

	const traceInterceptor: IRequestInterceptor = {
		call: url => {
			const queryParams = new URLSearchParams(window.location.search);
			const shuli = queryParams.get('shuli');

			if (shuli) {
				url.searchParams.set('shuli', shuli);
			}

			url.searchParams.set(REQUEST_ID, hash.uuid());
			url.searchParams.set(SESSION_ID, sessionId);
		},
	};

	ask.interceptors.requests.set('RequestTrace', traceInterceptor);

	ask.interceptors.requests.set('ActAs', ActAsHelper.actAsRequestInterceptor);
	ask.interceptors.responses.set('ActAs', ActAsHelper.actAsResponseInterceptor);

	const platform = getConfig().env.platform;
	// console.log('platform', platform);
	if (platform) {
		const platformInterceptor: IRequestInterceptor = {
			call: (url, requestOptions) => {
				requestOptions.headers = {
					'x-platform': platform,
					...requestOptions.headers,
				};
			},
		};
		ask.interceptors.requests.set('Platform', platformInterceptor);
	}

	return (
		<Sentry.ErrorBoundary
			beforeCapture={() => {
				console.log('when?');
			}}>
			<HelmetProvider>
				<Head />
				<ErrorsContainer />
				<ModalsContainer />
				<ToastsContainer />
				<TooltipsContainer />
				<AnnouncementsContainer />
				<SearchParamsGlobalDecleration knownsSearchParams={['maze', 'shuli', 'accountId', 'PUV']} />
				<MfpRouter />
			</HelmetProvider>
		</Sentry.ErrorBoundary>
	);
}

export default App;
