import { TextareaHTMLAttributes, useEffect, useState } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { FormError } from '../form-error/form-error';
import styles from '../input/input.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

export interface ITextarea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	value?: string;
	onValue?: (value: string) => void;
	required?: boolean;
	placeholder?: string;
	icon?: string;
	type?: string;
	id?: string;
	rows?: number;
	error?: string;
	debugProps?: IDebugProps;
	wrapperClassName?: string;
	labelClassName?: string;
	requiredAsterisk?: boolean;
}

export const Textarea = (props: ITextarea) => {
	const {
		onChange,
		onValue,
		value = '',
		icon,
		placeholder = '',
		required = false,
		id = '',
		rows = 4,
		error,
		debugProps,
		wrapperClassName,
		labelClassName,
		requiredAsterisk = true,
		...rest
	} = props;

	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
	const [, setIsTouched] = useState<boolean>(false);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setIsTouched(true);
		if (onChange) {
			onChange(e);
		}
		if (onValue) {
			onValue(e.target.value);
		}
	};

	return (
		<div className={`${styles.wrapper} ${wrapperClassName}`}>
			<div className={`${styles.inputWrapper} ${styles.textareaWrapper}`}>
				{icon ? (
					<Icon isMFP={true} className={styles.icon}>
						{icon}
					</Icon>
				) : null}
				{/* ${isTouched && value ? styles.inputTouched : ''} */}
				<textarea
					onChange={_onChange}
					className={`${styles.input} ${styles.textarea} ${icon ? styles.inputWithIcon : ''} ${value ? styles.notEmpty : ''} ${
						errorMsg ? styles.inputError : ''
					}`}
					placeholder={`${placeholder} ${required && requiredAsterisk ? '*' : ''}`}
					type={'textarea'}
					value={value}
					required={required}
					rows={rows}
					{...generateDataAttrs(dataAttrs)}
					{...rest}
				/>
				<label htmlFor={id} className={`${styles.label} ${labelClassName}`}>
					{placeholder} {required && requiredAsterisk ? '*' : ''}
				</label>
			</div>
			<FormError msg={errorMsg} />
		</div>
	);
};
