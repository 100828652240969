import { observer } from 'mobx-react';
import styles from '../billing-form.module.scss';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPPStore } from '../../../../stores';

export const Paypal = observer(() => {
	const { billingStore } = useStores<IMFPPStore>();
	const billingCrudStore = billingStore.getCrud();
	const formStore = billingCrudStore.getFormStore();
	const billing = billingCrudStore.getData();
	const isLoading = billingCrudStore.getIsLoading();

	return (
		<div className={styles.paypal}>
			<div className={styles.row}>
				<Input
					disabled={isLoading}
					className={styles.input}
					topLabel={'Paypal Email'}
					required
					requiredAsterisk={false}
					value={billing.getPaypalEmail()}
					onValue={value => {
						billing.setPaypalEmail(value);
						formStore.clearError('email');
					}}
					error={formStore.getErrors().get('email')?.getMsg()}
				/>
				<Input
					disabled={isLoading}
					requiredAsterisk={false}
					className={styles.input}
					topLabel={'Payment threshold'}
					value={billing.getPaymentThreshold()?.toString()}
					onValue={value => {
						billing.setPaymentThreshold(Number(value));
						formStore.clearError('paymentThreshold');
					}}
					error={formStore.getErrors().get('paymentThreshold')?.getMsg()}
					required
					bottomLabel={
						formStore.getErrors().get('paymentThreshold')?.getMsg()
							? undefined
							: 'Our minimum payment threshold is $500, you can increase it.'
					}
				/>
			</div>
		</div>
	);
});
