import { useSearchParams } from 'react-router-dom';
import { PublishersApi } from '../../apis/publishers.api';
import { useEffect, useState } from 'react';

export const useApiKey = () => {
	const [apiKey, setApiKey] = useState<string | null>(null);
	const [accountFullName, setAccountFullName] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);

	const [searchParams] = useSearchParams();
	const accountId = searchParams.get('accountId') || undefined;

	useEffect(() => {
		if (accountId) {
			PublishersApi.apiKey({ id: accountId })
				.then(res => {
					setApiKey(res.apiKey);
					setAccountFullName(res.accountFullName);
					setIsSuccess(true);
				})
				.catch(setError)
				.finally(() => setIsLoading(false));
		} else {
			setApiKey('YOUR_ACCOUNT_API_KEY');
			setAccountFullName('YOUR_ACCOUNT_NAME');
		}
	}, [accountId]);

	return {
		apiKey: apiKey || 'YOUR_ACCOUNT_API_KEY',
		accountFullName: accountFullName || 'YOUR_ACCOUNT_NAME',
		isLoading,
		isSuccess,
		error,
	};
};
