import { makeAutoObservable } from 'mobx';

export interface IReport<T> {
	totalRows: number;
	totalFilteredRows: number;
	summary: T;
	data: T[];
}

export class ReportModel<T> {
	totalRows: number;
	totalFilteredRows: number;
	summary: T;
	data: T[];

	constructor(performanceResponse: IReport<T>) {
		this.totalRows = performanceResponse?.totalRows;
		this.totalFilteredRows = performanceResponse?.totalFilteredRows;
		this.summary = performanceResponse?.summary;
		this.data = performanceResponse?.data;

		makeAutoObservable(this);
	}

	getTotalRows(): number | undefined {
		return this.totalRows;
	}

	getTotalFilteredRows(): number | undefined {
		return this.totalFilteredRows;
	}

	getSummary(): T | undefined {
		return this.summary;
	}

	getData(): T[] | undefined {
		return this.data;
	}
}
