import { ColumnDef, createColumnHelper, RowData, sortingFns } from '@tanstack/react-table';
import { IMergedHeadersReturn } from './index.headers';
import { IPublisherPerformance, PublisherPerformanceModel } from '../../models/performance.model';
import { CellTypes, renderCell } from '../../helpers/cells.helper';
import { currencyFormat, currencyFormatterWithDecimals, isStringInfinityOrNaN, percents } from '@monorepo/tools/src/lib/utils/number';

// TODO - think maybe in meta object should be column type and not the isPerformance
// TODO - meta interface with total line

interface IuseWithPerformanceHeaders {
	summaryItem?: IPublisherPerformance;
}

const columnHelper = createColumnHelper<PublisherPerformanceModel>();

declare module '@tanstack/table-core' {
	// eslint-disable-next-line
	interface ColumnMeta<TData extends RowData, TValue> {
		isPerformance?: boolean;
		colSpan?: number;
		// TODO add total line here
	}
}
export const useWithPerformanceHeaders = (props: IuseWithPerformanceHeaders): IMergedHeadersReturn<PublisherPerformanceModel> => {
	const {
		summaryItem = {
			clicks: 0,
			sales: 0,
			conversionsRate: 0,
			epc: 0,
			revenue: 0,
		},
	} = props;

	const columns = [
		columnHelper.accessor('clicks', {
			header: 'Clicks',
			cell: ({ row }) => <span>{renderCell(CellTypes.Number, row, 'clicks')}</span>,
			footer: () => <span>{summaryItem?.clicks?.toLocaleString() || ''}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('revenue', {
			header: 'Revenue',
			cell: ({ row }) => <span>{renderCell(CellTypes.Currency, row, 'revenue')}</span>,
			footer: () => <span>{summaryItem?.revenue ? currencyFormatterWithDecimals.format(summaryItem?.revenue) : ''}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('sales', {
			header: 'Sales',
			cell: ({ row }) => <span>{renderCell(CellTypes.Number, row, 'sales')}</span>,
			footer: () => <span>{summaryItem?.sales?.toLocaleString() || ''}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('conversionsRate', {
			header: 'CR',
			cell: ({ row }) => <span>{renderCell(CellTypes.Percentage, row, 'conversionsRate')}</span>,
			footer: () => (
				<span>
					{!isStringInfinityOrNaN(summaryItem?.conversionsRate || 0)
						? percents(summaryItem?.conversionsRate === -1 ? 0 : summaryItem.conversionsRate) || '0%'
						: '0%'}
				</span>
			),
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('epc', {
			header: 'EPC',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('epc') === -1 ? 0 : row.getValue('epc'), true)}</span>,
			footer: () => (
				<span>
					{!isStringInfinityOrNaN(summaryItem?.epc || 0)
						? currencyFormat(summaryItem?.epc === -1 ? 0 : summaryItem.epc, true) || '0'
						: '0'}
				</span>
			),
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
	] as ColumnDef<PublisherPerformanceModel>[];

	return {
		columns,
	};
};
