import { observer } from 'mobx-react';
import { Fragment, ReactNode } from 'react';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { useAccounts } from '../../hooks/apis/use-account';
import { IMFPPStore } from '../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import routes from '../../routes/index.routes';

interface IRequireAuth {
	children: ReactNode;
}

/**
 * Redirect users to login page in case of user not authenticated
 */
export const RequireAccounts = observer((props: IRequireAuth) => {
	const { children } = props;
	const { userStore, authStore } = useStores<IMFPPStore>();
	const { isLoading, isSuccess, accountList } = useAccounts();
	const navigate = useNavigateParams();
	if (!isLoading && isSuccess) {
		if (accountList?.length === 0) {
			console.warn('Error code 234523');
			ActAsHelper.clearAllPUVFromSessionStorage();
			userStore.removeUser();
			authStore.removeToken(false);
			navigate(routes.general.home(), { replace: true });
		}
		return <Fragment>{children}</Fragment>; // The fragment is just for not typescript yelling
	} else if (!isLoading && !isSuccess) {
		console.warn('Error code 354733');
		ActAsHelper.clearAllPUVFromSessionStorage();
		userStore.removeUser();
		authStore.removeToken(false);
		navigate(routes.general.home(), { replace: true });
	}

	return <BarLoader is={true} />;
});
