import { Page } from '@monorepo/base/src/components/page/page';
// import { Prompt } from '@monorepo/base/src/components/prompt/prompt';
import { observer } from 'mobx-react';
import styles from './billing.page.module.scss';
import { Helmet } from 'react-helmet-async';
import { BillingForm } from '../../../modules/components/forms/billing/billing.form';
import { FC } from 'react';

// export const Billing = observer(() => {
export const Billing: FC = observer(() => {
	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>Manifesto - Billing</title>
			</Helmet>

			<Page>
				<BillingForm />
			</Page>
			{/* <Prompt when={true} message="waasadadasdashdajkdhadsahdjkashdkjsadhj" /> */}
		</div>
	);
});
