import { Outlet } from 'react-router-dom';
import styles from './split-layout.module.scss';
// import demoImage from '@monorepo/base/src/assets/demo.png';
import dayjs from 'dayjs';
import { ManifestoLogo } from '../../logo/logo';

interface ISplitLayout {
	isSticky?: boolean;
}
export const SplitLayout = (props: ISplitLayout) => {
	const { isSticky = true } = props;

	return (
		<div className={`${styles.wrapper} ${isSticky ? styles.sticky : ''}`}>
			<div className={styles.outletWrapper}>
				<ManifestoLogo className={styles.manifestoLogo} width={'173'} height={'36'} />
				<div className={styles.card}>
					<Outlet />
				</div>
				<div className={styles.credits}>Intango {dayjs().year()}</div>
			</div>
			<div className={styles.rightSection}>
				<div className={styles.text}>
					<div className={styles.purpleText}>Insights for Growth:</div>
					<div className={styles.blackText}>Access Revenue, Data, and Asset Performance</div>
					<div className={`${styles.circle} ${styles.purple} ${styles.medium}`}></div>
					<div className={`${styles.circle} ${styles.purple} ${styles.small}`}></div>
					<div className={`${styles.circle} ${styles.orange} ${styles.large}`}></div>
					<div className={`${styles.circle} ${styles.orange} ${styles.small}`}></div>
				</div>
				<div className={`${styles.demoImage} ${styles.top}`}></div>
				<div className={`${styles.demoImage} ${styles.bottom}`}></div>
			</div>
		</div>
	);
};
