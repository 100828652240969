import { objectToSelectOptions } from '@monorepo/tools/src/lib/utils/object';
import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters';
import { Devices } from '../../enums/devices';
import { performanceFilters } from './perfomance.filter';
import countriesJson from '@monorepo/tools/src/lib/consts/geos.json';

export const reportFilters = new Map<FilterTypeKey, FilterType>([
	[
		'numberFilters',
		{
			campaignId: {
				columnName: 'Campaign ID',
				cellKey: 'campaignId',
			},
			...performanceFilters.numberFilters,
		} as INumberFilter,
	],
	[
		'stringFilters',
		{
			// id: { columnName: 'Account ID', cellKey: 'id' },
			// name: {
			// 	columnName: 'Account Name',
			// 	cellKey: 'name',
			// },
			domain: {
				columnName: 'Domain',
				cellKey: 'domain',
			},
			campaignName: {
				columnName: 'Campaign Name',
				cellKey: 'campaignName',
			},
			accountName: {
				columnName: 'Account Name',
				cellKey: 'name',
			},
			...performanceFilters.stringFilters,
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			geo: {
				columnName: 'Geo',
				cellKey: 'geo',
				enumOptions: objectToSelectOptions(countriesJson),
				deletable: true,
			},
			AccountType: {
				columnName: 'Account Type',
				cellKey: 'type',
				enumOptions: [
					{ value: 'CPA', label: 'CPA' },
					{ value: 'CPC', label: 'CPC' },
				],
				deletable: true,
			},
			device: {
				columnName: 'Device',
				cellKey: 'device',
				enumOptions: [
					{ value: Devices.Desktop, label: Devices.Desktop },
					{ value: Devices.Mobile, label: Devices.Mobile },
				],
				deletable: true,
			},
			isDeepLink: {
				columnName: 'Deep Link',
				cellKey: 'isDeepLink',
				enumOptions: [
					{ value: 'True', label: 'True' },
					{ value: 'False', label: 'False' },
				],
				deletable: true,
			},
		} as IEnumFilter,
	],
]);
