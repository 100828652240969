import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { createColumnHelper } from '@tanstack/react-table';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { IMFPPStore } from '../../stores';
import { IEntityHeaders, IMergedHeadersReturn } from './index.headers';
import { IUser, UserModel } from '../../models/user.model';
import { CellAction, QuickActionsInCell } from '@monorepo/base/src/components/table/quick-actions-in-cell/quick-actions-in-cell';
import styles from './headers.module.scss';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { useGuard, validateAction } from '../tools/use-guard';
import { PermissionActions } from '../../enums/permissions';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { Avatar } from '@monorepo/base/src/components/avatar/avatar';
import routes from '../../routes/index.routes';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

const columnHelper = createColumnHelper<IUser>();

export const useUsersHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<IUser> => {
	const { EntityActions } = props;
	const { settingsStore } = useStores<IMFPPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;
	const isUserWritePermission = useGuard({ validate: validateAction([PermissionActions.UsersWrite]) });
	const isActAsPermission = useGuard({ validate: validateAction([PermissionActions.ActAs]) });

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				selection: isUserWritePermission || isActAsPermission,
				id: false,
				firstName: true,
				email: true,
				company: true,
			}); // TODO: set default visibility
			tableStore.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	const setColumns = () => {
		const columns = [
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('firstName', {
				header: 'Full Name',
				cell: info => {
					const { row } = info;
					const user = new UserModel(row.original);
					return (
						<div className={styles.fullName}>
							<Avatar lastName={user.lastName} firstName={user.firstName} url={user.getPicture()} />
							{user.firstName} {user.lastName}
						</div>
					);
				},
			}),
			// columnHelper.accessor('lastName', {
			// 	header: 'Last Name',
			// }),
			columnHelper.accessor('email', {
				header: 'Email',
			}),
			columnHelper.accessor('company', {
				header: 'Company',
			}),
		];

		if (isActAsPermission || isUserWritePermission) {
			columns.unshift(
				columnHelper.display({
					id: 'selection',
					enableHiding: false,
					header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
					cell: cellProps => {
						const { row } = cellProps;
						const user = new UserModel(row.original);
						if (!EntityActions || !user.id) {
							return null;
						}
						return (
							<TableMergeColumn>
								<TableSelectionCell cellProps={cellProps} />
								{/* <QuickActionsMenu classes={{ actionsMenu: styles.usersActionsMenu }}>
									<EntityActions entity={user} />
								</QuickActionsMenu> */}
								<QuickActionsInCell>
									{isUserWritePermission && (
										<Tooltip content={`Edit`}>
											<CellAction
												onClick={() => {
													sendGtagEvent({
														action: TrackingActions.Click,
														category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.EDIT_USER,
														value: `user id: ${user.id}`,
													});
												}}
												icon={'edit-02'}
												to={`${routes.usersManagement.edit(user.id)}`}
												newWindow={false}
											/>
										</Tooltip>
									)}
									{isActAsPermission && (
										<Tooltip content={`Act as ${user.firstName}`}>
											<CellAction
												onClick={() => {
													sendGtagEvent({
														action: TrackingActions.Click,
														category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.ACT_AS,
														value: `user id: ${user.id}`,
													});
												}}
												icon={'user-right-02'}
												to={`act-as/verify/${user.id}`}
											/>
										</Tooltip>
									)}
								</QuickActionsInCell>
							</TableMergeColumn>
						);
					},
				})
			);
		}
		return columns;
	};

	return {
		columns: setColumns(),
	};
};
