import { observer } from 'mobx-react';
import { Wire } from './methods/wire';
import { Paypal } from './methods/paypal';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButtonBoxWithIcon } from '@monorepo/base/src/components/gbutton-box-with-icon/gbutton-box-with-icon';
import { Divider } from '@monorepo/base/src/components/divider/divider';
import styles from './billing-form.module.scss';
import { useEffect, useState } from 'react';
import { Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useSearchParams } from 'react-router-dom';
import { IMFPPStore } from '../../../stores';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../error-msg/error-msg';
import { capitalizeFirstLetter } from '@monorepo/tools/src/lib/utils/string';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export enum PaymentTypes {
	Wire = 'WIRE',
	Paypal = 'PAYPAL',
}

export const BillingForm = observer(() => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { billingStore, publisherStore, modalsStore } = useStores<IMFPPStore>();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const { dispatchLog } = useLogs();
	const accountId = searchParams.get('accountId');
	const billingCrudStore = billingStore.getCrud();
	const billing = billingCrudStore.getData();
	const isLoading = billingCrudStore.getIsLoading();
	const formStore = billingCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const error = billingCrudStore.getHttpError();
	const [isEdit, setIsEdit] = useState<boolean>();
	const isSuccess = billingCrudStore.getIsSuccess();

	useEffect(() => {
		if (isSuccess) {
			dispatchLog({
				msg: `Billing was successfully updated`,
				type: DispatchLogsTypes.Success,
			});
			billingCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (accountId) {
			billingCrudStore.get(accountId).then(res => {
				setIsEdit(!!res);
			});
		} else {
			const firstAccountId = publisherStore.getListStore().getData()?.[0]?.id;
			if (firstAccountId) {
				setSearchParams({ accountId: `${firstAccountId}` });
			}
		}
	}, [accountId]);

	const getMethodForm = () => {
		switch (billing.getPaymentType()) {
			case PaymentTypes.Wire:
				return <Wire />;
			case PaymentTypes.Paypal:
				return <Paypal />;
			default:
				return <Wire />;
		}
	};

	const onUpdateForm = () => {
		setFormClicked(true);

		modalsStore.addModal({
			children: (
				<ActionModal
					type={isEdit ? ModalTypes.Warning : ModalTypes.Info}
					primaryBtnTitle={isEdit ? 'Yes, I am sure' : 'Yes'}
					onAction={() => {
						if (accountId) {
							billingStore.getCrud().createOrEdit(accountId);
						}
					}}>
					{isEdit
						? 'Caution: You are about to change your billing information, are you sure?'
						: `Please confirm your intention to update your billing information. Are you certain you'd like to proceed with this change?`}
				</ActionModal>
			),
		});
	};

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	return (
		<div className={styles.formWrapper}>
			<Pagebar classes={{ pagebar: styles.padding }} debugProps={{ dataAttrs: [new DataAttribute('id', 'pagebar')] }}>
				<PagebarTitle>Billing</PagebarTitle>
			</Pagebar>

			<ButtonGroup
				disabled={isLoading}
				defaultValue={billing.getPaymentType() || PaymentTypes.Wire}
				onChange={value => {
					billing.setPaymentType(value.values().next().value);
				}}
				title={'Payment method'}
				className={styles.buttonGroup}
				titleClassName={styles.title}>
				<GButtonBoxWithIcon
					mini
					icon={'bank'}
					title={capitalizeFirstLetter(PaymentTypes.Wire)}
					value={PaymentTypes.Wire}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'wire_button')] }}
				/>
				<GButtonBoxWithIcon
					mini
					icon={'paypal-01'}
					title={capitalizeFirstLetter(PaymentTypes.Paypal)}
					value={PaymentTypes.Paypal}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'paypal_button')] }}
				/>
			</ButtonGroup>

			<Divider />

			<div className={styles.payeeInformation}>
				<h3>Finance contact information</h3>
				<div className={styles.row}>
					<Input
						disabled={isLoading}
						requiredAsterisk={false}
						className={styles.input}
						topLabel={'Contact Email'}
						required
						value={billing.getContactEmail() === null ? undefined : billing.getContactEmail()}
						onValue={value => {
							billing.setContactEmail(value);
							formStore.clearError('contactEmail');
						}}
						error={formStore.getErrors().get('contactEmail')?.getMsg()}
					/>
				</div>
			</div>

			<BarLoader is={isLoading} />
			<Divider />

			{getMethodForm()}

			<BottomBar classes={{ wrapperClassses: styles.bottomBar }}>
				{error ? <ErrorMsg error={error} /> : null}
				<BottomBarActions>
					<PrimaryButton
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.ENTITY.SAVE_BUTTON,
								label: `${isEdit ? 'Edit' : 'Create'} Billing`,
								value: window.location.href,
							});
							onUpdateForm();
						}}
						isRotateIcon={isLoading}
						icon={isLoading ? 'loading-01' : 'send-03'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign_button')] }}
						disabled={billingCrudStore.getIsLoading()}>
						Save
					</PrimaryButton>
				</BottomBarActions>
			</BottomBar>
		</div>
	);
});
