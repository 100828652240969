import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPublisher } from '../../models/publisher.model';
import { PublishersApi } from '../../apis/publishers.api';
import { makeAutoObservable } from 'mobx';
import { IPerformanceOptions } from '../../hooks/apis/use-reports-list';
import { ReportModel } from '../../models/report.model';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { filterPrototypesByProp } from '../../components/filters';
import { IAccount } from '../../models/account.model';

export enum PublisherStoreFiltersKeys {
	Statistics = 'statistics',
}

export class PublisherStore {
	reports = new HttpStore<IPerformanceOptions, ReportModel<IPublisher>>({
		httpFunc: PublishersApi.reports,
		model: ReportModel<IPublisher>,
	});
	metrics = new HttpStore<IPerformanceOptions, ReportModel<IPublisher>>({
		httpFunc: PublishersApi.reports,
		model: ReportModel<IPublisher>,
	});
	list = new HttpStore<undefined, IAccount[]>({
		httpFunc: PublishersApi.list,
	});
	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(PublisherStoreFiltersKeys).map((key: PublisherStoreFiltersKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const publishersFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, publishersFilterByKey);
		});
	}

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}

	getListStore() {
		return this.list;
	}
}
