import { BaseAndCrudRoutes } from '@monorepo/tools/src/lib/routes/base.routes';

export class ApiDocumentaionRoutes extends BaseAndCrudRoutes {
	constructor() {
		super('docs');
	}
	byCampaign() {
		return `${this.baseRoute}/report-by-campaign`;
	}
	bySales() {
		return `${this.baseRoute}/report-by-sales`;
	}
	byClicks() {
		return `${this.baseRoute}/report-by-clicks`;
	}
	inventory() {
		return `${this.baseRoute}/inventory-report`;
	}
}
