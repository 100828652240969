import React from 'react';
import styles from './docs-content.module.scss';
import { useApiKey } from '../../modules/hooks/apis/use-api-key';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const dallasYesterdayString = () => {
	return dayjs().tz('America/Chicago').subtract(25, 'hours').format('YYYY-MM-DD');
};

const dallas2DaysAgoString = () => {
	return dayjs().tz('America/Chicago').subtract(49, 'hours').format('YYYY-MM-DD');
};

export const TitlesAndNotesComponent = () => {
	return (
		<React.Fragment>
			<h1>Welcome to the API Reporting documentation</h1>
			<p>
				This technical document provides developers with detailed information on how to access performance data through our API.
				This API enables you to retrieve crucial insights about your account’s campaigns, sales and clicks.
			</p>
			<h3>Important notes:</h3>
			<h4>Before diving into the API endpoints and procedures, please take note of the following important information:</h4>
			<ul>
				<li>
					<b>Access limits:</b> Each endpoint allows up to 200 requests per 24 HRS, 1 request per 1 minute.
				</li>
				<li>
					<b>Access levels:</b> The reports’ data can be provided at 3 main levels: campaign/sales/clicks.
				</li>
				<li>
					<b>Time Zone:</b> The API response provides date and time information in accordance with Central Daylight Time (CDT) in
					Dallas, TX, USA.
				</li>
				<li>
					<b>Reset time: </b>The API response will also indicate the reset time for the 24-hour request limit.
				</li>
				<li>
					<b>Response Formats: </b>Depending on the 'format' parameter specified, each API request may result in one of three
					output structures: XML, JSON, or CSV (excluding the Inventory report, which is supported in JSON and CSV formats only).
				</li>
				<li>
					<b>API Key Restrictions:</b> Each API request contains data related to the account and complies with restrictions set by
					the account's API key.
				</li>
				<li>
					<b>Currency:</b> The revenue displayed is in USD ($).
				</li>
			</ul>
		</React.Fragment>
	);
};

const ReportByCampignOutputInJson = `"data":{
  "2023-09-12": [{
    "campaignId": 569398,
    "domain": "mediaexpert.pl",
    "geo": "PL",
    "device": "DESKTOP",
    "deeplink": "true",
    "clicks": 16,
    "sales": 0,
    "revenue": 0.00
  },
  {
    "campaignId": 536610,
    "domain": "wayfair.com",
    "geo": "US",
    "device": "DESKTOP",
    "deepLink": "true",
    "clicks": 23,
    "sales": 2,
    "revenue": 15.34
  },
]}`;

export const ByCampignDocumantation = () => {
	const { apiKey, accountFullName } = useApiKey();
	return (
		<React.Fragment>
			<h3>
				<u>Report By Campaign</u>
			</h3>
			<ol type="A">
				<li>
					This report allows a maximum time frame of one month for each request (1 calendar month between start_date and
					end_date).
				</li>
				<li>The data in the response is aggregated according to each date of the request.</li>
			</ol>
			<h4>URL structure example:</h4>
			<p>
				<u>
					<code className={`${styles.code}`} style={{ color: '#1a0dab' }}>
						https://reports-api.mani-festo.com/publishers/campaigns?pid={accountFullName}&key={apiKey}
						&start_date={`${dallas2DaysAgoString()}`}&end_date={`${dallasYesterdayString()}`}&format=json
					</code>
				</u>
			</p>
			<ol>
				<li>HTTP Method: GET</li>
				<li>
					Authentication: Your API key {apiKey !== 'YOUR_ACCOUNT_API_KEY' ? <code className={styles.code}>{apiKey}</code> : ''}
				</li>
				<li>
					PID: Your account name{' '}
					{accountFullName !== 'YOUR_ACCOUNT_NAME' ? (
						<code className={styles.code}>{accountFullName}</code>
					) : (
						'(request it from your account manager)'
					)}
				</li>
				<li>
					<code className={styles.code}>start_date</code> and <code className={styles.code}> end_date</code> format: YYYY-MM-DD
				</li>
				<li>
					<code className={styles.code}>format</code>: Choose between json (default), xml, or csv.
				</li>
			</ol>
			<h4>Output example:</h4>
			<pre style={{ fontSize: '1rem' }}>{ReportByCampignOutputInJson}</pre>
			<h4>Output Values:</h4>
			<table className={styles.tableStyle} cellPadding={5}>
				<thead>
					<tr>
						<th>Metric</th>
						<th>Output value</th>
						<th>Comment</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>campaignId</th>
						<td>Unique 6 digits representing campaign Id</td>
						<td></td>
					</tr>
					<tr>
						<th>domain</th>
						<td>The domain associated with the campaign</td>
						<td></td>
					</tr>
					<tr>
						<th>geo</th>
						<td>The geographic region (e.g., country code) of the campaign targeting</td>
						<td></td>
					</tr>
					<tr>
						<th>deeplink</th>
						<td>A boolean indicating whether a deep link can be used for the campaign (true/false)</td>
						<td></td>
					</tr>
					<tr>
						<th>device</th>
						<td>DESKTOP/MOBILE</td>
						<td>Tablet is included in MOBILE</td>
					</tr>
					<tr>
						<th>clicks</th>
						<td>The number of valid clicks generated by the campaign</td>
						<td>Invalid clicks may result from GEO mismatches, exceeding daily caps, or suspected fraud.</td>
					</tr>
					<tr>
						<th>sales</th>
						<td>The number of conversions generated by the campaign</td>
						<td></td>
					</tr>
					<tr>
						<th>revenue</th>
						<td>The total revenue generated by the campaign</td>
						<td>In USD ($)</td>
					</tr>
				</tbody>
			</table>
		</React.Fragment>
	);
};

const ReportBySalesOutputInJson = `"data": {
  "2023-09-12": [
	{
      "clickDate": "2023-09-11 09:31:56.747",
      "saleDate": "2023-09-12 04:01:02.353",
      "subId": "h4ki6l7GGHY_T6",
      "campaignId": 537542,
      "domain": "lieferando.de",
      "geo": "DE",
      "device": "DESKTOP",
      "deeplink": "false",
      "dp1": "374893”,
      "action": "sale",
      "sales": 1,
      "revenue": 5.28            
    },
    {
      "clickDate": "2023-09-11 15:33:28.389",
      "saleDate": "2023-09-12 04:01:02.353",
      "subId": "_hrr55HyUU8-7",
      "campaignId": 547154,
      "domain": "lacoste.com",
      "geo": "DE",
      "device": "DESKTOP",
      "deeplink": "false",
      "dp1": "442278",
      "action": "click",
      "sales": 1,
      "revenue": 0.75
    },
  ]
}
`;

export const BySalesDocumantation = () => {
	const { apiKey, accountFullName } = useApiKey();
	return (
		<React.Fragment>
			<h3>
				<u>Report By Sales</u>
			</h3>
			<ol type="A">
				<li>
					This report allows a maximum time frame of one month for each request (1 calendar month between start_date and
					end_date).
				</li>
				<li>This report retrieves raw data</li>
			</ol>
			<h4>URL structure example:</h4>
			<p>
				<u>
					<code className={`${styles.code}`} style={{ color: '#1a0dab' }}>
						https://reports-api.mani-festo.com/publishers/sales?pid={accountFullName}&key={apiKey}
						&start_date={`${dallas2DaysAgoString()}`}&end_date={`${dallasYesterdayString()}`}&format=json
					</code>
				</u>
			</p>
			<ol>
				<li>HTTP Method: GET</li>
				<li>
					Authentication: Your API key {apiKey !== 'YOUR_ACCOUNT_API_KEY' ? <code className={styles.code}>{apiKey}</code> : ''}
				</li>
				<li>
					PID: Your account name
					{accountFullName !== 'YOUR_ACCOUNT_NAME' ? (
						<code className={styles.code}>{accountFullName}</code>
					) : (
						'(request it from your account manager)'
					)}
				</li>
				<li>
					<code className={styles.code}>start_date</code> and <code className={styles.code}> end_date</code> format: YYYY-MM-DD
				</li>
				<li>
					<code className={styles.code}>format</code>: Choose between json (default), xml, or csv.
				</li>
			</ol>
			<h4>Output example:</h4>
			<pre style={{ fontSize: '1rem' }}>{ReportBySalesOutputInJson}</pre>
			<h4>Output Values:</h4>
			<table className={styles.tableStyle} cellPadding={5}>
				<thead>
					<tr>
						<th>Metric</th>
						<th>Output value</th>
						<th>Comment</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>clickDate</th>
						<td>Specifies the date and time when a click occurred</td>
						<td></td>
					</tr>
					<tr>
						<th>saleDate</th>
						<td>Represents the date and time when the sale resulting from the click occurred</td>
						<td>According to Intango’s server time</td>
					</tr>
					<tr>
						<th>subId</th>
						<td>Used for tracking and may contain unique identifiers, number of characters limited to 255</td>
						<td>Usually used for publisher’s unique click values</td>
					</tr>
					<tr>
						<th>campaignId</th>
						<td>A unique identifier for the campaign</td>
						<td></td>
					</tr>
					<tr>
						<th>domain</th>
						<td>The domain associated with the campaign</td>
						<td></td>
					</tr>
					<tr>
						<th>geo</th>
						<td>The geographic region (e.g., country code) of the campaign targeting</td>
						<td></td>
					</tr>
					<tr>
						<th>deeplink</th>
						<td>A boolean indicating whether a deep link can be used for the campaign (true/false)</td>
						<td>
							In the results, a domain-GEO pair may appear twice: one as a deeplink with a different campaignID and the other
							as HP (deeplink=false)
						</td>
					</tr>
					<tr>
						<th>device</th>
						<td>DESKTOP/MOBILE</td>
						<td>Tablet is included in MOBILE</td>
					</tr>
					<tr>
						<th>dp1,dp2</th>
						<td>Placeholders - holds additional data relevant to the click or conversion event</td>
						<td>Can be used dynamically </td>
					</tr>
					<tr>
						<th>action</th>
						<td>Specifies the type of action that occurred as a result of the click (click/sale)</td>
						<td>
							Relevant <b>only for CPA type</b> payment model publishers who are also getting revshare on dynamic CPC in their
							campaigns
						</td>
					</tr>
					<tr>
						<th>sales</th>
						<td>Indicates whether an actual sale was accrued (0=no actual sale, 1=actual sale)</td>
						<td>
							Relevant <b>only for CPA type</b> payment model publishers who are also getting revshare on dynamic CPC in their
							campaigns
						</td>
					</tr>
					<tr>
						<th>revenue</th>
						<td>The total revenue generated by the campaign</td>
						<td>In USD ($)</td>
					</tr>
				</tbody>
			</table>
		</React.Fragment>
	);
};

const ReportByClicksOutputInJson = `"data": {
  "2023-09-12": [
    {
      "clickDate": "2023-01-01",
      "subId": "ABCHIDjsdjf242C",
      "campaignId": 1050123,
      "domain": "ticketmaster.com",
      "geo": "US",
      "deviceType": "DESKTOP",
      "isDeepLink": "false",
      "dp1": "112233",
      "clicks": 1,
      "revenue": 0.15
    },
    {
      "clickDate": "2023-01-01",
      "subId": "ABDJF65385638FUhhth5",
      "campaignId": 546160,
      "domain": "westmarine.com",
      "geo": "US",
      "device": "DESKTOP",
      "deeplink": "false",
      "dp1": "364456",
      "clicks": 1,
      "revenue": 0.10
    }
  ]
}`;

export const ByClicksDocumantation = () => {
	const { apiKey, accountFullName } = useApiKey();
	return (
		<React.Fragment>
			<h3>
				<u>Report By Clicks</u>
			</h3>
			<ol type="A">
				<li>This report allows a maximum time frame of one day.</li>
			</ol>
			<h4>URL structure example:</h4>
			<p>
				<u>
					<code className={`${styles.code}`} style={{ color: '#1a0dab' }}>
						https://reports-api.mani-festo.com/publishers/clicks?pid={accountFullName}&key={apiKey}&date=
						{`${dallasYesterdayString()}`}&format=json
					</code>
				</u>
			</p>
			<ol>
				<li>HTTP Method: GET</li>
				<li>
					Authentication: Your API key {apiKey !== 'YOUR_ACCOUNT_API_KEY' ? <code className={styles.code}>{apiKey}</code> : ''}
				</li>
				<li>
					PID: Your account name{' '}
					{accountFullName !== 'YOUR_ACCOUNT_NAME' ? (
						<code className={styles.code}>{accountFullName}</code>
					) : (
						'(request it from your account manager)'
					)}
				</li>
				<li>
					<code className={styles.code}> date</code> format: YYYY-MM-DD
				</li>
				<li>
					<code className={styles.code}>format</code>: Choose between json (default), xml, or csv.
				</li>
			</ol>
			<h4>Output example:</h4>
			<pre style={{ fontSize: '1rem' }}>{ReportByClicksOutputInJson}</pre>
			<h4>Output Values:</h4>
			<table className={styles.tableStyle} cellPadding={5}>
				<thead>
					<tr>
						<th>Metric</th>
						<th>Output value</th>
						<th>Comment</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>clickDate</th>
						<td>Specifies the date and time when a click occurred</td>
						<td></td>
					</tr>
					<tr>
						<th>subId</th>
						<td>Used for tracking and may contain unique identifiers, number of characters limited to 255 </td>
						<td>Publisher’s unique value</td>
					</tr>
					<tr>
						<th>campaignId</th>
						<td>A unique identifier for the campaign</td>
						<td></td>
					</tr>
					<tr>
						<th>domain</th>
						<td>The domain associated with the campaign </td>
						<td></td>
					</tr>
					<tr>
						<th>geo</th>
						<td>Always presented in a two-letter code (US,DE, etc.)</td>
						<td></td>
					</tr>
					<tr>
						<th>deeplink</th>
						<td>A boolean indicating whether a deep link can be used for the campaign (true/false)</td>
						<td></td>
					</tr>
					<tr>
						<th>device</th>
						<td>DESKTOP/MOBILE</td>
						<td>Tablet is included in MOBILE</td>
					</tr>
					<tr>
						<th>dp1,dp2</th>
						<td>Placeholders - holds additional data relevant to the click or conversion event</td>
						<td>Can be used dynamically </td>
					</tr>
					<tr>
						<th>clicks</th>
						<td>The amount of valid clicks a campaign received</td>
						<td>
							Invalid clicks are caused by mismatch in the user’s GEO, clicks sent after the daily cap has been reached or
							suspected as fraud
						</td>
					</tr>
					<tr>
						<th>revenue</th>
						<td>The total revenue generated by the campaign</td>
						<td>In USD ($)</td>
					</tr>
				</tbody>
			</table>
		</React.Fragment>
	);
};

const InventoryReportOutputInJson = `{"merchants":[
  {
    "id" : 123456 , 
    "geo" : "US" , 
    "commission" : "7.000%" , 
    "domain" : "example1.com" , 
    "link" : "https://mftrkinx.com/adServe/aff?cmpid=536223&pid=441234&subid={subid}&ap1=source&dp1=441234&dp3=POC" , 
    "deepLink" : "false" , 
    "clickCap" : 0 
  },{
    "id" : 123457 , 
    "geo" : "UK" , 
    "commission" : "0.000% - 4.550%" ,
    "domain" : "example2.com" ,
    "link" : "https://kbx.example.com/adServe/aff?cmpid=123457&pid=441235&subid={subid}&ap1=source&dp1=441235&dp3=POC" , 
    "deepLink" : "false" , 
    "clickCap" : 0 
  }
]}`;

export const InventoryReportDocumantation = () => {
	const { apiKey, accountFullName } = useApiKey();
	return (
		<React.Fragment>
			<h3>
				<u>Inventory Report</u>
			</h3>
			<ol type="A">
				<li>This report allows a maximum time frame of one day.</li>
			</ol>
			<h4>URL structure example:</h4>
			<p>
				<u>
					<code className={`${styles.code}`} style={{ color: '#1a0dab' }}>
						https://reports-api.mani-festo.com/publishers/merchantList?pid={accountFullName}&key={apiKey}
					</code>
				</u>
			</p>
			<ol>
				<li>HTTP Method: GET</li>
				<li>
					Authentication: Your API key {apiKey !== 'YOUR_ACCOUNT_API_KEY' ? <code className={styles.code}>{apiKey}</code> : ''}
				</li>
				<li>
					PID: Your account name{' '}
					{accountFullName !== 'YOUR_ACCOUNT_NAME' ? (
						<code className={styles.code}>{accountFullName}</code>
					) : (
						'(request it from your account manager)'
					)}
				</li>
				<li>
					Format options: json, csv
					<ul style={{ listStyleType: 'circle', marginLeft: '15px' }}>
						<li>If no format is specified, the default response format will be json</li>
					</ul>
				</li>
				<li>
					Filtering options (optional):
					<ul style={{ listStyleType: 'circle', marginLeft: '15px' }}>
						<li>geo (equals; multiple values can be provided, separated by comma)</li>
						<li>domain (contains; multiple values can be provided, separated by comma)</li>
						<li>deeplink (equals; true/false)</li>
					</ul>
				</li>
			</ol>
			<h4>URL filtering structure example:</h4>
			<p>
				<u>
					<code className={`${styles.code}`} style={{ color: '#1a0dab' }}>
						https://reports-api.mani-festo.com/publishers/merchantList?pid={accountFullName}&key={apiKey}
						&geo=de,us&domain=example1, example2&deeplink=true
					</code>
				</u>
			</p>
			<h4>Output example:</h4>
			<pre style={{ fontSize: '1rem' }}>{InventoryReportOutputInJson}</pre>
			<h4>Output Values:</h4>
			<table className={styles.tableStyle} cellPadding={5}>
				<thead>
					<tr>
						<th>Metric</th>
						<th>Output value</th>
						<th>Comment</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>campaignId</th>
						<td>Unique 6 digits representing campaign Id</td>
						<td></td>
					</tr>
					<tr>
						<th>geo</th>
						<td>Always presented in a two-letter code (US,DE, etc.)</td>
						<td>The GEO of a campaign indicates its targeting, there can be only one GEO per campaign</td>
					</tr>
					<tr>
						<th>commission</th>
						<td>Publisher revenue per Click/Conversion</td>
						<td>according to the account payment model (CPC/CPA)</td>
					</tr>
					<tr>
						<th>domain</th>
						<td>The domain associated with the campaign</td>
						<td></td>
					</tr>
					<tr>
						<th>link</th>
						<td>The destination URL for the campaign</td>
						<td></td>
					</tr>
					<tr>
						<th>deeplink</th>
						<td>A boolean value; indicating whether a deep link can be used for the campaign (true/false)</td>
						<td></td>
					</tr>
					<tr>
						<th>clickCap</th>
						<td>Amount of clicks allowed per 24 hours</td>
						<td>
							- Clicks that will be sent over the cap will not be paid. <br />- 0 means there’s no limit.
						</td>
					</tr>
				</tbody>
			</table>
		</React.Fragment>
	);
};
