import { ReactNode } from 'react';
import { UserMenu, UserMenuItem } from '@monorepo/controlled/src/components/user-menu/user-menu';
import styles from './main-navbar.module.scss';
import { MainBreadcrumbs } from '../main-breadcrumbs/main-breadcrumbs';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';
import { DocsMenu } from '../docs-menu/docs-menu';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPPStore } from '../../stores';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';
import routes from '../../routes/index.routes';
import { useNavigate } from 'react-router-dom';

interface IMainNavbar {
	BeginNavbar?: ReactNode;
}

export const MainNavbar = (props: IMainNavbar) => {
	const { authStore, userStore } = useStores<IMFPPStore>();
	const navigate = useNavigate();
	const { BeginNavbar } = props;
	const actedAsId = ActAsHelper.getPUV();

	const onChangePasswordClick = () => {
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.NAVBAR.USER.MENU.CHANGE_PASSWORD,
			label: 'User Menu',
		});
		navigate(routes.usersManagement.changePassword());
	};

	const onPrivacyPolicyClick = () => {
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.NAVBAR.USER.MENU.PRIVACY_POLICY,
			label: 'User Menu',
		});
		window.open(routes.general.privacyPolicy());
	};

	const onTermsAndConditionsClick = () => {
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.NAVBAR.USER.MENU.TERMS_AND_CONDITIONS,
			label: 'User Menu',
		});
		window.open(routes.general.termsAndConditions());
	};

	return (
		<nav className={`${styles.navBar} ${actedAsId ? styles.actAs : ''}`}>
			<div className={styles.begin}>
				{BeginNavbar}
				<MainBreadcrumbs />
			</div>
			<div className={styles.end}>
				<DocsMenu />
				<UserMenu loginPageRoute="/" authStore={authStore} userStore={userStore}>
					<UserMenuItem icon={'pencil-01'} onClick={onChangePasswordClick}>
						Change password
					</UserMenuItem>
					<UserMenuItem icon={'file-06'} onClick={onTermsAndConditionsClick}>
						Terms & Conditions
					</UserMenuItem>
					<UserMenuItem icon={'lock-01'} onClick={onPrivacyPolicyClick}>
						Privacy Policy
					</UserMenuItem>
				</UserMenu>
			</div>
		</nav>
	);
};
