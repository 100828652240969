import { BillingModel, IBilling, IBillingEditForm } from '../../models/billing.model';

import { BillingApi } from '../../apis/billing.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { ErrorPayloadType, HttpError, IHttpError } from '../../models/http-error.model';
import { runInAction } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { PaymentTypes } from '../../components/forms/billing/billing.form';
import { validateEmail } from '@monorepo/tools/src/lib/utils/string';

export class BillingCrudStore extends BaseCrudStore<BillingModel, IBilling, IBillingEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: BillingApi,
			model: BillingModel,
			errorModel: HttpError,
		});
	}

	getCreateFormData(): IBilling {
		const billing = this.getData();

		return {
			id: billing.getId(),
			accountId: billing.getAccountId(),
			paymentType: billing.getPaymentType(),
			payeeName: billing.getPayeeName(),
			billingAddress: billing.getBillingAddress(),
			paymentThreshold: billing.getPaymentThreshold(),
			geo: billing.getGeo(),
			bankName: billing.getBankName(),
			bankNumber: billing.getBankNumber(),
			bankAccountName: billing.getBankAccountName(),
			sortCode: billing.getSortCode(),
			swift: billing.getSwift(),
			iban: billing.getIban(),
			email: billing.getPaypalEmail(),
			contactEmail: billing.getContactEmail(),
		};
	}

	public getEditFormData(): IBillingEditForm {
		const billing = this.getData();

		return {
			id: billing.getAccountId(),
			accountId: billing.getAccountId(),
			paymentType: billing.getPaymentType(),
			payeeName: billing.getPayeeName(),
			billingAddress: billing.getBillingAddress(),
			paymentThreshold: billing.getPaymentThreshold(),
			geo: billing.getGeo(),
			bankName: billing.getBankName(),
			bankNumber: billing.getBankNumber(),
			bankAccountName: billing.getBankAccountName(),
			sortCode: billing.getSortCode(),
			swift: billing.getSwift(),
			iban: billing.getIban(),
			bankAccountNumber: billing.getBankAccountNumber(),
			bankAddress: billing.getBankAddress(),
			email: billing.getPaypalEmail(),
			contactEmail: billing.getContactEmail(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		const billing = this.getData();

		if (!validateEmail(billing.getContactEmail() || '')) {
			this.formStore.addError(new FormError('contactEmail', 'Please provide a valid email'));
		}

		if (billing.getPaymentType() === PaymentTypes.Wire) {
			if (!billing.getPayeeName()) {
				this.formStore.addError(new FormError('payeeName', 'Please provide a payee name'));
			}

			if (!billing.getBillingAddress()) {
				this.formStore.addError(new FormError('billingAddress', 'Please provide a billing address'));
			}

			if (!billing.getBankName()) {
				this.formStore.addError(new FormError('bankName', 'Please provide a bank name'));
			}

			if (!billing.getBankNumber()) {
				this.formStore.addError(new FormError('bankNumber', 'Please provide a bank number'));
			}

			if (!billing.getBankAccountNumber()) {
				this.formStore.addError(new FormError('bankAccountNumber', 'Please provide a bank account number'));
			}

			if (!billing.getBankAccountName()) {
				this.formStore.addError(new FormError('bankAccountName', 'Please provide a bank account name'));
			}

			if (!billing.getBankAddress()) {
				this.formStore.addError(new FormError('bankAddress', 'Please provide a bank account name'));
			}

			if (!billing.getSortCode()) {
				this.formStore.addError(new FormError('sortCode', 'Please enter sort code'));
			}

			if (!billing.getSwift()) {
				this.formStore.addError(new FormError('swift', 'Please enter swift'));
			}

			if (!billing.getIban()) {
				this.formStore.addError(new FormError('iban', 'Please enter iban'));
			}
		} else {
			if (!validateEmail(billing.getPaypalEmail() || '')) {
				this.formStore.addError(new FormError('email', 'Please provide a valid email'));
			}
		}

		if (billing.getPaymentThreshold() && (billing.getPaymentThreshold() || 0) < 500) {
			this.formStore.addError(new FormError('paymentThreshold', 'Please make sure that payment threshold is at least 500$'));
		}

		return this.formStore.getIsValid();
	}

	public onError(error: IHttpError) {
		if (error?.errorPayload?.type === ErrorPayloadType.Properties) {
			Object.keys(error.errorPayload?.data).forEach(key => {
				this.formStore.addError(new FormError(key, error.errorPayload?.data[key] as string));
			});
		}
	}
	async createOrEdit(accountId: id) {
		const isValid = this.isValid();
		this.setHttpError(null);
		if (!isValid) {
			return;
		}

		if (!accountId) {
			this.setIsSuccess(false);
			return;
		}

		this.setIsSuccess(false);
		this.setIsLoading(true);

		return this.apiLayer
			.edit(accountId.toString(), this.getEditFormData())
			.then(() => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
			})
			.catch(error => {
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					if (this.errorModel) {
						this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response.status }));
					}
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
			});
	}

	get(id: id, options?: URLSearchParams): Promise<void | BillingModel> {
		if (this.getIsLocalCache()) {
			return Promise.resolve();
		}

		this.setIsLocalCache(true);
		this.setIsLoading(true);

		return this.apiLayer
			.get(id, options)
			.then(res => {
				const data = new this.model(res);
				runInAction(() => {
					this.setIsLocalCache(false); //TODO: move to finally
					this.setData(data);
					this.setIsLoading(false);
				});
				return data;
			})
			.catch(error => {
				// TODO - log
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					if (this.errorModel) {
						this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response.status }));
					}

					this.setIsLocalCache(false);
					this.reset();
				});
			});
	}
}
