import { makeAutoObservable } from 'mobx';
import { BillingCrudStore } from './billing-crud.store';

export class BillingStore {
	crud = new BillingCrudStore();

	constructor() {
		makeAutoObservable(this);
	}

	getCrud() {
		return this.crud;
	}
}
