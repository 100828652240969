import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { UserModel } from '../../models/user.model';
import { IMFPPStore } from '../../stores';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';

export function useUser() {
	const { userStore } = useStores<IMFPPStore>();
	const user = userStore.getUser();
	const userCrudStore = userStore.getCrud();
	const didMount = useDidMount();

	useEffect(() => {
		const userId = user?.getId();
		if (userId && didMount) {
			userStore
				.getCrud()
				.profile()
				.then(user => {
					if (user?.id) {
						userStore.setUser(new UserModel(user));
						if (user.actAs) {
							ActAsHelper.setUser(user.actAs, user.actAs.id);
						}
					}
				});
		}
	}, [didMount]);

	return {
		error: userCrudStore.getHttpError(),
		isLoading: userCrudStore.getIsLoading(),
		isSuccess: userCrudStore.getIsSuccess(),
	};
}
