import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { IMFPPStore } from '../../stores';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export function useAccounts() {
	const { publisherStore } = useStores<IMFPPStore>();
	const accountHttpStore = publisherStore.getListStore();

	useEffect(() => {
		const startTime = Date.now();
		accountHttpStore.fetch(undefined).then(accounts => {
			if (!accounts || !accounts.length) {
				return;
			}
			const totalTime = ((Date.now() - startTime) / 1000).toFixed(2);
			sendGtagEvent({
				action: TrackingActions.ApiResponse,
				category: EVENTS.GENERAL.PAGE_LOAD_SPEED,
				label: window.location.href,
				value: totalTime,
			});
			publisherStore.getListStore().setData(accounts);
		});
	}, []);

	return {
		error: accountHttpStore.getHttpError(),
		isLoading: accountHttpStore.getIsLoading(),
		isSuccess: accountHttpStore.getIsSuccess(),
		accountList: accountHttpStore.getData(),
	};
}
