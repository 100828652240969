import { ReactNode } from 'react';
import styles from './quick-actions-in-cell.module.scss';
import { SecondaryLink } from '../../buttons/buttons';

interface IQuickActionsInCell {
	children: ReactNode;
}

interface ICellAction {
	iconSize?: string;
	to?: string;
	icon?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	newWindow?: boolean;
}

export const QuickActionsInCell = (props: IQuickActionsInCell) => {
	const { children } = props;
	return <div className={styles.quickActionsInCell}>{children}</div>;
};

export const CellAction = (props: ICellAction) => {
	const { icon, iconSize, to, onClick, newWindow = true } = props;

	return (
		<div className={styles.cellAction}>
			<SecondaryLink
				className={`${styles.action} cellQuickActions`}
				icon={icon}
				iconSize={iconSize}
				to={to}
				onClick={onClick}
				newWindow={newWindow}
			/>
		</div>
	);
};
