import { NotificationsStore } from '@monorepo/controlled/src/stores/notifications.store';
import { AuthStore } from '@monorepo/controlled/src/stores/auth.store';
import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { DateStore } from '@monorepo/controlled/src/stores/date.store';
import { ModalsStore } from '@monorepo/controlled/src/stores/modals.store';
import { ToastsStore } from '@monorepo/controlled/src/stores/toasts.store';
import { SidebarStore } from '@monorepo/controlled/src/stores/sidebar.store';
import { AnnouncementsStore } from '@monorepo/controlled/src/stores/announcements.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TooltipsStore } from '@monorepo/controlled/src/stores/tooltips.store';
import { UserStore } from './users/user.store';
import { PublisherStore } from './reports/publisher.store';
import { UsersStore } from './users/users.store';
import { InventoryStore } from './inventory/inventory.store';
import { BillingStore } from './billing/billing.store';

export interface IMFPPStore {
	sidebarStore: SidebarStore;
	toastsStore: ToastsStore;
	modalsStore: ModalsStore;
	authStore: AuthStore;
	formStore: FormStore;
	notificationsStore: NotificationsStore;
	dateStore: DateStore;
	userStore: UserStore;
	usersStore: UsersStore;
	announcementsStore: AnnouncementsStore;
	settingsStore: SettingsStore;
	tooltipsStore: TooltipsStore;
	publisherStore: PublisherStore;
	inventoryStore: InventoryStore;
	billingStore: BillingStore;
}

export class MFPPStore implements IMFPPStore {
	public dateStore: DateStore;
	public formStore: FormStore;
	public modalsStore: ModalsStore;
	public notificationsStore: NotificationsStore;
	public sidebarStore: SidebarStore;
	public toastsStore: ToastsStore;
	public userStore: UserStore;
	public usersStore: UsersStore;
	public authStore: AuthStore;
	public announcementsStore: AnnouncementsStore;
	public settingsStore: SettingsStore;
	public tooltipsStore: TooltipsStore;
	public publisherStore: PublisherStore;
	public inventoryStore: InventoryStore;
	public billingStore: BillingStore;

	constructor() {
		this.settingsStore = new SettingsStore();
		this.formStore = new FormStore();
		this.authStore = new AuthStore();
		this.userStore = new UserStore();
		this.usersStore = new UsersStore(this.settingsStore);
		this.notificationsStore = new NotificationsStore();
		this.sidebarStore = new SidebarStore();
		this.toastsStore = new ToastsStore();
		this.modalsStore = new ModalsStore();
		this.dateStore = new DateStore();
		this.announcementsStore = new AnnouncementsStore();
		this.tooltipsStore = new TooltipsStore();
		this.publisherStore = new PublisherStore(this.settingsStore);
		this.billingStore = new BillingStore();
		this.inventoryStore = new InventoryStore(this.settingsStore);
	}
}
