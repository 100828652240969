export enum PermissionActions {
	ReportRead = 'report:read',
	AccountRead = 'account:read',
	UsersRead = 'user:read',
	UsersWrite = 'user:write',
	UsersOnline = 'user:online',
	UserInvite = 'user:invite',
	BillingRead = 'billing:read',
	BillingWrite = 'billing:write',
	Public = 'user:public',
	Internal = 'user:internal',
	ActAs = 'user:act-as',
	inventoryDocs = 'inventory:docs',
}
