import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { IMFPPStore } from '../../../modules/stores';
import { UserForm } from '../../../modules/components/forms/user/user.form';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import styles from './edit-user.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { getUrlLevelUp } from '@monorepo/tools/src/lib/utils/url';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export const EditUser = observer(() => {
	const { usersStore, publisherStore } = useStores<IMFPPStore>();
	const accountList = publisherStore.getListStore().getData();
	const { dispatchLog } = useLogs();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const userCrudStore = usersStore.getCrud();
	const user = userCrudStore.getData();
	const usersList = usersStore.getListStore().getList();

	const navigate = useNavigateParams();
	const location = useLocation();

	const params = useParams();

	// TODO when we will have the API
	// if (params.userId) {
	// useUserGet(params.userId);
	// }

	//TODO remove it when we have the API
	useEffect(() => {
		const userT = usersList.find(user => {
			return user.id == params.userId;
		});

		if (userT && accountList) {
			// userT.domainRoles = domainRolesTransformer(userT.domainRoles, accountList);
			usersStore.getCrud().setData(userT);
		}
	}, [usersList]);

	const closeSiderSlider = (isBlockRequest = false) => {
		navigate(getUrlLevelUp({ pathname: location.pathname, levelsUp: 2 }), { state: { isBlockRequest } as BetweenPagesStateProps });
	};

	useEffect(() => {
		return () => {
			userCrudStore.reset();
		};
	}, [location]);

	const name = user.getFirstName();
	const formStore = userCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = userCrudStore.getIsLoading();
	const isSuccess = userCrudStore.getIsSuccess();
	const error = userCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			closeSiderSlider();
			dispatchLog({
				msg: `User ${name} was successfully edited`,
				type: DispatchLogsTypes.Success,
			});
			userCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onEditUser = () => {
		setFormClicked(true);
		usersStore.getCrud().edit();
	};
	return (
		<SiderSlider className={styles.userSiderSlider} onClose={() => closeSiderSlider(true)} isOpen={true}>
			<Helmet>
				<title>Edit User</title>
			</Helmet>
			<Disable is={isLoading || !user.id}>
				<Page unstyled className={styles.wrapper}>
					<Pagebar>
						<PagebarTitle classes={{ title: styles.title }} unstyled>
							Edit new User
						</PagebarTitle>
					</Pagebar>
					<UserForm />
					<BottomBar classes={{ barClassses: styles.userBottomBar, wrapperClassses: styles.userBottomBarWrapper }}>
						{error ? <ErrorMsg error={error} classes={{ error: styles.errorMsg }} /> : null}
						<BottomBarActions>
							<SecondaryButton
								onClick={() => {
									sendGtagEvent({
										action: TrackingActions.Click,
										category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.FORM.CANCEL,
										label: 'Cancel edit user',
										value: user.getId()?.toString(),
									});
									closeSiderSlider(true);
								}}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'cancel_user_button')] }}>
								Cancel
							</SecondaryButton>
							<PrimaryButton
								onClick={() => {
									sendGtagEvent({
										action: TrackingActions.Click,
										category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.FORM.EDIT,
										label: 'Edit user',
										value: user.getId()?.toString(),
									});
									onEditUser();
								}}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'save_user_button')] }}>
								Edit
							</PrimaryButton>
						</BottomBarActions>
					</BottomBar>
				</Page>
			</Disable>
		</SiderSlider>
	);
});
