import { IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPPStore } from '../../stores/index';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { inventoryFilterPrototypes } from '../../components/filters';
import { InventoryStoreFiltersKeys } from '../../stores/inventory/inventory.store';

interface IInventoryActions {
	availableFilters?: IFilterProto[];
}

export const useInventoryActions = (props: IInventoryActions = {}) => {
	const { availableFilters } = props;
	const { settingsStore } = useStores<IMFPPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars as InventoryStoreFiltersKeys);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [...inventoryFilterPrototypes];

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={availableFilters || DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	return {
		FiltersActions,
	};
};
