import { observer } from 'mobx-react';
import { FC } from 'react';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import styles from '../../../styles/pages.module.scss';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Helmet } from 'react-helmet-async';
import { IMFPPStore } from '../../../modules/stores';
import { useUsersHeaders } from '../../../modules/hooks/theaders/users.headers';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import routes from '../../../modules/routes/index.routes';
import { Outlet } from 'react-router-dom';
import { useList } from '../../../modules/hooks/use-list';
import { useUsersActions } from '../../../modules/hooks/actions/users.actions';
import { Guard } from '../../../modules/components/guard/guard';
import { validateAction } from '../../../modules/hooks/tools/use-guard';
import { PermissionActions } from '../../../modules/enums/permissions';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'users_table')] };

export const UsersManagement: FC = observer(() => {
	const { usersStore } = useStores<IMFPPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	useList(usersStore.getListStore());
	const { FiltersActions, SelectedActions, EntityActions } = useUsersActions();
	const { columns } = useUsersHeaders({ EntityActions });

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Manifesto - Users</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }} debugProps={{ dataAttrs: [new DataAttribute('id', 'pagebar')] }}>
				<PagebarTitle>Users</PagebarTitle>
				<PagebarActions>
					<Guard validate={validateAction([PermissionActions.UserInvite])}>
						<PrimaryLink
							icon={'plus'}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'user_invite')] }}
							onClick={() => {
								sendGtagEvent({
									action: TrackingActions.Click,
									category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.NEW_USER,
								});
							}}
							to={`${routes.usersManagement.create()}`}
							width={'125px'}>
							New User
						</PrimaryLink>
					</Guard>
				</PagebarActions>
			</Pagebar>
			<ControlledTable
				currentRoute={
					currentRouteWithoutSpecialChars === 'usersmanagementcreate' || currentRouteWithoutSpecialChars === 'usersmanagementedit'
						? 'usersmanagement'
						: currentRouteWithoutSpecialChars
				}
				classes={{ wrapper: styles.padding }}
				label={'Users'}
				debugProps={tableDebugProps}
				columns={columns}
				data={usersStore.getListStore().getList()}
				isLoading={usersStore.getListStore().getIsLoading()}
				defaultSortBy={[{ id: 'id', desc: true }]}
				isError={Boolean(usersStore.getListStore().getError())}>
				{() => {
					// { sortedRows }
					// reportStore.setSortedRows(sortedRows);

					return {
						FiltersActions,
						SelectedActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
