import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { makeAutoObservable } from 'mobx';
import { UsersApi } from '../../apis/users.api';
import { UserModel } from '../../models/user.model';
import { UserCrudStore } from './user-crud.store';
import { filterPrototypesByProp } from '../../components/filters';

export enum UsersFiltersStoreKeys {
	Users = 'usersmanagement',
}

export class UsersStore {
	list = new BaseListStore<UserModel>({
		listFunc: UsersApi.list,
		model: UserModel,
	});

	crud = new UserCrudStore();

	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(UsersFiltersStoreKeys).map((key: UsersFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const usersFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, usersFilterByKey);
		});
	}

	getListStore() {
		return this.list;
	}

	getCrud() {
		return this.crud;
	}
}
