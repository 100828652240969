import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from '../routes/index.routes';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { RequireAuth } from '../components/require-auth/require-auth';
import { Home } from '../../pages/home/home.page';
import { AuthLayout } from '../components/layouts/auth-layout/auth-layout';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { RedirectAuth } from '../components/redirect-auth/redirect-auth';
import { SignIn } from '../../pages/signin-signup/signin/signin.page';
import { OAuthLoginRedirect } from '../../pages/signin-signup/signin/signin-redirect';
import { OAuthProviderEnum } from '../enums/oauth-providers';
import { SignUp } from '../../pages/signin-signup/signup/signup.page';
import { TermsAndConditions } from '../../pages/terms-and-conditions/terms-and-conditions';
// import { CreateAccount } from '../../pages/signin-signup/create-account/create-account';
import { VerifyUser } from '../../pages/verify-user/verify-user.page';
import { VerifiedUser } from '../../pages/verified-user/verified-user.page';
import { NotFoundPage } from '../../pages/404/404';
import { SplitLayout } from '../components/layouts/split-layout/split-layout';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { IMFPPStore } from '../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { TwoFactorAuth } from '../../pages/signin-signup/signin/2fa.page';
import { ReportsRouter } from './reports.router';
import { UsersManagementRouter } from './users-management.router';
import { ForgotPassword } from '../../pages/reset-password/forgot-password/forgot-password.page';
import { ResetPassword } from '../../pages/reset-password/reset-password/reset-password.page';
import { RequireAccounts } from '../components/require-accounts/require-accounts';
import { PrivacyPolicy } from '../../pages/privacy-policy/privacy-policy';
import { ApiDocsRouter } from './api-documentation.router';
import { InventoryRouter } from './inventory.router';
import { BillingRouter } from './billing.router';
//import { Maintenance } from '../../pages/maintenance/maintenance';

const Router = () => {
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { settingsStore, tooltipsStore } = useStores<IMFPPStore>();
	//const navigate = useNavigate(); // uncomment for maintenance

	useEffect(() => {
		tooltipsStore.removeTooltips();
		if (!settingsStore.getPages().get(currentRouteWithoutSpecialChars)) {
			settingsStore.getPages().set(currentRouteWithoutSpecialChars, { tableStore: new TableStore() });
		}
		// uncomment after maintenance
		// if (sessionStorage.getItem('harel|sessionId') !== 'blameitonsemamama') {
		// 	navigate(routes.general.maintenance());
		// }
	}, [currentRouteWithoutSpecialChars]);

	const appRoutes = [
		// uncomment after maintenance
		// {
		// 	path: routes.general.maintenance(),
		// 	element: <Maintenance />,
		// },
		{
			path: routes.auth.verified(),
			element: <VerifiedUser />,
		},
		{
			path: routes.auth.verify(),
			element: <VerifyUser />,
		},
		{
			path: routes.general.termsAndConditions(),
			element: <TermsAndConditions />,
		},
		{
			path: routes.general.privacyPolicy(),
			element: <PrivacyPolicy />,
		},
		{
			path: routes.auth.forgetPassword(),
			element: <ForgotPassword />,
		},
		{
			path: routes.auth.resetPassword(),
			element: <ResetPassword />,
		},
		// Unauthorized Routes
		{
			element: (
				<RedirectAuth>
					<AuthLayout />
				</RedirectAuth>
			),
			children: [
				{
					element: <SplitLayout isSticky={true} />,
					children: [
						{
							path: routes.auth.login(),
							element: <SignIn />,
						},
						{
							path: routes.auth.twoFactor(),
							element: <TwoFactorAuth />,
						},
					],
				},
				{
					element: <SplitLayout isSticky={false} />,
					children: [
						{
							path: routes.auth.signUp(),
							element: <SignUp />,
						},
					],
				},
				{
					path: routes.auth.redirect(OAuthProviderEnum.Google),
					element: <OAuthLoginRedirect provider={OAuthProviderEnum.Google} />,
				},
				{
					path: routes.auth.redirect(OAuthProviderEnum.Microsoft),
					element: <OAuthLoginRedirect provider={OAuthProviderEnum.Microsoft} />,
				},
				{
					path: routes.auth.redirect(OAuthProviderEnum.Facebook),
					element: <OAuthLoginRedirect provider={OAuthProviderEnum.Facebook} />,
				},
			],
		},
		// Authorized Routes
		{
			element: (
				<RequireAuth>
					<RequireAccounts>
						<AuthLayout />
					</RequireAccounts>
				</RequireAuth>
			),
			children: [
				{
					element: <PageLayout />,
					children: [
						{
							path: routes.general.home(),
							element: <Home />,
						},
					],
				},
				...UsersManagementRouter,
				...ReportsRouter,
				...ApiDocsRouter,
				...InventoryRouter,
				...BillingRouter,
			],
		},
		// Wildcard
		{
			path: '*',
			element: <SplitLayout />,
			children: [
				{
					path: '*',
					element: <NotFoundPage />,
				},
			],
		},
	];

	return useRoutes(appRoutes);
};
export const MfpRouter = () => {
	const didMount = useDidMount();
	if (!didMount) {
		return <BarLoader is={true} />;
	}

	return (
		<BrowserRouter>
			<Router />
		</BrowserRouter>
	);
};
