import { FC, useEffect } from 'react';
import styles from './home.module.scss';
import { Helmet } from 'react-helmet-async';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import routes from '../../modules/routes/index.routes';

export const Home: FC = () => {
	const navigate = useNavigateParams();

	useEffect(() => {
		navigate(routes.reports.index());
	});
	return (
		<article className={styles.wrapper}>
			<Helmet>
				<title>Manifesto Publishers - Overview</title>
			</Helmet>
			<div>home</div>
		</article>
	);
};
