import { ReportByCampaign } from '../../pages/api-docs/by-campiagn/report-by-campaign.page';
import { ReportByClicks } from '../../pages/api-docs/by-clicks/report-by-clicks.page';
import { ReportBySales } from '../../pages/api-docs/by-sales/report-by-sales.page';
import { InventoryReport } from '../../pages/api-docs/inventory/inventory-report.page';
import { GuardRoute } from '../components/guard/guard-route';
// import { InventoryReport } from '../../pages/api-docs/inventory/inventory-report.page';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { PermissionActions } from '../enums/permissions';
import { validateAction } from '../hooks/tools/use-guard';
import routes from '../routes/index.routes';

export const ApiDocsRouter = [
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.apiDocumentations.byCampaign(),
				element: <ReportByCampaign />,
			},
			{
				path: routes.apiDocumentations.bySales(),
				element: <ReportBySales />,
			},
			{
				path: routes.apiDocumentations.byClicks(),
				element: <ReportByClicks />,
			},
			{
				path: routes.apiDocumentations.inventory(),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.inventoryDocs])}>
						<InventoryReport />
					</GuardRoute>
				),
			},
		],
	},
];
