import { CreateUser } from '../../pages/users-management/create/create-user.page';
import { EditProfile } from '../../pages/users-management/edit-profile/edit-profile.page';
import { EditUser } from '../../pages/users-management/edit/edit-user.page';
import { UsersManagement } from '../../pages/users-management/index/users-management.page';
import { FullLayout } from '../components/layouts/full-layout/full-layout';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { PermissionActions } from '../enums/permissions';
import { validateAction } from '../hooks/tools/use-guard';
import { GuardRoute } from '../components/guard/guard-route';
import routes from '../routes/index.routes';
// import { ActAs } from '../../pages/users-management/act-as/act-as.page';
import { ActAsVerify } from '../../pages/users-management/act-as-verify/act-as-veirfy.page';

export const UsersManagementRouter = [
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.usersManagement.index(),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.UsersRead])}>
						<UsersManagement />
					</GuardRoute>
				),
				children: [
					{
						path: routes.usersManagement.create(),
						element: (
							<GuardRoute validate={validateAction([PermissionActions.UserInvite])}>
								<CreateUser />
							</GuardRoute>
						),
					},
					{
						path: routes.usersManagement.edit(':userId'),
						element: (
							<GuardRoute validate={validateAction([PermissionActions.UsersWrite])}>
								<EditUser />
							</GuardRoute>
						),
					},
				],
			},
			{
				path: routes.usersManagement.actAsVerify(':id'),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.ActAs])}>
						<ActAsVerify />,
					</GuardRoute>
				),
			},
			// {
			// 	path: routes.usersManagement.actAs(':id'),
			// 	element: <ActAs />,
			// },
		],
	},
	{
		element: <FullLayout />,
		children: [
			{
				path: routes.usersManagement.changePassword(),
				element: <EditProfile />,
			},
		],
	},
];
