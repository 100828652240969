import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { IMFPPStore } from '../../stores';
import { useSearchParams } from 'react-router-dom';

export function useInventoryList() {
	const { inventoryStore, publisherStore } = useStores<IMFPPStore>();
	const [searchParams, setSearchParams] = useSearchParams();
	const accountId = searchParams.get('accountId');

	const inventoryHttpStore = inventoryStore.getListStore();

	useEffect(() => {
		if (accountId) {
			inventoryHttpStore.fetch({ accountId }).then(accounts => {
				if (!accounts || !accounts.length) {
					return;
				}
				inventoryHttpStore.setData(accounts);
			});
		} else {
			inventoryHttpStore.reset();
			inventoryHttpStore.abort();
			inventoryHttpStore.setIsLoading(false);
			inventoryHttpStore.setData([]);
			inventoryHttpStore.setHttpError(null);
			const firstAccountId = publisherStore.getListStore().getData()?.[0]?.id;
			if (firstAccountId) {
				setSearchParams({ accountId: `${firstAccountId}` });
			}
		}
	}, [accountId]);

	return {
		error: inventoryHttpStore.getHttpError(),
		isLoading: inventoryHttpStore.getIsLoading(),
		isSuccess: inventoryHttpStore.getIsSuccess(),
		data: inventoryHttpStore.getData(),
	};
}
