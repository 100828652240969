import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { IDomainRoleForm } from '@monorepo/controlled/src/models/domain-role.model';
import styles from './role-selector.module.scss';
import { observer } from 'mobx-react';
import { Fragment } from 'react';

interface IRoleSelector {
	onEditRole: (domainRole: IDomainRoleForm, index: number) => void;
	onRemoveRole: (index: number) => void;
	entityLabel: string;
	entityOptions: string[];
	rolesOptions: string[];
	allowAllRolesSelection?: boolean;
	index: number;
	isAllowRemove?: boolean;
	errorMessages?: {
		entityErrorMessage?: string;
		rolesErrorMessage?: string;
	};
	domainRole: IDomainRoleForm;
}

export const RoleSelector = observer((props: IRoleSelector) => {
	const {
		onEditRole,
		onRemoveRole,
		entityLabel,
		entityOptions,
		rolesOptions,
		allowAllRolesSelection,
		index,
		isAllowRemove,
		errorMessages,
		domainRole,
	} = props;

	const { entityErrorMessage, rolesErrorMessage } = errorMessages || {};

	return (
		<div className={styles.wrapper}>
			<div className={styles.entityWrapper}>
				<Dropdown
					autocomplete={true}
					autocompletePlaceholder={`Choose ${entityLabel}`}
					label={entityLabel}
					onSelect={(entity: Set<option> | undefined) => {
						const selectedEntity = entity?.values().next().value as string;
						domainRole.domain = selectedEntity;
						domainRole.errorMessages = { ...domainRole.errorMessages, entityErrorMessage: '' };
						onEditRole(domainRole, index);
					}}
					defaultOption={typeof domainRole.domain === 'string' ? (domainRole.domain as string) : undefined}
					options={allowAllRolesSelection ? ['All', ...entityOptions] : entityOptions}
					className={styles.entityDropdown}
					required
					error={entityErrorMessage}
				/>
			</div>
			<Fragment>
				<div className={styles.rolesWrapper}>
					<Dropdown
						disabled={!domainRole.domain}
						isCloseIcon={false}
						label="Roles"
						defaultOptions={new Set(domainRole.roles || [])}
						showClearAllButton={false}
						options={rolesOptions}
						className={styles.rolesDropdown}
						multi
						error={rolesErrorMessage}
						onSelect={(role: Set<option> | undefined) => {
							const selectedRoles = Array.from(role?.values() || []) as string[];
							domainRole.roles = selectedRoles;
							domainRole.errorMessages = { ...domainRole.errorMessages, rolesErrorMessage: '' };
							onEditRole(domainRole, index);
						}}
					/>
				</div>
				<Icon
					isMFP
					className={`${styles.removeIcon} ${isAllowRemove ? '' : styles.disabled}`}
					onClick={() => {
						if (!isAllowRemove) {
							return null;
						}
						onRemoveRole(index);
					}}>
					trash-02
				</Icon>
			</Fragment>
		</div>
	);
});
