import { getConfig, IConfig } from '../get-config';
import { Envs } from '../enums/env';
import { Cherami, CheramiConstructorParamsType } from './cherami';
import { Ga4, Ga4ConstructorParamsType } from './ga4';

export type AnalyticsEventMessageType = { action: string; params: Record<string, string | number | boolean | null | undefined> };
export type AnalyticsConstructorType = {
	cherami?: CheramiConstructorParamsType;
	ga4?: Ga4ConstructorParamsType;
	supportedEnvs?: Set<Envs>;
};

export class Analytics {
	private static instance: Analytics;

	private readonly cheramiInstance?: Cherami;
	private readonly ga4Instance?: Ga4;
	private readonly config: IConfig<unknown>;
	private readonly supportedEnvs?: Set<Envs>;

	private constructor(params: AnalyticsConstructorType) {
		this.config = getConfig();
		if (params?.supportedEnvs instanceof Set) {
			this.supportedEnvs = params.supportedEnvs;
		}
		let shareEnvs = false;
		if (this.supportedEnvs instanceof Set) {
			if (!this.supportedEnvs.has(this.config.env.projectEnv as Envs)) {
				return;
			}
			shareEnvs = true;
		}
		if (params.cherami && params?.cherami === Object(params?.cherami)) {
			if (shareEnvs) {
				params.cherami.supportedEnvs = this.supportedEnvs;
			}
			this.cheramiInstance = Cherami.getInstance(params.cherami as CheramiConstructorParamsType);
		}
		if (params.ga4 && params?.ga4 === Object(params?.ga4)) {
			if (shareEnvs) {
				params.ga4.supportedEnvs = this.supportedEnvs;
			}
			this.ga4Instance = Ga4.getInstance(params.ga4 as Ga4ConstructorParamsType);
		}
	}

	getInitScript = () => {
		return [this?.ga4Instance?.getInitScript(), this?.cheramiInstance?.getInitScript()].filter(Boolean);
	};

	getGa4Instance = () => {
		return this.ga4Instance;
	};

	async event(event: AnalyticsEventMessageType) {
		if (!event?.action?.length) {
			return;
		}
		await this.events([event]);
	}

	async events(events: AnalyticsEventMessageType[]) {
		if (!this.ga4Instance || !this.cheramiInstance) {
			const config = getConfig();
			if (config.env.projectEnv !== Envs.Production) {
				throw new Error('Invalid Analytics, ');
			}
			return;
		}
		if (!Array.isArray(events) || !events.length) {
			return;
		}

		if (this.cheramiInstance) {
			this.cheramiInstance.events(events);
		}
		if (this.ga4Instance) {
			this.ga4Instance.events(events);
		}
	}

	static getInstance(params?: AnalyticsConstructorType): Analytics | undefined {
		if (!this.instance && params === Object(params)) {
			return this.init(params as AnalyticsConstructorType);
		}
		return this.instance;
	}

	static init(params: AnalyticsConstructorType): Analytics {
		this.instance = new Analytics(params);
		return this.instance;
	}
}
