import { ask } from '../tools/ask/ask';
import { getConfig, IConfig } from '../get-config';
import { Envs } from '../enums/env';
import { AnalyticsEventMessageType } from './analytics';

export type CheramiConstructorParamsType = { url: string; supportedEnvs?: Set<Envs> };

export class Cherami {
	private static instance: Cherami;

	private readonly config: IConfig<unknown>;
	private readonly supportedEnvs = new Set<Envs>([Envs.Production, Envs.Staging]);
	private readonly url?: string;

	private constructor(params: CheramiConstructorParamsType) {
		this.config = getConfig();
		if (params?.supportedEnvs instanceof Set) {
			this.supportedEnvs = params.supportedEnvs;
		}
		if (!this.supportedEnvs.has(this.config.env.projectEnv as Envs)) {
			return;
		}

		this.url = params.url;
	}

	getInitScript = () => {
		return null;
	};

	async event(event: AnalyticsEventMessageType) {
		if (!event?.action?.length) {
			return;
		}
		await this.events([event]);
	}

	async events(events: AnalyticsEventMessageType[]) {
		if (!this.url) {
			const config = getConfig();
			if (config.env.projectEnv !== Envs.Production) {
				throw new Error('Invalid Cherami Url, ');
			}
			return;
		}
		if (!Array.isArray(events) || !events.length || !this.url) {
			return;
		}
		const body = [];
		for (const e of events) {
			if (!e?.action?.length) {
				continue;
			}
			body.push({ action: e.action, ...e.params });
		}

		if (!body.length) {
			return;
		}

		await ask.post(this.url, body);
	}

	static getInstance(params: CheramiConstructorParamsType): Cherami | undefined {
		if (!this.instance && params?.url?.length) {
			return this.init(params);
		}
		return this.instance;
	}

	static init(params: CheramiConstructorParamsType): Cherami {
		this.instance = new Cherami(params);
		return this.instance;
	}
}
