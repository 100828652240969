import { FC } from 'react';
import styles from './terms-and-conditions.module.scss';
import { Helmet } from 'react-helmet-async';

export const TermsAndConditions: FC = () => {
	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>Manifesto - Terms & Conditions</title>
			</Helmet>
			<div className={styles.paragraphsWrapper}>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Manifesto Publisher Platform – Terms and Conditions</div>
					<div className={styles.date}>Last updated December 12, 2023</div>
					<div className={styles.content}>
						<p>
							These Terms and Conditions (the “<b>Agreement</b>”) are a legally binding contract between the entity accepting
							and agreeing to the terms of this Agreement (“<b>you</b>”, “<b>publisher</b>”, “<b>user</b>”) and Intango Ltd.
							(“
							<b>Intango</b>”), that is the creator and provider of the Manifesto Publisher Platform and its services (“
							<b>Manifesto Publisher Platform</b>” and or the "<b>Services</b>", as applicable).
						</p>
						<p>
							PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING MANIFESTO PUBLISHER PLATFORM SERVICES. THESE TERMS
							AND CONDITIONS GOVERN YOUR USE OF THE MANIFESTO PUBLISHER PLATFORM.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Acceptance of Terms</div>
					<div className={styles.content}>
						<p>
							By using any portion of the Manifesto Publisher Platform, you agree to comply with and be bound by this
							Agreement and any and all agreements with Intango, laws, policies, regulations, and guidelines applicable and/or
							incorporated by reference.
						</p>
						<p>
							If you do not wish to be bound by this Agreement, do not indicate your acceptance, and do not use the Manifesto
							Publisher Platform
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Description of Service</div>
					<div className={styles.content}>
						<p>
							Manifesto Publisher Platform offers you an accessible way to view and manage the reporting, inventory, and users
							of the campaigns which are managed through Intango.
						</p>
						<p>
							Intango may, at its sole discretion, at any time and without notice, suspend or discontinue the operation of the
							Manifesto Publisher Platform and its Services, or remove, change, or add content.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Payments</div>
					<div className={styles.content}>
						<p>
							You agree to keep valid payment method information, including, for example, PayPal account information or bank
							account (“<b>Payment Details</b>”) on file in your account at all times. Intango has no responsibility nor
							liability regarding the validity or completeness of the above-provided Payment Details. Any payment made by
							Intango to the above-provided Payment Details will be regarded as complete.
						</p>
					</div>
				</div>{' '}
				<div className={styles.paragraph}>
					<div className={styles.heading}>Links To Other Websites</div>
					<div className={styles.content}>
						<p>
							Manifesto Publisher Platform may link to third-party sites or content (collectively, “<b>Third-Party Content</b>
							”). Any use by you of Third-Party Content that is available via Manifesto Publisher Platform Services is subject
							to the terms and conditions of any such third-party. You hereby acknowledge that Intango has no control over and
							assumes no responsibility for Third-Party Content. If you decide to leave the Manifesto Publisher Platform and
							access Third-Party Content, you assume all responsibility and risk and Intango hereby disclaim all liability to
							you or any third party in connection thereto. Intango has no obligation to examine, review or monitor
							Third-Party Content and it is not responsible for the accuracy, completeness, appropriateness, or legality of
							Third-Party Content. For the removal of doubt, by using the Manifesto Publisher Platform you acknowledge that
							Intango shall not be liable for any damages, claims, and/or any other liabilities arising out of Third-Party
							Content.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Requirements To Use</div>
					<div className={styles.content}>
						<p>
							In consideration of your use of the Manifesto Publisher Platform you represent and warrant that: (a) you are of
							legal age to form a binding contract and are not a person or an entity barred from receiving services under the
							laws of your state, province, or country of residence. and (b) your use of the Manifesto Publisher Platform does
							not violate any agreement, applicable law or regulation. If you use the Manifesto Publisher Platform Service,
							you represent that you can be bound by this Agreement. You represent that you are not under any sanctions or
							prohibited from receiving Services pursuant to this Agreement under applicable laws and regulations.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Amendments to this Agreement</div>
					<div className={styles.content}>
						<p>
							Intango may amend this Agreement or other policies that govern the Service from time to time. Any changes to
							this Agreement will be posted at{' '}
							<a href={'https://publishers.mani-festo.com/terms-and-conditions'}>
								https://publishers.mani-festo.com/terms-and-conditions{' '}
							</a>
							, and the posted Agreement will indicate the date the Agreement was last revised. If you do not agree to the
							changes, you must stop using the Manifesto Publisher Platform. By continuing to use the Manifesto Publisher
							Platform after any changes to this Agreement are posted, you agree to be bound by those changes.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Termination</div>
					<div className={styles.content}>
						<p>
							Without prior notice, we may, in our sole discretion, temporarily deactivate or permanently terminate any
							account or the Manifesto Publisher Platform with or without cause. For the avoidance of doubt, Intango shall
							have no liability following the termination of any account or the Manifesto Publisher Platform.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Privacy and your Personal Information</div>
					<div className={styles.content}>
						<p>
							The information you provide Intango through the Service is governed by Manifesto Publisher Platform Privacy
							Policy (“<b>Privacy Policy</b>”). Please read carefully the Manifesto Publisher Platform Privacy Policy found at{' '}
							<a href={'https://publishers.mani-festo.com/privacy-policy'}>
								https://publishers.mani-festo.com/privacy-policy
							</a>
							{'. '}
							Your election to use the Manifesto Publisher Platform Service indicates your acceptance of the terms of the
							Privacy Policy.ou are solely responsible for maintaining the confidentiality of your account and password and
							for restricting access to your account, and you agree to accept responsibility for all activities that occur
							under your account or password. You are responsible for all activities that occur in your user account, and you
							agree that the information you provide to Intango upon registration and at all other times will be true,
							accurate, current, and complete. If you have any reason to believe that your account is no longer secure (e.g.,
							in the event of a loss, theft, or unauthorized disclosure or use of your account, password, etc.), then you
							agree to immediately notify Intango by email to{' '}
							<a href={'mailto:privacy@mani-festo.co'}>privacy@mani-festo.co</a>
							{'. '}
							You may be liable for the losses incurred by Intango or others due to any unauthorized use of your account.
						</p>
						<p>
							Intango is not responsible for any loss or damage to you or to any third party incurred as a result of any
							unauthorized access and/or use of your user account, or otherwise.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Modifications to the service</div>
					<div className={styles.content}>
						<p>
							Intango reserves the right to modify or discontinue, temporarily or permanently, the Manifesto Publisher
							Platform or its Services (or any part thereof), at any time, with or without notice. You agree that Intango
							shall not be liable to you or to any third party for any modification or discontinuance of the Manifesto
							Publisher Platform or its Services.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Licensing</div>
					<div className={styles.content}>
						<p>
							Subject to this Agreement and your initial and ongoing compliance with its terms, you shall have a
							non-exclusive, revocable, non-transferable, non-sublicensable, limited right to access and use the Manifesto
							Publisher Platform and its Services during the term of this Agreement, and solely for the purposes under this
							Agreement, but not otherwise.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Proprietary Rights</div>
					<div className={styles.content}>
						<p>
							You acknowledge and agree that the Manifesto Publisher Platform, the Services, and any necessary software used
							in connection with the Manifesto Publisher Platform (“<b>Software</b>”) contain proprietary and confidential
							information, including copyrights, trademarks, know-how, etc., that is protected by applicable intellectual
							property rights and other laws. You further acknowledge and agree that any of the information presented to you
							through the Manifesto Publisher Platform or by any third-party is protected by copyrights, trademarks, service
							marks, patents, or other proprietary rights and laws. Except as expressly authorized in writing by Intango, you
							agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Manifesto
							Publisher Platform, its Services, or the Software, in whole or in part.
						</p>
						<p>
							You shall not (and shall not allow any third party to) copy, modify, create a derivative work from, reverse
							engineer, reverse assemble, or otherwise attempt to discover any source code, sell, assign, sublicense, grant a
							security interest in or otherwise transfer any right in the Software. You agree not to modify the Software in
							any manner or form, nor to use modified versions of the Software, including (without limitation) for the purpose
							of obtaining unauthorized access to the Manifesto Publisher Platform and its Services
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Trademarks</div>
					<div className={styles.content}>
						<p>
							The names, logos, service marks, trademarks, and any other mark used on Manifesto Publisher Platform are
							registered trademarks or service marks of Intango or its business partners, worldwide. The use of Intango's or
							its business partners' trademarks and any variations thereof in any manner not expressly authorized in writing
							by Intango is strictly forbidden. Intango reserves all rights not expressly granted hereunder.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Confidentiality</div>
					<div className={styles.content}>
						<p>
							The term “Confidential Information” means all trade secrets, know-how, methods, software and all other
							financial, business, regulatory or technical information disclosed by or concerning a party or its group company
							(the “<b>disclosing party</b>”) in relation to this Agreement, but not including any information the party
							receiving the Confidential Information (or its group company) (the “<b>receiving party</b>”) can demonstrate is
							(a) generally available to the public without breach of this Agreement or (b) independently developed by it
							without reliance on such information, as can be evidenced by the receiving party's records. Except for the
							specific rights granted by this Agreement, the receiving party shall not possess, access, use or disclose any of
							the disclosing party’s Confidential Information without its prior written consent, and shall use reasonable care
							to protect the disclosing party’s Confidential Information. Each party shall be responsible for any breach of
							confidentiality by its officers, employees and contractors and those of its group companies.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Disclaimer of Warranties; Limitations of Liability</div>
					<div className={styles.content}>
						<p>
							You expressly understand and agree that Intango and its subsidiaries, affiliates, officers, directors,
							shareholders, employees, and other representatives shall not be liable to you for any injury, loss, claim, or
							direct, indirect, incidental, punitive, special, consequential or exemplary damages, including, but not limited
							to, damages for loss of profits, goodwill, use, data or other tangible or intangible losses (even if Intango has
							been advised of the possibility of such damages), whether based in contract, tort (including negligence) or
							otherwise, resulting from, but not limited to (i) the use or the inability to use the Manifesto Publisher
							Platform Services; (ii) any error or omissions, mistakes or inaccuracies in the Services or results that are
							obtained from the use of the Manifesto Publisher Platform Services; (iii) any loss or damage of any kind
							resulting from the use of the Manifesto Publisher Platform Services or any content posted, transmitted, or
							otherwise made available via the Manifesto Publisher Platform Services; or (iv) any other matter relating to the
							Manifesto Publisher Platform Services. Notwithstanding anything to the contrary contained herein, Intango's
							maximum aggregate liability to you for any causes whatsoever, and regardless of the form of action (or
							omission), will at all times be limited to $20. The exclusions and limitations in this Section apply whether the
							alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if you have
							been advised of the possibility of such damage.
						</p>
						<p>
							Intango does not guarantee, represent, or warrant that your use of the Manifesto Publisher Platform and its
							Service will be uninterrupted, timely, secure, or error-free. Intango does not warrant that the data or the
							results that may be obtained from the use of the Manifesto Publisher Platform Service will be accurate or
							reliable.
						</p>
						<p>
							YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE MANIFESTO PUBLISHER PLATFORM AND ITS SERVICES IS AT YOUR
							SOLE RISK. THE MANIFESTO PUBLISHER PLATFORM AND ITS SERVICES ARE PROVIDED ON AN AS-IS-AND-AS-AVAILABLE BASIS.
							INTANGO EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
							TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. INTANGO MAKES NO WARRANTY
							THAT THE MANIFESTO PUBLISHER PLATFORM AND ITS SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. NO
							ADVICE OR INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM INTANGO, ITS EMPLOYEES, AFFILIATES, OR
							REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Indemnification</div>
					<div className={styles.content}>
						<p>
							You agree to indemnify, defend and hold harmless Intango and its officers, directors, shareholders,
							subsidiaries, advertisers, affiliates, and employees, from and against all claims, demands, and expenses,
							including but not limited to reasonable attorneys’ fees and other legal expenses, in whole or in part arising
							out of or attributable to your use of the Intango Publisher Platform or its Service or any breach of this
							Agreement by you or your violation of any law or rights of third-party when using the Manifesto Publisher
							Platform and its Service.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Governing Law & Jurisdiction</div>
					<div className={styles.content}>
						<p>
							This Agreement shall be construed and enforced in accordance with the laws of the state of Israel, without
							reference to its conflicts of law principles. The courts located in Tel Aviv, Israel shall have exclusive
							jurisdiction to adjudicate any dispute arising out of this Agreement, and each party hereby expressly consents
							to the personal jurisdiction of such courts.
						</p>
						<p>
							If for any reason Intango does not enforce or rely on a right that Intango has pursuant to this Agreement, or to
							the general law, such nonenforcement shall not constitute as a waiver or abandonment of that right. Any waiver
							of any provision of this Agreement shall be effective only in writing and signed by Intango.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Severability</div>
					<div className={styles.content}>
						<p>
							If any provision of this Agreement or of any guideline or policy incorporated herein is held to be unlawful,
							void, or for any reason unenforceable, then that provision will be limited or eliminated from this Agreement to
							the minimum extent necessary and will not affect the validity and enforceability of any remaining provisions.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Assignment; Successors and Assigns</div>
					<div className={styles.content}>
						<p>
							This Agreement shall be binding on the undersigned, its successors, and assigns. This Agreement may not be
							assigned or subcontracted by you without the prior written consent of Intango (not to be unreasonably withheld
							or delayed).
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Survival</div>
					<div className={styles.content}>
						<p>
							Any terms of this Agreement that expressly survive termination or expiration, or by their nature ought to
							survive termination or expiration, will survive, including but not limited to provisions regarding
							confidentiality, disclaimers, limitation of liability, indemnification, termination, governing law and
							jurisdiction, notices and other provisions of interpretation and enforcement.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Force Majeure</div>
					<div className={styles.content}>
						<p>
							A delay by Intango in performing its obligations or providing the Services shall not be a breach of this
							Agreement if caused by a pandemic, fire, flood, or other event beyond its reasonable control.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Notices</div>
					<div className={styles.content}>
						<p>
							Other than specific provisions obligating otherwise, all notices, consents, and approvals to you shall be
							delivered in writing to the most recent email address provided by you to Intango. All notices, consents, and
							approvals to Intango must be delivered in writing to the address set out below and to the attention of the Legal
							Department at <a href={'mailto:legal@Intango.com'}>legal@Intango.com</a>. All such notices will be deemed given
							upon the next business day following such electronic mail transmission provided no “undeliverable” notice is
							received. Publisher agrees and acknowledges that it is responsible for ensuring Intango has accurate and current
							information for purposes of providing notice, including its address.
						</p>
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Contact Us</div>
					<div className={styles.content}>
						<p>
							You can contact Intango by submitting any inquiry or request via{' '}
							<a href={'mailto:support@mani-festo.co'}>support@mani-festo.co</a>.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
