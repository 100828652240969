import { currencyFormat, percents } from '@monorepo/tools/src/lib/utils/number';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';
import { Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import styles from './cells.module.scss';
import { Directions, PrimaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { devicesIconMapping } from '../enums/devices';
import countriesJson from '@monorepo/tools/src/lib/consts/geos.json';

export enum CellTypes {
	Date = 'date',
	Currency = 'currency',
	Number = 'number',
	Percentage = 'percentage',
	Boolean = 'boolean',
	Elipsis = 'elipsis',
	DL = 'deeplink',
	Domain = 'domain',
	Link = 'link',
	Geo = 'geo',
	Device = 'device',
	Text = 'text',
}

export interface IRenderCellOptions {
	elipsisMaxWidth?: string;
	elipsisClickable?: boolean;
	linkTo?: string; // TODO - add link to @KOBIA
	digitLen?: number;
	decimalLen?: number;
}

const contriesObj = countriesJson as { [key: string]: string };

export const renderCell = <T,>(type: CellTypes, row: Row<T>, columnId: string, options?: IRenderCellOptions) => {
	let value: string | number | null = row.getValue(columnId);

	switch (type) {
		case CellTypes.Text:
			return <span>{value}</span>;
		case CellTypes.Date:
			return dayjs(value).format('YYYY-MM-DD');
		case CellTypes.Currency:
			return value !== -1 && typeof value === 'number' ? currencyFormat(value, true, options) : '$0';
		case CellTypes.Number:
			return value !== -1 && value !== null ? value.toLocaleString() : '0';
		case CellTypes.Percentage:
			return value !== -1 && typeof value === 'number' ? percents(value) : '0%';
		case CellTypes.Boolean:
			return value ? 'Yes' : 'No';
		case CellTypes.DL:
			return value ? 'True' : 'False';
		case CellTypes.Elipsis:
			return <OverflowColumn data={row.getValue(columnId)} maxWidth={options?.elipsisMaxWidth} />;
		case CellTypes.Link:
			value = value === null ? '' : `${value}`;
			return (
				<PrimaryText onClick={() => window.open(value as string, '_blank')}>
					<span className={styles.link}>
						<OverflowColumn data={row.getValue(columnId)} maxWidth={options?.elipsisMaxWidth} />
						<Icon className={'cellQuickActions'} size={'1rem'} isMFP={true}>
							link-external-01
						</Icon>
					</span>
				</PrimaryText>
			);
		case CellTypes.Domain:
			value = value === null ? '' : `${value}`;
			return (
				<PrimaryText onClick={() => window.open(`https://${value}`, '_blank')}>
					<span className={styles.link}>
						<OverflowColumn data={row.getValue(columnId)} maxWidth={options?.elipsisMaxWidth} />
						<Icon className={'cellQuickActions'} size={'1rem'} isMFP={true}>
							link-external-01
						</Icon>
					</span>
				</PrimaryText>
			);
		case CellTypes.Geo:
			if (value === null) {
				value = 'N/A';
			}
			return (
				<div className={styles.countries}>
					<img src={`/assets/country_logos/${value === 'N/A' || !contriesObj[value] ? 'unknown' : value}.svg`} alt={`${value}`} />
					<span>{contriesObj[value] || value}</span>
				</div>
			);
		// return value;
		case CellTypes.Device:
			if (!value) {
				return null;
			}
			value = `${value}`;
			return (
				<Tooltip direction={Directions.FromRight} content={value} bottom={'10px'}>
					<div className={styles.centeredCell}>
						<Icon color={'#6366f1'} size={'16px'} isMFP={true} font={IconFonts.Outlined}>
							{devicesIconMapping[value]}
						</Icon>
					</div>
				</Tooltip>
			);
		default:
			return value;
	}
};
