import { id } from '@monorepo/tools/src/lib/types/primitives';

export enum AccountTypeEnum {
	CPC = 'CPC',
	CPA = 'CPA',
}

export interface IAccount {
	id: id;
	name: string;
	type: AccountTypeEnum;
}

export class AccountModel implements IAccount {
	id: id;
	name: string;
	type: AccountTypeEnum;

	constructor(account: IAccount) {
		this.id = account?.id;
		this.name = account?.name;
		this.type = account?.type;
	}
}
