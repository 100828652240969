import { useEffect, useState } from 'react';
import { ask, IResponseInterceptor } from '@monorepo/tools/src/lib/tools/ask/ask';
import { HttpStatuses } from '@monorepo/tools/src/lib/enums/http-statuses';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { HttpError, IHttpError } from '../../models/http-error.model';
import { sessionStorageHelper } from '@monorepo/tools/src/lib/tools/storage';
import {
	ActionAnnouncement,
	AnnouncementTypes,
} from '@monorepo/controlled/src/components/announcement/announcements/action-announcement/action-announcement';
import { IMFPPStore } from '../../stores';
import { URL_TO_RETURN_AFTER_LOGOUT } from '../../../lib/consts/storage';
import { KickAnnouncements } from '../announcements/announcemets';
import { isUnauthorizedError } from '@monorepo/tools/src/lib/tools/ask/guards';

// TODO - probably will need to change this functionality
const httpErrorAnnouncementStatuses = [
	HttpStatuses.NOT_ACCEPTABLE,
	HttpStatuses.METHOD_NOT_ALLOWED,
	HttpStatuses.NOT_IMPLEMENTED, // TODO- new announcement for this?
	HttpStatuses.NOT_FOUND, // TODO- new announcement for this?
	HttpStatuses.INTERNAL_SERVER_ERROR,
	HttpStatuses.UNAUTHORIZED,
];

const inlineErrorStatuses = [HttpStatuses.BAD_REQUEST];

export const ErrorsContainer = () => {
	const { announcementsStore, authStore, userStore } = useStores<IMFPPStore>();
	const [isErrorAnnouncement, setErrorAnnouncement] = useState<boolean>(false);
	const [error, setError] = useState<HttpError | null>(null);

	const getErrorAnnouncement = () => {
		if (!isErrorAnnouncement) {
			return null;
		}

		const httpStatus = error?.httpStatus;
		if (httpStatus) {
			if (httpErrorAnnouncementStatuses.includes(httpStatus)) {
				return <KickAnnouncements message={error.message} />;
			}
			if (inlineErrorStatuses.includes(httpStatus)) {
				return null;
			}
		}
		return <KickAnnouncements message={error?.message} />;
	};

	useEffect(() => {
		if (isErrorAnnouncement) {
			const ErrorAnnouncement = getErrorAnnouncement();
			if (!ErrorAnnouncement) {
				return;
			}
			announcementsStore.addAnnouncement({
				children: (
					<ActionAnnouncement type={AnnouncementTypes.Error} onClose={() => setErrorAnnouncement(false)}>
						{ErrorAnnouncement}
					</ActionAnnouncement>
				),
			});
		}
	}, [isErrorAnnouncement]);

	useEffect(() => {
		const serverInterceptor: IResponseInterceptor = {
			error: (response, data) => {
				const httpError = new HttpError({
					httpStatus: response?.status,
					message: data?.payload?.message || data?.message,
					errorPayload: data?.errorPayload,
				} as IHttpError);

				if (isUnauthorizedError(httpError)) {
					const isUserLoggedIn = !!authStore.getToken();

					// Login Error should be handled by the Announcement component
					// ! TALK TO RONT ABOUT THIS "SOLUTION"
					authStore.removeToken(false);
					userStore.removeUser();
					sessionStorageHelper.setItem(URL_TO_RETURN_AFTER_LOGOUT, window?.location?.pathname);

					if (!isErrorAnnouncement && isUserLoggedIn) {
						setErrorAnnouncement(true);
						const customError = new HttpError({
							httpStatus: response?.status,
							message: 'Session expired, please sign in again.',
							errorPayload: data?.errorPayload,
						} as IHttpError);
						setError(customError);
					}
				}
			},
		};
		ask.interceptors.responses.set('Server Error', serverInterceptor);

		// uncomment for testing getting kicked
		// if (ask.interceptors.responses.size > 0) {
		// 	ask.interceptors.responses.forEach((interceptor: IResponseInterceptor) => {
		// 		setTimeout(() => {
		// 			if (interceptor.error) {
		// 				interceptor.error({ status: 401 } as Response, { payload: { message: 'fack' } });
		// 			}
		// 		}, 1500);
		// 	});
		// }

		return () => setErrorAnnouncement(false);
	}, []);

	return null;
};
