import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { InventoryModel } from '../models/inventory.model';
export interface IInventoryRequestParams {
	accountId: id;
}
export const InventoryApi = {
	list: (params: IInventoryRequestParams, options?: IRequestOptions): Promise<InventoryModel[]> => {
		return ask
			.get(endpoints.inventory.get(params.accountId), queryParamsToSearchParams({ ...(options?.queryParams || {}) }))
			.then(res => res?.payload.list);
	},

	downloadCsv: (params: IInventoryRequestParams, options?: IRequestOptions): Promise<File | null> => {
		return ask.download(
			endpoints.inventory.get(params.accountId),
			queryParamsToSearchParams({ format: 'CSV', ...(options?.queryParams || {}) })
		);
	},
};
