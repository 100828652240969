import { CrudEndpoint } from './crud.endpoint';

export class PublishersEndpoint extends CrudEndpoint {
	constructor() {
		super('publishers');
	}
	apiKey(id: string): string {
		return `${this.baseUrl}/${id}/api-key`;
	}
}
