import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { IMFPPStore } from '../../../stores';
import styles from './user-form.module.scss';
import { InputDesc, InputInfo, InputItem, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { RolesSelector } from '@monorepo/controlled/src/components/roles-selector/roles-selector';
import { useEffect, useState } from 'react';
import { UsersApi } from '../../../apis/users.api';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { Disable } from '@monorepo/base/src/components/disable/disable';
// import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';

export const UserForm = observer(() => {
	const { usersStore, publisherStore } = useStores<IMFPPStore>();
	const accountList = publisherStore.getListStore().getData();
	const usersCrudStore = usersStore.getCrud();
	const formStore = usersCrudStore.getFormStore();
	const user = usersCrudStore.getData();
	const { currentRoute } = useRoute();
	const isEditPage = currentRoute.includes('edit');
	const [rolesOptions, setRoles] = useState<string[]>([]);
	const didMount = useDidMount();
	const domainRoles = user.getDomainRolesArray(accountList || []);

	// in order to control the roles selector we need to pass the roles options and the selected roles to internal state
	//@TODO - refactor the roles selector to be controlled component

	// api calls
	useEffect(() => {
		if (didMount) {
			UsersApi.getRoles().then(res => {
				setRoles(res);
			});
			// setDomainRoles(user.getDomainRoles());
		}
	}, [didMount]);

	return (
		<div className={styles.wrapper}>
			<InputItem flexDirection={'column'} gap={'16px'} maxWidth={'300px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Email</InputTitle>
					<InputDesc>Enter the email of the user</InputDesc>
				</InputInfo>
				<Input
					disabled={isEditPage}
					className={styles.input}
					value={user?.getEmail()}
					onValue={value => {
						user.setEmail(value);
						formStore.clearError('email');
					}}
					required={true}
					requiredAsterisk={false}
					error={formStore.getErrors().get('email')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'create_user_email')] }}
				/>
			</InputItem>
			<InputItem flexDirection={'column'} gap={'16px'} maxWidth={'300px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Company</InputTitle>
					<InputDesc>Enter the user company</InputDesc>
				</InputInfo>
				<Input
					className={styles.input}
					value={user?.getCompany()}
					onValue={value => {
						user.setCompany(value);
						formStore.clearError('company');
					}}
					required={true}
					requiredAsterisk={false}
					error={formStore.getErrors().get('company')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'create_user_company_name')] }}
				/>
			</InputItem>
			<InputItem flexDirection={'column'} gap={'16px'} maxWidth={'300px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>First Name</InputTitle>
					<InputDesc>Enter the first name of the user</InputDesc>
				</InputInfo>
				<Input
					className={styles.input}
					value={user?.getFirstName()}
					onValue={value => {
						user.setFirstName(value);
						formStore.clearError('first_name');
					}}
					required={true}
					requiredAsterisk={false}
					error={formStore.getErrors().get('first_name')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'create_user_first_name')] }}
				/>
			</InputItem>

			<InputItem flexDirection={'column'} gap={'16px'} maxWidth={'300px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Last Name</InputTitle>
					<InputDesc>Enter the last name of the user</InputDesc>
				</InputInfo>
				<Input
					className={styles.input}
					value={user?.getLastName()}
					onValue={value => {
						user.setLastName(value);
						formStore.clearError('last_name');
					}}
					required={true}
					requiredAsterisk={false}
					error={formStore.getErrors().get('last_name')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'create_user_last_name')] }}
				/>
			</InputItem>
			{/* <Toggle
				title={'Two steps authenticator'}
				label={user.getRequired2Fa() ? 'Enabled' : 'Disabled'}
				onChange={isEnabled => {
					user.setRequired2Fa(isEnabled);
				}}
				defaultValue={user.getRequired2Fa() === undefined ? true : user.getRequired2Fa()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_user_cap_enabled')] }}
			/> */}
			<Disable is={rolesOptions.length === 0}>
				<RolesSelector
					entityOptions={accountList?.map(account => `${account.name} (${account.id})`) || []}
					rolesOptions={rolesOptions}
					entityLabel={'Account'}
					onRemoveRole={user.removeDomainRole}
					onEditRole={user.editDomainRole}
					onAddRole={user.addDomainRole}
					domainRoles={domainRoles}
					allowAllRolesSelection={rolesOptions.includes('Internal User') ? true : false}
				/>
			</Disable>
		</div>
	);
});
