import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BillingModel, IBilling, IBillingEditForm } from '../models/billing.model';

export interface IBillingRequestParams {
	accountId: id;
}

export const BillingApi = {
	list: async (params?: URLSearchParams): Promise<BillingModel[]> => {
		const res = await ask.get(endpoints.billing.list(), params);
		return res?.payload?.list;
	},
	create: async (billing: IBilling, options?: IRequestOptions): Promise<BillingModel> => {
		const res = await ask.post<IBilling>(endpoints.billing.create(), billing, options);
		return res?.payload?.item;
	},
	edit: async (accountId: id, billing: IBillingEditForm, options?: IRequestOptions): Promise<BillingModel> => {
		const res = await ask.put<IBillingEditForm>(endpoints.billing.edit(accountId), billing, options);
		return res?.payload?.item;
	},
	get: async (accountId: id, options?: URLSearchParams): Promise<BillingModel> => {
		const res = await ask.get(endpoints.billing.get(accountId), options);
		return res?.payload?.item;
	},
	delete: async (accountId: id) => {
		const res = await ask.delete(endpoints.billing.delete(accountId));
		return res?.payload?.ids;
	},
};
