import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { InputItem } from '@monorepo/base/src/components/form/input-item/input-item';
import { CountDown, CountDownEnum } from '@monorepo/base/src/components/countdown/countdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { validateEmail } from '@monorepo/tools/src/lib/utils/string';
import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UsersApi } from '../../../modules/apis/users.api';
import routes from '../../../modules/routes/index.routes';
import { IMFPPStore } from '../../../modules/stores';
import styles from './forgot-password.module.scss';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export const ForgotPassword = observer(() => {
	const { userStore } = useStores<IMFPPStore>();
	const [isCheckYourEmail, setIsCheckYourEmail] = useState<boolean>(false);
	const [isResend, setIsResend] = useState<boolean>(false);
	const userCrudStore = userStore.getCrud();
	const formStore = userCrudStore.getFormStore();
	const user = userCrudStore.getData();
	const navigate = useNavigate();

	const onResetBtn = () => {
		const email = user.getEmail();
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.LOGIN.FOROGOT_PASSOWRD.SUBMIT,
			value: email,
		});
		if (email && validateEmail(email)) {
			UsersApi.forgotPassword(email).then(() => {
				setIsCheckYourEmail(true);
			});
		}
	};

	const onResendClick = () => {
		if (isResend) {
			setIsResend(false);
			onResetBtn();
		}
	};

	const renderCheckYourEmail = () => {
		return (
			<Fragment>
				<div className={styles.iconWrapper}>
					<Icon isMFP={true} color={'#5552EE'} size={'21px'}>
						mail-01
					</Icon>
				</div>
				<div className={styles.description}>
					<div className={styles.primary}>Check your email</div>
					<div className={styles.secondary}>
						<span>We sent a password reset link to</span>
						<span className={styles.sentTo}>{user.getEmail()}</span>
					</div>
				</div>
				<div className={styles.resendEmail}>
					<span>Didn't receive the email?</span>
					<span className={`${styles.link} ${isResend ? '' : styles.disabled}`} onClick={onResendClick}>{`Click To Resend`}</span>
					<span className={styles.countdown}>
						{!isResend ? (
							<CountDown type={CountDownEnum.Seconds} onTimeEnd={() => setIsResend(true)} initialTime={30} />
						) : (
							<span>0</span>
						)}
						<span>&nbsp;seconds</span>
					</span>
				</div>
			</Fragment>
		);
	};

	const renderResetPassword = () => {
		return (
			<Fragment>
				<div className={styles.iconWrapper}>
					<Icon isMFP={true} color={'#5552EE'} size={'21px'}>
						key-01
					</Icon>
				</div>
				<div className={styles.description}>
					<div className={styles.primary}>Can't remember your password?</div>
					<div className={styles.secondary}>No problem - enter your email to get a link to reset it</div>
				</div>
				<InputItem flexDirection={'column'} gap={'16px'}>
					<Input
						className={styles.emailInput}
						placeholder={'Enter your email'}
						value={user?.getEmail()}
						onValue={value => {
							user.setEmail(value);
							formStore.clearError('email');
						}}
						required={true}
						requiredAsterisk={false}
						error={formStore.getErrors().get('email')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'forgot_password_email')] }}
					/>
				</InputItem>
				<PrimaryButton disabled={!validateEmail(user.getEmail() || '')} className={styles.resetBtn} onClick={onResetBtn}>
					Reset Password
				</PrimaryButton>
			</Fragment>
		);
	};

	return (
		<div className={styles.wrapper}>
			{!isCheckYourEmail ? renderResetPassword() : renderCheckYourEmail()}
			<div className={styles.backWrapper}>
				<Icon color={'#475467'} size={'20px'} isMFP={true}>
					arrow-narrow-left
				</Icon>
				<span onClick={() => navigate(routes.auth.login())} className={styles.backText}>
					Back to log in
				</span>
			</div>
		</div>
	);
});
