import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { PublisherModel } from '../models/publisher.model';
import { ReportModel } from '../models/report.model';
import { IAccount } from '../models/account.model';

export const PublishersApi = {
	reports: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<ReportModel<PublisherModel>> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask
			.get(endpoints.publishers.reports(), queryParamsToSearchParams({ p: hashParams, ...(options?.queryParams || {}) }))
			.then(res => res?.payload?.report);
	},
	downloadCsv: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<File | null> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.download(
			endpoints.publishers.reports(),
			queryParamsToSearchParams({ p: hashParams, format: 'CSV', ...(options?.queryParams || {}) })
		);
	},
	list: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<IAccount[]> => {
		return ask
			.get(endpoints.publishers.list(), queryParamsToSearchParams({ ...(options?.queryParams || {}) }))
			.then(res => res?.payload.list);
	},
	apiKey: ({ id }: { id: string }, options?: IRequestOptions): Promise<{ apiKey: string; accountFullName: string }> => {
		return ask
			.get(endpoints.publishers.apiKey(id), queryParamsToSearchParams({ ...(options?.queryParams || {}) }))
			.then(res => res?.payload);
	},
	requestCsv: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<null> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(
			endpoints.publishers.generateCsv(),
			queryParamsToSearchParams({ p: hashParams, format: 'CSV', ...(options?.queryParams || {}) })
		);
	},
};
