import { BillingEndpoint } from './billing.endpoint';
import { InventoryEndpoint } from './inventory.endpoints';
import { PublishersEndpoint } from './publishers.endpoints';
import { UsersEndpoint } from './users.endpoint';

const endpoints = {
	users: new UsersEndpoint(),
	publishers: new PublishersEndpoint(),
	inventory: new InventoryEndpoint(),
	billing: new BillingEndpoint(),
};

export default endpoints;
