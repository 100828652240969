import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { InputInfo, InputItem, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { passwordValidation } from '@monorepo/tools/src/lib/utils/string';
import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UsersApi } from '../../../modules/apis/users.api';
import routes from '../../../modules/routes/index.routes';
import styles from './reset-password.module.scss';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { MFPPStore } from '../../../modules/stores';
import {
	ActionAnnouncement,
	AnnouncementTypes,
} from '@monorepo/controlled/src/components/announcement/announcements/action-announcement/action-announcement';

export const ResetPassword = observer(() => {
	const { announcementsStore } = useStores<MFPPStore>();
	const [isRenderNewPassowrd, setIsRenderNewPassword] = useState<boolean>(false);
	const [passwordState, setPasswordState] = useState<{
		password: {
			value: string;
			errors: {
				lengthError: boolean;
				integerError: boolean;
				letterCaseError: boolean;
				specialCharacterError: boolean;
			};
			show: boolean;
		};
		confirmPassword: {
			value: string;
			errors: {
				matchError: boolean;
			};
			show: boolean;
		};
	}>({
		password: {
			value: '',
			errors: {
				lengthError: true,
				integerError: true,
				letterCaseError: true,
				specialCharacterError: true,
			},
			show: false,
		},
		confirmPassword: {
			value: '',
			errors: {
				matchError: true,
			},
			show: false,
		},
	});
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const navigate = useNavigate();
	const isValid =
		!passwordState.confirmPassword.errors.matchError &&
		!passwordState.password.errors.lengthError &&
		!passwordState.password.errors.integerError &&
		!passwordState.password.errors.letterCaseError &&
		!passwordState.password.errors.specialCharacterError;

	if (!token) {
		navigate(routes.auth.login());
	}

	const onResetBtn = () => {
		if (isValid && token) {
			UsersApi.resetPassword({ password: passwordState.password.value, token })
				.then(() => {
					setIsRenderNewPassword(true);
				})
				.catch(e => {
					announcementsStore.addAnnouncement({
						children: (
							<ActionAnnouncement type={AnnouncementTypes.Error}>
								<div>{e?.data?.message || 'Reset password link expired.'}</div>
							</ActionAnnouncement>
						),
					});
					navigate(routes.auth.login());
				});
		}
	};

	const renderGoToLogin = () => {
		return (
			<Fragment>
				<div className={`${styles.iconWrapper} ${styles.success}`}>
					<Icon isMFP={true} color={'#039855'} size={'21px'}>
						mail-01
					</Icon>
				</div>
				<div className={styles.description}>
					<div className={styles.primary}>Password reset</div>
					<div className={styles.secondary}>
						<span>Your password has been successfully reset. Click below to log in magically.</span>
					</div>
				</div>
				<PrimaryButton className={styles.goToLoginBtn} onClick={() => navigate(routes.auth.login())}>
					Go To Login
				</PrimaryButton>
			</Fragment>
		);
	};

	const renderResetPassword = () => {
		return (
			<Fragment>
				<div className={styles.iconWrapper}>
					<Icon isMFP={true} color={'#5552EE'} size={'21px'}>
						key-01
					</Icon>
				</div>
				<div className={styles.description}>
					<div className={styles.primary}>Set new password</div>
					<div className={styles.secondary}>Create a new password to safeguard your user.</div>
				</div>
				<InputItem flexDirection={'column'} gap={'16px'}>
					<InputInfo className={styles.inputInfoOverwrite}>
						<InputTitle>Password</InputTitle>
					</InputInfo>
					<Input
						type={passwordState.password.show ? 'text' : 'password'}
						after={
							<Icon
								onClick={() => {
									setPasswordState({
										...passwordState,
										password: {
											...passwordState.password,
											show: !passwordState.password.show,
										},
									});
								}}
								className={styles.passwordVisibilityIcon}
								isMFP={true}>
								{!passwordState.password.show ? 'eye' : 'eye-off'}
							</Icon>
						}
						className={styles.passwordInput}
						value={passwordState.password.value}
						onValue={value => {
							setPasswordState({
								confirmPassword: {
									errors: {
										matchError:
											value !== passwordState.confirmPassword.value ||
											passwordState.confirmPassword.value.length === 0 ||
											value.length === 0,
									},
									value: passwordState.confirmPassword.value,
									show: passwordState.confirmPassword.show,
								},
								password: { errors: { ...passwordValidation(value) }, value, show: passwordState.password.show },
							});
						}}
						required={true}
						requiredAsterisk={false}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'password')] }}
					/>
				</InputItem>
				<InputItem flexDirection={'column'} gap={'16px'}>
					<InputInfo className={styles.inputInfoOverwrite}>
						<InputTitle>Confirm Password</InputTitle>
					</InputInfo>
					<Input
						type={passwordState.confirmPassword.show ? 'text' : 'password'}
						after={
							<Icon
								onClick={() => {
									setPasswordState({
										...passwordState,
										confirmPassword: {
											...passwordState.confirmPassword,
											show: !passwordState.confirmPassword.show,
										},
									});
								}}
								className={styles.passwordVisibilityIcon}
								isMFP={true}>
								{!passwordState.confirmPassword.show ? 'eye' : 'eye-off'}
							</Icon>
						}
						className={styles.confirmPasswordInput}
						value={passwordState.confirmPassword.value}
						onValue={value => {
							setPasswordState({
								...passwordState,
								confirmPassword: {
									errors: {
										matchError:
											value !== passwordState.password.value ||
											passwordState.password.value.length === 0 ||
											value.length === 0,
									},
									value,
									show: passwordState.password.show,
								},
							});
						}}
						required={true}
						requiredAsterisk={false}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'confirm_password')] }}
					/>
				</InputItem>
				<div className={styles.passwordErrWrapper}>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={passwordState.password.errors.lengthError ? styles.xIcon : styles.checkIcon}>
							{passwordState.password.errors.lengthError ? 'x-close' : 'check'}
						</Icon>
						Minimum 8 characters
					</div>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={passwordState.password.errors.integerError ? styles.xIcon : styles.checkIcon}>
							{passwordState.password.errors.integerError ? 'x-close' : 'check'}
						</Icon>
						At least one digit (0-9)
					</div>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={passwordState.password.errors.letterCaseError ? styles.xIcon : styles.checkIcon}>
							{passwordState.password.errors.letterCaseError ? 'x-close' : 'check'}
						</Icon>
						At least lowercase and uppercase letters
					</div>
					<div className={styles.passwordErr}>
						<Icon
							isMFP={true}
							className={passwordState.password.errors.specialCharacterError ? styles.xIcon : styles.checkIcon}>
							{passwordState.password.errors.specialCharacterError ? 'x-close' : 'check'}
						</Icon>
						At least one special character
					</div>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={passwordState.confirmPassword.errors.matchError ? styles.xIcon : styles.checkIcon}>
							{passwordState.confirmPassword.errors.matchError ? 'x-close' : 'check'}
						</Icon>
						{passwordState.confirmPassword.errors.matchError ? 'Passwords do not match' : 'Passwords match'}
					</div>
				</div>
				<PrimaryButton className={styles.resetBtn} onClick={onResetBtn} disabled={!isValid}>
					Reset Password
				</PrimaryButton>
				<div className={styles.backWrapper}>
					<Icon color={'#475467'} size={'20px'} isMFP={true}>
						arrow-narrow-left
					</Icon>
					<span onClick={() => navigate(routes.auth.login())} className={styles.backText}>
						Back to log in
					</span>
				</div>
			</Fragment>
		);
	};

	return <div className={styles.wrapper}>{!isRenderNewPassowrd ? renderResetPassword() : renderGoToLogin()}</div>;
});
