import { PageLayout } from '../components/layouts/page-layout/page-layout';
import routes from '../routes/index.routes';
import { Inventory } from '../../pages/inventory/index/inventory.page';
import { GuardRoute } from '../components/guard/guard-route';
import { validateAction } from '../hooks/tools/use-guard';
import { PermissionActions } from '../enums/permissions';

export const InventoryRouter = [
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.inventory.index(),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.ReportRead])}>
						<Inventory />
					</GuardRoute>
				),
			},
		],
	},
];
