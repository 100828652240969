import { makeAutoObservable } from 'mobx';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IInventoryRequestParams, InventoryApi } from '../../apis/inventory.api';
import { InventoryModel } from '../../models/inventory.model';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { filterPrototypesByProp } from '../../components/filters';

export enum InventoryStoreFiltersKeys {
	Inventory = 'inventory',
}

export class InventoryStore {
	list = new HttpStore<IInventoryRequestParams, InventoryModel[]>({
		httpFunc: InventoryApi.list,
	});

	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(InventoryStoreFiltersKeys).map((key: InventoryStoreFiltersKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const publishersFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, publishersFilterByKey);
		});
	}

	getListStore() {
		return this.list;
	}
}
