import { Reports } from '../../pages/reports/index/reports.page';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import routes from '../routes/index.routes';

export const ReportsRouter = [
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.reports.index(),
				element: <Reports />,
			},
		],
	},
];
