import { createColumnHelper } from '@tanstack/react-table';
import { IMergedHeadersReturn } from './index.headers';
import { CellTypes, renderCell } from '../../helpers/cells.helper';
import { IInventory } from '../../models/inventory.model';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccountTypeEnum, IAccount } from '../../models/account.model';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPPStore } from '../../stores';

const columnHelper = createColumnHelper<IInventory>();

export const useInventoryHeaders = (): IMergedHeadersReturn<IInventory> => {
	const { tableStore } = usePageSettings();
	const [searchParams] = useSearchParams();
	const { publisherStore } = useStores<IMFPPStore>();
	const accountList = publisherStore.getListStore().getData();

	const [accountSelected, setAccountSelected] = useState<IAccount | null>(null);

	useEffect(() => {
		const accountId = searchParams.get('accountId') || undefined;
		if (!accountId || !Array.isArray(accountList) || !accountList.length) {
			setAccountSelected(null);
		} else {
			for (const account of accountList) {
				if (`${account?.id}` === accountId) {
					setAccountSelected(account);
				}
			}
		}
	}, [searchParams.get('accountId')]);

	useEffect(() => {
		const colVis = {
			id: false,
			domain: true,
			geo: true,
			deepLink: true,
			vertical: false,
			commission: accountSelected?.type === AccountTypeEnum.CPA,
			cpc: accountSelected?.type === AccountTypeEnum.CPC,
			clickCap: false,
		};
		tableStore?.setDefaultColumnVisibility(colVis);
		tableStore?.setColumnVisibility(colVis);
	}, [tableStore, accountSelected]);

	const setColumns = () => {
		const columns = [
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('domain', {
				header: 'Domain',
				cell: ({ row }) => {
					return <span>{renderCell(CellTypes.Domain, row, 'domain')}</span>;
				},
			}),
			columnHelper.accessor('geo', {
				header: 'Geo',
				cell: ({ row }) => {
					return <span>{renderCell(CellTypes.Geo, row, 'geo')}</span>;
				},
			}),
			columnHelper.accessor('deepLink', {
				header: 'Deep Link',
				cell: ({ row }) => {
					return <span>{renderCell(CellTypes.DL, row, 'deepLink')}</span>;
				},
			}),
			columnHelper.accessor('vertical', {
				header: 'Vertical',
				cell: ({ row }) => {
					return <span>{renderCell(CellTypes.Text, row, 'vertical')}</span>;
				},
			}),
			columnHelper.accessor('commission', {
				header: 'Commission',
				cell: ({ row }) => {
					return accountSelected?.type !== AccountTypeEnum.CPA ? null : (
						<span>{renderCell(CellTypes.Text, row, 'commission')}</span>
					);
				},
			}),
			columnHelper.accessor('cpc', {
				header: 'CPC',
				cell: ({ row }) => {
					return accountSelected?.type !== AccountTypeEnum.CPC ? null : (
						<span>{renderCell(CellTypes.Currency, row, 'cpc', { decimalLen: 5 })}</span>
					);
				},
			}),
			// columnHelper.accessor('link', {
			// 	header: 'Link',
			// 	cell: ({ row }) => {
			// 		return <span>{renderCell(CellTypes.Link, row, 'link')}</span>;
			// 	},
			// }),
			columnHelper.accessor('clickCap', {
				header: 'Click Cap',
				cell: ({ row }) => {
					return <span>{row.getValue('clickCap') == 0 ? '--' : row.getValue('clickCap')}</span>;
				},
			}),
		];
		return columns;
	};

	return {
		columns: setColumns(),
	};
};
