import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { HttpStore, IRequestFlags } from '@monorepo/controlled/src/stores/http.store';
import { IQueryParams } from '@monorepo/tools/src/lib/interfaces/url';
import { useDebug } from '../tools/use-debug';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { FilterActionLabelToActionEnum, FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { ReportModel } from '../../models/report.model';
import { PublisherColumnsNames } from '../../models/publisher.model';
import { PerformanceColumnsNames } from '../../models/performance.model';
//import { stringAction } from '@monorepo/tools/src/lib/utils/string';
//import { numberAction } from '@monorepo/tools/src/lib/utils/number';

export enum Entities {
	Account = 'account',
	Chart = 'chart',
}

export enum PerformancesColumns {
	Date = 'date',
}

export const groupByMapper: Record<string, string> = {
	id: PublisherColumnsNames.AccountId,
	name: PublisherColumnsNames.AccountName,
	type: PublisherColumnsNames.Type,
	campaignId: PublisherColumnsNames.CampaignId,
	campaignName: PublisherColumnsNames.CampaignName,
	geo: PublisherColumnsNames.Geo,
	device: PublisherColumnsNames.Device,
	isDeepLink: PublisherColumnsNames.IsDeepLink,
	date: PublisherColumnsNames.Date,
	domain: PublisherColumnsNames.Domain,
};

export const columnsTitlesToColumnsNames: Record<string, string> = {
	'Account ID': PublisherColumnsNames.AccountId,
	'Account Name': PublisherColumnsNames.AccountName,
	'Account Type': PublisherColumnsNames.Type,
	'Campaign ID': PublisherColumnsNames.CampaignId,
	'Campaign Name': PublisherColumnsNames.CampaignName,
	Geo: PublisherColumnsNames.Geo,
	Device: PublisherColumnsNames.Device,
	'Deep Link': PublisherColumnsNames.IsDeepLink,
	Date: PublisherColumnsNames.Date,
	Clicks: PerformanceColumnsNames.Clicks,
	Revenue: PerformanceColumnsNames.Revenue,
	Sales: PerformanceColumnsNames.Sales,
	Domain: PublisherColumnsNames.Domain,
	'Conversions Rate': PerformanceColumnsNames.ConversionsRate,
	EPC: PerformanceColumnsNames.EPC,
};

interface IPerformanceOptionsPeriod {
	periodStart: string; // YYYY-MM-DD
	periodEnd: string; // YYYY-MM-DD
}

export enum IPerformanceOptionsFilterType {
	Equals = 'EQUALS',
	Include = 'INCLUDE',
	In = 'IN',
}

interface IPerformanceOptionsFilter {
	column: string;
	filterType: string;
	value: string[];
	inverted?: boolean;
}

export enum IPerformanceOptionsDateSegments {
	Daily = 'DAILY',
	None = 'NONE',
}

export enum IPerformanceOptionsSortBy {
	AccountId = 'id',
	AccountName = 'name',
	Type = 'type',
	CampaignId = 'campaignId',
	CampaignName = 'campaignName',
	Geo = 'geo',
	Device = 'device',
	IsDeepLink = 'isDeepLink',
	Hour = 'hour',
	Clicks = 'clicks',
	ConversionsRate = 'conversionsRate',
	Sales = 'sales',
	EPC = 'epc',
	WinRate = 'win_rate',
	Date = 'date',
}

export type PerformanceOptionsSortByStrings = keyof typeof IPerformanceOptionsSortBy;

export const getFilterObject = (filters: Map<number, FilterModel>) => {
	const filtersObject: IPerformanceOptionsFilter[] = [];
	Array.from(filters).forEach(([, filter]) => {
		filtersObject.push({
			column: columnsTitlesToColumnsNames[filter.prototype.MenuComponent] || '',
			filterType:
				filter.prototype.MenuComponent === 'Status'
					? IPerformanceOptionsFilterType.Include
					: FilterActionLabelToActionEnum[(filter.action as keyof typeof FilterActionLabelToActionEnum) || 'contains'] || '',
			value: Array.isArray(filter.value) ? filter.value : [filter?.value.toString() || ''],
			inverted: false,
		});
	});

	return filtersObject;
};

export interface IPerformanceOptions {
	entity?: string;
	// start?: number;
	periodStart?: string; // YYYY-MM-DD
	periodEnd?: string; // YYYY-MM-DD
	multipleTimePeriods?: IPerformanceOptionsPeriod[];
	groupBys?: string[];
	filters?: IPerformanceOptionsFilter[];
	segment?: IPerformanceOptionsDateSegments;
	sortBys?: { column: IPerformanceOptionsSortBy; order: 'asc' | 'desc' }[];
	page?: number;
	limit?: number;
	offset?: number;
}

export const preparePerformanceRequest = (options: IPerformanceOptions): IPerformanceOptions => {
	const { entity, limit, periodStart, periodEnd, multipleTimePeriods, groupBys, filters, sortBys, page, offset } = options;

	const externalOptions: IPerformanceOptions = {
		entity,
		limit: limit || 100000,
		page, // not implemented in backend
		offset,
		periodStart,
		periodEnd,
		multipleTimePeriods: multipleTimePeriods || [],
		groupBys: (() => {
			if (!groupBys) {
				return [];
			}
			// Default is by date, if we add date it ruins the results so remove it for now until fixed in reporting service (probably never blat)
			return groupBys.filter((value, index, self) => self.indexOf(value) === index); // remove duplicates
		})(),
		filters: filters || [],
		segment: options.segment || IPerformanceOptionsDateSegments.None,
		sortBys: sortBys || [{ column: IPerformanceOptionsSortBy.AccountName, order: 'desc' }],
	};

	return externalOptions;
};

//TODO - add aggergateDataBySegment from use-merge-with-performance
export const useReportsList = <TRespose,>(
	httpStore: HttpStore<IPerformanceOptions, ReportModel<TRespose>>,
	options: IPerformanceOptions = {},
	hookOptions: BetweenPagesStateProps = {}
) => {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const location = useLocation();
	const isDebug = useDebug();
	const { startDate, endDate } = useDates();
	const { tableStore } = usePageSettings();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved
	const groupByColumns = options.groupBys || [];
	const sortBys = JSON.stringify(options.sortBys);
	const limit = options?.limit;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const filters = tableStore?.getFiltersStore(currentRouteWithoutSpecialChars)?.getCurrentFilters();
	const [fetchParams, setFetchParams] = useState<IPerformanceOptions>({ ...options, groupBys: groupByColumns, limit: 100000 });

	useEffect(() => {
		httpStore.reset();
		tableStore?.setPageIndex(0);
		fetchReports({ appendData: false, resetOffset: true });
		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				httpStore.abort();
			}
		};
	}, [
		location,
		primaryFromDate,
		primaryToDate,
		didMount,
		JSON.stringify(groupByColumns),
		sortBys,
		JSON.stringify(Array.from(filters || new Map())),
	]); // options

	const fetchReports = (flags?: IRequestFlags) => {
		const { periodStart, periodEnd } = options;
		if (isBlockRequest || !didMount) {
			return;
		}

		const _LIMIT = limit || 10000;
		const _OFFSET = flags?.resetOffset ? 0 : httpStore.getData()?.data?.length || 0;
		const filters = tableStore?.getFiltersStore(currentRouteWithoutSpecialChars)?.getCurrentFilters();
		const filtersArray = getFilterObject(filters || new Map());

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
			limit: _LIMIT,
			offset: _OFFSET,
			filters: [...filtersArray, ...(options.filters || [])],
		});

		const extraQueryParams: IQueryParams = {};
		if (isDebug) {
			extraQueryParams.m = JSON.stringify(params);
		}
		setFetchParams(params);

		httpStore.fetch(params, { queryParams: extraQueryParams }, flags || {});
	};

	return {
		reportsError: httpStore.getHttpError(),
		isLoading: httpStore.getIsLoading(),
		data: httpStore.getData(),
		fetchReports,
		fetchParams,
	};
};
