export enum PerformanceColumnsNames {
	Clicks = 'clicks',
	ConversionsRate = 'conversions_rate',
	Sales = 'sales',
	EPC = 'epc',
	Revenue = 'revenue',
	Date = 'date',
}

export interface IPublisherPerformance {
	clicks?: number;
	epc?: number;
	conversionsRate?: number;
	sales?: number;
	revenue?: number;
	date?: string;
}

export class PublisherPerformanceModel implements IPublisherPerformance {
	clicks?: number;
	epc?: number;
	conversionsRate?: number;
	sales?: number;
	revenue?: number;
	date?: string;

	constructor(publisher: IPublisherPerformance) {
		this.clicks = publisher?.clicks;
		this.epc = publisher?.epc;
		this.conversionsRate = publisher?.conversionsRate;
		this.sales = publisher?.sales;
		this.revenue = publisher?.revenue;
		this.date = publisher?.date;
	}
}
