import { IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { EnumFilterPrototype } from './enum.filter';
import { NumberFilterPrototype } from './number.filter';
import { StringFilterPrototype } from './string.filter';
import { reportFilters } from '../../hooks/filters/report.filters';
import { usersFilters } from '../../hooks/filters/users.filters';
import { inventoryFilters } from '../../hooks/filters/inventory.filter';
import { SelectOptions } from '@monorepo/base/src/components/select-new/select-new';

export interface INumberFilterOptions {
	columnName: string;
	cellKey: string;
	isPercentage?: boolean;
	entity?: string;
}

export interface IStringFilterOptions {
	columnName: string;
	cellKey: string;
	entity?: string;
}

export interface IEnumFilterOptions {
	columnName: string;
	cellKey: string;
	enumOptions: SelectOptions[];
	deletable?: boolean;
	entity?: string;
}

export type FilterType = INumberFilter | IStringFilter | IEnumFilter;

export type FilterTypeKey = 'numberFilters' | 'stringFilters' | 'enumFilters';

export type IEntityFilters = {
	[key in FilterTypeKey]: FilterType;
};

export interface INumberFilter {
	[key: string]: INumberFilterOptions;
}

export interface IStringFilter {
	[key: string]: IStringFilterOptions;
}

export interface IEnumFilter {
	[key: string]: IEnumFilterOptions;
}

enum EntitiesPrefixes {
	Reports = 'reports',
	Users = 'usersmanagement',
	Inventory = 'inventory',
}

const addEntitiesPrefix = (entitiesFilters: Map<FilterTypeKey, FilterType>[], entitiesPrefix: EntitiesPrefixes[]) => {
	entitiesFilters.forEach((entityFilters, index) => {
		entityFilters.forEach(filterType => {
			Object.keys(filterType).forEach((filterKey: string) => {
				filterType[filterKey] = { ...filterType[filterKey] }; // deep clone of performence filters
				filterType[filterKey].entity = entitiesPrefix[index];
			});
		});
	});
};

const setFilterPrototypes = (entityFilters: Map<FilterTypeKey, FilterType>): IFilterProto[] => {
	const filterPrototypes: IFilterProto[] = [];

	for (const key of entityFilters.keys()) {
		if (key === 'numberFilters') {
			const numberFilters = entityFilters.get(key) as INumberFilter;
			Object.keys(numberFilters).forEach(columnKey => {
				filterPrototypes.push(NumberFilterPrototype(numberFilters[columnKey]));
			});
		} else if (key === 'stringFilters') {
			const stringFilters = entityFilters.get(key) as IStringFilter;
			Object.keys(stringFilters).forEach(columnKey => {
				filterPrototypes.push(StringFilterPrototype(stringFilters[columnKey]));
			});
		} else {
			const enumFilters = entityFilters.get(key) as IEnumFilter;
			Object.keys(enumFilters).forEach(columnKey => {
				filterPrototypes.push(EnumFilterPrototype(enumFilters[columnKey]));
			});
		}
	}

	return filterPrototypes;
};

const initializePrototypeFilter = (filterPrototypes: IFilterProto[]) => {
	return filterPrototypes.reduce((prev, currentFilter) => {
		return { ...prev, [currentFilter.prop]: currentFilter };
	}, {});
};

// make sure that filers array and prefixes has the same order
addEntitiesPrefix(
	[reportFilters, usersFilters, inventoryFilters],
	[EntitiesPrefixes.Reports, EntitiesPrefixes.Users, EntitiesPrefixes.Inventory]
);

export const reportFilterPrototypes = setFilterPrototypes(reportFilters);
export const inventoryFilterPrototypes = setFilterPrototypes(inventoryFilters);
export const usersFilterPrototypes = setFilterPrototypes(usersFilters);

export const filterPrototypesByProp: Record<string, IFilterProto> = {
	...initializePrototypeFilter(reportFilterPrototypes),
	...initializePrototypeFilter(usersFilterPrototypes),
	...initializePrototypeFilter(inventoryFilterPrototypes),
};
