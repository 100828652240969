import { FilterType, FilterTypeKey, IEnumFilter, INumberFilter, IStringFilter } from '../../components/filters';

export const usersFilters = new Map<FilterTypeKey, FilterType>([
	['numberFilters', {} as INumberFilter],
	[
		'stringFilters',
		{
			firstName: { columnName: 'First Name', cellKey: 'firstName' },
			lastName: { columnName: 'Last Name', cellKey: 'lastName' },
			email: { columnName: 'Email', cellKey: 'email' },
			company: { columnName: 'Company', cellKey: 'company' },
		} as IStringFilter,
	],
	['enumFilters', {} as IEnumFilter],
]);
