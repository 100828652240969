import { observer } from 'mobx-react';
import { Fragment, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../routes/index.routes';
import { IMFPPStore } from '../../stores';

interface IRedirectAuth {
	children: ReactNode;
}

/**
 * Wrap the children element in this component if you want
 * to redirect authenticated users from the children component to homepage
 */
export const RedirectAuth = observer((props: IRedirectAuth) => {
	const { children } = props;
	const { authStore } = useStores<IMFPPStore>();

	if (authStore.getToken()) {
		// TODO - do we need here used id and is verified user also?
		return <Navigate to={routes.general.home()} replace />;
	}
	return <Fragment>{children}</Fragment>;
});
