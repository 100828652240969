import { id } from '@monorepo/tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';
import { PaymentTypes } from '../components/forms/billing/billing.form';

export interface IBilling {
	id?: id;
	accountId?: string;
	paymentType?: string;
	email?: string;
	payeeName?: string;
	billingAddress?: string;
	paymentThreshold?: number;
	geo?: string;
	geos?: string[];
	bankName?: string;
	bankNumber?: string;
	bankAccountName?: string;
	bankAddress?: string;
	bankAccountNumber?: string;
	sortCode?: string;
	swift?: string;
	iban?: string;
	contactEmail?: string;
}

export interface IBillingEditForm {
	accountId?: string;
	paymentType?: string;
	email?: string;
	payeeName?: string;
	billingAddress?: string;
	paymentThreshold?: number;
	geo?: string;
	geos?: string[];
	bankName?: string;
	bankNumber?: string;
	bankAccountName?: string;
	bankAccountNumber?: string;
	bankAddress?: string;
	sortCode?: string;
	swift?: string;
	iban?: string;
	id?: id;
	contactEmail?: string;
}

export class BillingModel implements IBilling {
	accountId?: string;
	paymentType?: string;
	email?: string;
	payeeName?: string;
	billingAddress?: string;
	paymentThreshold?: number;
	geo?: string;
	geos?: string[];
	bankName?: string;
	bankNumber?: string;
	bankAccountName?: string;
	bankAccountNumber?: string;
	bankAddress?: string;
	sortCode?: string;
	swift?: string;
	iban?: string;
	id?: id;
	contactEmail?: string;

	constructor(billing?: IBilling) {
		this.accountId = billing?.accountId;
		this.paymentType = billing?.paymentType || PaymentTypes.Wire;
		this.email = billing?.email;
		this.payeeName = billing?.payeeName;
		this.billingAddress = billing?.billingAddress;
		this.geo = billing?.geo;
		this.geos = billing?.geos;
		this.paymentThreshold = 500.0;
		this.bankName = billing?.bankName;
		this.bankNumber = billing?.bankNumber;
		this.bankAccountName = billing?.bankAccountName;
		this.bankAccountNumber = billing?.bankAccountNumber;
		this.bankAddress = billing?.bankAddress;
		this.sortCode = billing?.sortCode;
		this.swift = billing?.swift;
		this.iban = billing?.iban;
		this.id = billing?.id;
		this.contactEmail = billing?.contactEmail;
		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public getAccountId(): string | undefined {
		return this.accountId;
	}

	public getPaymentType(): string | undefined {
		return this.paymentType;
	}

	public getPaypalEmail(): string | undefined {
		return this.email;
	}

	public getPayeeName(): string | undefined {
		return this.payeeName;
	}

	public getBillingAddress(): string | undefined {
		return this.billingAddress;
	}

	public getPaymentThreshold(): number | undefined {
		return this.paymentThreshold;
	}

	public getGeo(): string | undefined {
		return this.geo;
	}

	public getGeos(): string[] | undefined {
		return this.geos;
	}

	public getBankName(): string | undefined {
		return this.bankName;
	}

	public getBankNumber(): string | undefined {
		return this.bankNumber;
	}

	public getBankAccountName(): string | undefined {
		return this.bankAccountName;
	}

	public getBankAccountNumber(): string | undefined {
		return this.bankAccountNumber;
	}

	public getSortCode(): string | undefined {
		return this.sortCode;
	}

	public getSwift(): string | undefined {
		return this.swift;
	}

	public getIban(): string | undefined {
		return this.iban;
	}

	public getBankAddress(): string | undefined {
		return this.bankAddress;
	}

	public setId(id: id): void {
		this.id = id;
	}

	public setAccountId(accountId: string): void {
		this.accountId = accountId;
	}

	public setPaymentType(paymentType: string): void {
		this.paymentType = paymentType;
	}

	public setPaypalEmail(paypalEmail: string): void {
		this.email = paypalEmail;
	}

	public setPayeeName(payeeName: string): void {
		this.payeeName = payeeName;
	}

	public setPaymentThreshold(paymentThreshold: number): void {
		this.paymentThreshold = paymentThreshold;
	}

	public setBillingAddress(billingAddress: string): void {
		this.billingAddress = billingAddress;
	}

	// setPaymentThreshold(paymentThreshold: string): void {
	//   this.paymentThreshold = paymentThreshold;
	// }

	public setGeo(geo: string): void {
		this.geo = geo;
	}

	public setGeos(geos: string[]): void {
		this.geos = geos;
	}

	public setBankName(bankName: string): void {
		this.bankName = bankName;
	}

	public setBankNumber(bankNumber: string): void {
		this.bankNumber = bankNumber;
	}

	public setBankAccountName(bankAccountName: string): void {
		this.bankAccountName = bankAccountName;
	}
	public setBankAccountNumber(bankAccountNumber: string): void {
		this.bankAccountNumber = bankAccountNumber;
	}

	public setBankAddress(bankAddress: string): void {
		this.bankAddress = bankAddress;
	}

	public setSortCode(sortCode: string): void {
		this.sortCode = sortCode;
	}

	public setSwift(swift: string): void {
		this.swift = swift;
	}

	public setIban(iban: string): void {
		this.iban = iban;
	}

	public setContactEmail(contactEmail: string): void {
		this.contactEmail = contactEmail;
	}

	public getContactEmail(): string | undefined {
		return this.contactEmail;
	}
}
