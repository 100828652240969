import { observer } from 'mobx-react';
import { Skeleton } from '@monorepo/base/src/components/skeleton/skeleton';
import styles from './main-breadcrumbs.module.scss';

import { AccountPageBreadcrumbs } from './account-page.breadcrumbs';
import { Fragment } from 'react';

interface ISkeletonBreadCrumb {
	isNext?: boolean;
}

// TODO - @MFP remove from controlled
export const SkeletonBreadCrumb = (props: ISkeletonBreadCrumb) => {
	const { isNext } = props;

	return (
		<div className={styles.breadcrumbWrapper}>
			<div className={styles.skeletons}>
				<div className={styles.topSkeleton}>
					<Skeleton is={true} rectanglesAmount={1} />
				</div>
				<div className={styles.bottomSkeleton}>
					<Skeleton is={true} rectanglesAmount={1} />
				</div>
			</div>
			{isNext && <div className={styles.next}>/</div>}
		</div>
	);
};

export const MainBreadcrumbs = observer(() => {
	return (
		<Fragment>
			<AccountPageBreadcrumbs />
		</Fragment>
	);
});
