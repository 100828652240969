import { url } from '@monorepo/tools/src/lib/types/url';
import { email } from '@monorepo/tools/src/lib/types/email';
import { action, makeAutoObservable } from 'mobx';
import { UserStatusEnum } from '../enums/user.enum';
import { capitalCase } from 'change-case';
import { permissionsDomain } from '../hooks/tools/use-guard';
import { PermissionActions } from '../enums/permissions';
import { getStringAfterLastSlash } from '@monorepo/tools/src/lib/utils/string';
import { IAccount } from './account.model';
import { DomainRoleForm } from '@monorepo/controlled/src/models/domain-role.model';

export enum OtpMethod {
	Email = 'email',
	Authenticator = 'authenticator',
	Phone = 'phone',
}

export type DomainRoleType = {
	[domain: string]: string[] | undefined;
};

export const setEntityId = (entityId: string): string => {
	if (entityId === 'All') {
		return '*';
	}
	return entityId;
};

const getEntityId = (entityId: string): string | undefined => {
	if (entityId === '*') {
		return 'All';
	} else if (entityId === 'undefined') {
		return '';
	}
	return entityId;
};

export const domainRolesTransformer = (domainRoles: any[], accountList: IAccount[]): DomainRoleForm[] => {
	if (Object.keys(domainRoles).length === 0) {
		return [new DomainRoleForm({ domain: '', roles: [] })];
	}

	if (accountList.length === 0) {
		return [new DomainRoleForm({ domain: '', roles: [] })];
	}

	const transformedDomainRoles: DomainRoleForm[] = Object.keys(domainRoles).map(entityId => {
		// typescript shit that has to stay because domainRoles is an object and not array
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		const roles = domainRoles[entityId];
		const cleanedEntityId = getEntityId(getStringAfterLastSlash(entityId));
		const account = accountList.find(account => account.id == cleanedEntityId);

		if (!cleanedEntityId) {
			return new DomainRoleForm({
				domain: '',
				roles: [],
			});
		}

		return new DomainRoleForm({
			domain: cleanedEntityId === 'All' ? 'All' : `${account?.name} - (${cleanedEntityId})`,
			roles: cleanedEntityId ? Array.from(roles) : [],
		});
	});

	return transformedDomainRoles;
};

export interface IUser {
	id?: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	picture?: string;
	email?: string;
	isInternal?: boolean;
	password?: string;
	code?: string; // 2fa code
	status?: UserStatusEnum;
	rememberMe?: boolean;
	domainPermissions?: Record<permissionsDomain, PermissionActions[]>;
	domainRoles?: DomainRoleType;
	domainRolesArray?: DomainRoleForm[];
	required2fa?: boolean;
}

export interface IUserCreateForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
	domainRoles?: DomainRoleForm[];
	required2fa?: boolean;
}

export interface IUserEditForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
	domainRoles?: DomainRoleForm[];
	required2fa?: boolean;
}

export class UserModel implements IUser {
	id?: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	picture?: url;
	email?: email;
	isInternal?: boolean;
	password?: string;
	code?: string;
	status?: UserStatusEnum;
	rememberMe?: boolean;
	domainPermissions?: Record<permissionsDomain, PermissionActions[]>;
	permissionsSet: Set<PermissionActions>;
	domainRoles?: DomainRoleType;
	domainRolesArray: DomainRoleForm[];
	required2fa?: boolean;
	actAs?: IUser;

	constructor(user?: IUser) {
		this.id = user?.id;
		this.firstName = capitalCase(user?.firstName || '');
		this.lastName = capitalCase(user?.lastName || '');
		this.company = user?.company;
		this.picture = user?.picture;
		this.email = user?.email;
		this.isInternal = Boolean(user?.isInternal);
		this.status = user?.status;
		this.rememberMe = user?.rememberMe;
		this.domainPermissions = user?.domainPermissions || {};
		this.permissionsSet = new Set<PermissionActions>([PermissionActions.Public]);
		this.domainRoles = user?.domainRoles;
		this.domainRolesArray = [];
		this.required2fa = user?.required2fa;

		// user?.domainRoles?.map(domainRole => ({ entityId: 'dada', roles: domainRole.roles || [], key: randomString(5) })) || [];

		if (user === Object(user)) {
			this.permissionsSet.add(PermissionActions.UsersOnline);
		}

		Object.values(this.domainPermissions).forEach(permissionsArr => {
			this.permissionsSet = new Set([...this.permissionsSet, ...permissionsArr]);
		});
		makeAutoObservable(this, {
			editDomainRole: action,
			addDomainRole: action,
			removeDomainRole: action,
			setActAs: action,
		});
	}

	getId(): string | undefined {
		return this.id;
	}

	setEmail(email: email | undefined): void {
		this.email = email;
	}

	getEmail(): email | undefined {
		return this.email;
	}

	getFullName(): string | undefined {
		if (!this.lastName) {
			return this.firstName;
		} else if (!this.firstName) {
			return this.lastName;
		}
		return `${this.firstName} ${this.lastName}`;
	}

	getCompany(): string | undefined {
		return this.company;
	}

	setCompany(company: string): void {
		this.company = company;
	}

	getPassword(): string | undefined {
		return this.password;
	}

	setPassword(password: string): void {
		this.password = password;
	}

	setFirstName(firstName: string): void {
		this.firstName = firstName;
	}

	getFirstName(): string | undefined {
		return this.firstName;
	}

	setLastName(lastName: string): void {
		this.lastName = lastName;
	}

	getLastName(): string | undefined {
		return this.lastName;
	}

	setStatus(status: UserStatusEnum): void {
		this.status = status;
	}

	getStatus(): UserStatusEnum | undefined {
		return this.status;
	}

	getPicture(): url | undefined {
		return this.picture;
	}

	hasAccounts(): boolean {
		return !this.email?.includes('+1');
	}

	isVerified(): boolean {
		return this.status === UserStatusEnum.ENABLED;
	}

	setRememberMe(rememberMe: boolean) {
		this.rememberMe = rememberMe;
	}

	getRememberMe(): boolean | undefined {
		return this.rememberMe;
	}

	setDomainPermissions(domainPermissions: Record<permissionsDomain, PermissionActions[]>) {
		this.domainPermissions = domainPermissions;
	}

	getDomainPermissions() {
		return this.domainPermissions;
	}

	setCode(code: string) {
		this.code = code;
	}

	getCode() {
		return this.code;
	}

	removeDomainRole = (index: number) => {
		this.domainRolesArray.splice(index, 1);
	};

	editDomainRole = (domainRole: DomainRoleForm, index: number) => {
		this.domainRolesArray[index] = { ...domainRole };
	};

	addDomainRole = () => {
		this.domainRolesArray.push(new DomainRoleForm({ roles: ['View Reports - Required!'], domain: '', errorMessages: {} }));
	};

	setRequired2Fa(required2fa: boolean) {
		this.required2fa = required2fa;
	}

	getRequired2Fa() {
		return this.required2fa;
	}

	getDomainRolesArray(accountList?: IAccount[]) {
		if (!this.domainRolesArray.length && !this.domainRoles) {
			this.domainRolesArray.push(new DomainRoleForm({ domain: '', roles: ['View Reports - Required!'], errorMessages: {} }));
		}

		if (!this.domainRolesArray.length && this.domainRoles) {
			const domainRolesEntries = Object.entries(this.domainRoles);

			if (domainRolesEntries.length === 0) {
				// console.log('empty domain and roles');
				this.domainRolesArray.push(new DomainRoleForm({ domain: '', roles: [], errorMessages: {} }));
			}

			for (const [domain, roles] of domainRolesEntries) {
				let account = undefined;
				if (accountList) {
					const extractedAccountId = getStringAfterLastSlash(domain);
					if (extractedAccountId !== '*') {
						account = accountList.find(account => {
							return account.id == extractedAccountId;
						});
						if (!account) {
							continue;
						}
					} else {
						this.domainRolesArray.push(new DomainRoleForm({ domain: 'All', roles, errorMessages: {} }));
						continue;
					}
				}
				this.domainRolesArray.push(new DomainRoleForm({ domain: `${account?.name} (${account?.id})`, roles, errorMessages: {} }));
			}
		}

		return this.domainRolesArray;
	}

	setActAs(user: IUser) {
		this.actAs = { ...user };
	}

	getActAs(): IUser | undefined {
		return this.actAs;
	}
}
