import { Page } from '@monorepo/base/src/components/page/page';
import { Fragment, useState } from 'react';
import { PrimaryButton, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import styles from './edit-profile.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';
import { InputInfo, InputItem, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { UsersApi } from '../../../modules/apis/users.api';
import routes from '../../../modules/routes/index.routes';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { passwordValidation } from '@monorepo/tools/src/lib/utils/string';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export const EditProfile = observer(() => {
	const { dispatchLog } = useLogs();
	const [passwordState, setPasswordState] = useState<{
		oldPassword: { value: string; show: boolean };
		newPassword: {
			value: string;
			errors: {
				lengthError: boolean;
				integerError: boolean;
				letterCaseError: boolean;
				specialCharacterError: boolean;
			};
			show: boolean;
		};
		confirmNewPassword: {
			value: string;
			errors: {
				matchError: boolean;
			};
			show: boolean;
		};
	}>({
		oldPassword: {
			value: '',
			show: false,
		},
		newPassword: {
			value: '',
			errors: {
				lengthError: true,
				integerError: true,
				letterCaseError: true,
				specialCharacterError: true,
			},
			show: false,
		},
		confirmNewPassword: {
			value: '',
			errors: {
				matchError: true,
			},
			show: false,
		},
	});
	const [oldPasswordNetError, setOldPasswordNetError] = useState<string>('');
	const isValid =
		!passwordState.confirmNewPassword.errors.matchError &&
		!passwordState.newPassword.errors.lengthError &&
		!passwordState.newPassword.errors.integerError &&
		!passwordState.newPassword.errors.letterCaseError &&
		!passwordState.newPassword.errors.specialCharacterError;

	const navigate = useNavigateParams();

	const onEditUser = () => {
		if (isValid) {
			UsersApi.editProfile({ newPassword: passwordState.newPassword.value, oldPassword: passwordState.oldPassword.value })
				.then(() => {
					dispatchLog({
						msg: `Password changed successfully`,
						type: DispatchLogsTypes.Success,
					});
					navigate(routes.reports.index());
				})
				.catch(err => {
					if (err.response.status === 400) {
						setOldPasswordNetError(err.data.message);
					} else {
						dispatchLog({
							msg: `Password change failed`,
							type: DispatchLogsTypes.Error,
						});
					}
				});
		}
	};

	return (
		<Fragment>
			<Helmet>
				<title>Edit Profile</title>
			</Helmet>
			<BottomBar>
				<Flex height={'100%'}>
					<SecondaryText onClick={() => navigate(-1)} icon={'chevron-left'} iconSize={'20px'} width={'70px'}>
						Back
					</SecondaryText>
					<BottomBarActions>
						{
							<PrimaryButton
								disabled={!isValid}
								onClick={() => {
									sendGtagEvent({
										action: TrackingActions.Click,
										category: EVENTS.CLICK.INDEX_PAGE.USERS_PAGE.FORM.SUBMIT_CHANGE_PASSWORD,
										label: 'Change password page',
									});
									onEditUser();
								}}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'edit_user_button')] }}>
								Change Password
							</PrimaryButton>
						}
					</BottomBarActions>
				</Flex>
			</BottomBar>
			<Page>
				<div className={styles.wrapper}>
					<div className={styles.iconWrapper}>
						<Icon isMFP={true} color={'#5552EE'} size={'21px'}>
							key-01
						</Icon>
					</div>
					<div className={styles.description}>
						<div className={styles.primary}>Change Password</div>
						<div className={styles.secondary}>Enhance user protection with a new password.</div>
					</div>
					<InputItem flexDirection={'column'} gap={'16px'}>
						<InputInfo className={styles.inputInfoOverwrite}>
							<InputTitle>Old Password</InputTitle>
						</InputInfo>
						<Input
							type={passwordState.oldPassword.show ? 'text' : 'password'}
							after={
								<Icon
									onClick={() => {
										setPasswordState({
											...passwordState,
											oldPassword: {
												...passwordState.oldPassword,
												show: !passwordState.oldPassword.show,
											},
										});
									}}
									className={styles.passwordVisibilityIcon}
									isMFP={true}>
									{!passwordState.oldPassword.show ? 'eye' : 'eye-off'}
								</Icon>
							}
							className={styles.passwordInput}
							value={passwordState.oldPassword.value}
							onValue={value => {
								setPasswordState({ ...passwordState, oldPassword: { ...passwordState.oldPassword, value } });
							}}
							required={true}
							requiredAsterisk={false}
							error={oldPasswordNetError}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'new_password')] }}
						/>
					</InputItem>
					<InputItem flexDirection={'column'} gap={'16px'}>
						<InputInfo className={styles.inputInfoOverwrite}>
							<InputTitle>Password</InputTitle>
						</InputInfo>
						<Input
							type={passwordState.newPassword.show ? 'text' : 'password'}
							after={
								<Icon
									onClick={() => {
										setPasswordState({
											...passwordState,
											newPassword: {
												...passwordState.newPassword,
												show: !passwordState.newPassword.show,
											},
										});
									}}
									className={styles.passwordVisibilityIcon}
									isMFP={true}>
									{!passwordState.newPassword.show ? 'eye' : 'eye-off'}
								</Icon>
							}
							className={styles.passwordInput}
							value={passwordState.newPassword.value}
							onValue={value => {
								setPasswordState({
									confirmNewPassword: {
										errors: {
											matchError:
												value !== passwordState.confirmNewPassword.value ||
												passwordState.confirmNewPassword.value.length === 0 ||
												value.length === 0,
										},
										value: passwordState.confirmNewPassword.value,
										show: passwordState.confirmNewPassword.show,
									},
									newPassword: {
										errors: { ...passwordValidation(value) },
										value,
										show: passwordState.newPassword.show,
									},
									oldPassword: {
										value: passwordState.oldPassword.value,
										show: passwordState.oldPassword.show,
									},
								});
							}}
							required={true}
							requiredAsterisk={false}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'password')] }}
						/>
					</InputItem>
					<InputItem flexDirection={'column'} gap={'16px'}>
						<InputInfo className={styles.inputInfoOverwrite}>
							<InputTitle>Confirm Password</InputTitle>
						</InputInfo>
						<Input
							type={passwordState.confirmNewPassword.show ? 'text' : 'password'}
							after={
								<Icon
									onClick={() => {
										setPasswordState({
											...passwordState,
											confirmNewPassword: {
												...passwordState.confirmNewPassword,
												show: !passwordState.confirmNewPassword.show,
											},
										});
									}}
									className={styles.passwordVisibilityIcon}
									isMFP={true}>
									{!passwordState.confirmNewPassword.show ? 'eye' : 'eye-off'}
								</Icon>
							}
							className={styles.passwordInput}
							value={passwordState.confirmNewPassword.value}
							onValue={value => {
								setPasswordState({
									...passwordState,
									confirmNewPassword: {
										errors: {
											matchError:
												value !== passwordState.newPassword.value ||
												passwordState.newPassword.value.length === 0 ||
												value.length === 0,
										},
										value,
										show: passwordState.newPassword.show,
									},
								});
							}}
							required={true}
							requiredAsterisk={false}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'confirm_password')] }}
						/>
					</InputItem>
					<div className={styles.passwordErrWrapper}>
						<div className={styles.passwordErr}>
							<Icon isMFP={true} className={passwordState.newPassword.errors.lengthError ? styles.xIcon : styles.checkIcon}>
								{passwordState.newPassword.errors.lengthError ? 'x-close' : 'check'}
							</Icon>
							Minimum 8 characters
						</div>
						<div className={styles.passwordErr}>
							<Icon isMFP={true} className={passwordState.newPassword.errors.integerError ? styles.xIcon : styles.checkIcon}>
								{passwordState.newPassword.errors.integerError ? 'x-close' : 'check'}
							</Icon>
							At least one digit (0-9)
						</div>
						<div className={styles.passwordErr}>
							<Icon
								isMFP={true}
								className={passwordState.newPassword.errors.letterCaseError ? styles.xIcon : styles.checkIcon}>
								{passwordState.newPassword.errors.letterCaseError ? 'x-close' : 'check'}
							</Icon>
							At least lowercase and uppercase letters
						</div>
						<div className={styles.passwordErr}>
							<Icon
								isMFP={true}
								className={passwordState.newPassword.errors.specialCharacterError ? styles.xIcon : styles.checkIcon}>
								{passwordState.newPassword.errors.specialCharacterError ? 'x-close' : 'check'}
							</Icon>
							At least one special character
						</div>
						<div className={styles.passwordErr}>
							<Icon
								isMFP={true}
								className={passwordState.confirmNewPassword.errors.matchError ? styles.xIcon : styles.checkIcon}>
								{passwordState.confirmNewPassword.errors.matchError ? 'x-close' : 'check'}
							</Icon>
							{passwordState.confirmNewPassword.errors.matchError ? 'Passwords do not match' : 'Passwords match'}
						</div>
					</div>
				</div>
			</Page>
		</Fragment>
	);
});
{
	/* <InputItem flexDirection={'column'} gap={'16px'} alignItems={'flex-start'}>
						<InputInfo className={styles.inputInfo}>
							<InputTitle>Old Password</InputTitle>
						</InputInfo>
						<Input
							type={'password'}
							className={styles.inputWrapper}
							value={passwordState.oldPassword.value}
							onValue={value => {
								setPasswordState({ ...passwordState, oldPassword: { ...passwordState.oldPassword, value } });
							}}
							required={true}
							requiredAsterisk={false}
							error={passwordState.oldPassword.errors}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'new_password')] }}
						/>
					</InputItem>
					<InputItem flexDirection={'column'} gap={'16px'} alignItems={'flex-start'}>
						<InputInfo className={styles.inputInfo}>
							<InputTitle>New Password</InputTitle>
						</InputInfo>
						<Input
							type={'password'}
							className={styles.inputWrapper}
							value={passwordState.newPassword.value}
							onValue={value => {
								setPasswordState({ ...passwordState, newPassword: { ...passwordState.newPassword, value } });
							}}
							required={true}
							requiredAsterisk={false}
							error={passwordState.newPassword.errors}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'new_password')] }}
						/>
					</InputItem>
					<InputItem flexDirection={'column'} gap={'16px'} alignItems={'flex-start'}>
						<InputInfo className={styles.inputInfo}>
							<InputTitle>Confirm Password</InputTitle>
						</InputInfo>
						<Input
							type={'password'}
							className={styles.inputWrapper}
							value={passwordState.confirmNewPassword.value}
							onValue={value => {
								setPasswordState({
									...passwordState,
									confirmNewPassword: { ...passwordState.confirmNewPassword, value },
								});
							}}
							required={true}
							requiredAsterisk={false}
							error={passwordState.confirmNewPassword.errors}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'confirm_new_password')] }}
						/>
					</InputItem> */
}
