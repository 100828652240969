// import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { observer } from 'mobx-react';
import styles from './roles-selector.module.scss';
import { RoleSelector } from './role-selector/role-selector';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { IDomainRoleForm } from '@monorepo/controlled/src/models/domain-role.model';

interface IRolesSelector {
	onRemoveRole: (index: number) => void;
	onEditRole: (domainRole: IDomainRoleForm, index: number) => void;
	onAddRole: () => void;
	rolesOptions: string[];
	entityOptions: string[];
	entityLabel: string;
	allowAllRolesSelection?: boolean;
	domainRoles: IDomainRoleForm[];
}

export const RolesSelector = observer((props: IRolesSelector) => {
	const { onEditRole, onAddRole, onRemoveRole, entityOptions, entityLabel, rolesOptions, domainRoles, allowAllRolesSelection } = props;
	const isSelectedEntityAndRoles = domainRoles.every(role => role.domain && (role.roles || []).length > 0);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Assign roles</div>
			<BarLoader is={rolesOptions.length === 0 || entityOptions.length === 0} />
			<div className={styles.selectionWrapper}>
				{domainRoles.map((domainRole, index) => (
					<RoleSelector
						entityOptions={allowAllRolesSelection ? ['All', ...entityOptions] : entityOptions}
						rolesOptions={rolesOptions}
						entityLabel={entityLabel}
						onEditRole={onEditRole}
						onRemoveRole={onRemoveRole}
						index={index}
						key={domainRole.key}
						isAllowRemove={domainRoles.length > 1}
						domainRole={domainRole}
						errorMessages={domainRole.errorMessages}
					/>
				))}
			</div>
			<div
				className={`${styles.addAnotherRoleWrapper} ${isSelectedEntityAndRoles ? '' : styles.disabled}`}
				onClick={() => {
					if (isSelectedEntityAndRoles) {
						onAddRole();
					}
				}}>
				<Icon isMFP>plus</Icon> ADD ANOTHER ROLE
			</div>
		</div>
	);
});
