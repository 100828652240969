import { useEffect, useState } from 'react';

export enum CountDownEnum {
	Seconds = 'seconds',
	Minutes = 'minutes',
}

interface ICountdown {
	initialTime: number;
	onTimeEnd?: () => void;
	type: CountDownEnum;
}

export const CountDown = (props: ICountdown) => {
	const { initialTime, onTimeEnd } = props;
	const [timeLeft, setTimeLeft] = useState(initialTime);

	useEffect(() => {
		if (timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft(timeLeft - 1);
			}, 1000);

			return () => clearInterval(timer);
		} else {
			onTimeEnd && onTimeEnd();
		}
	}, [timeLeft]);

	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;

		if (props.type === CountDownEnum.Seconds) {
			return `${seconds}`;
		}

		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	return <div>{formatTime(timeLeft)}</div>;
};
