import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { Helmet } from 'react-helmet-async';
import { Outlet, useSearchParams } from 'react-router-dom';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import styles from '../../../styles/pages.module.scss';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useInventoryHeaders } from '../../../modules/hooks/theaders/inventory.headers';
import { useInventoryList } from '../../../modules/hooks/apis/use-inventory-list';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { MFPPStore } from '../../../modules/stores';
import { InventoryApi } from '../../../modules/apis/inventory.api';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import dayjs from 'dayjs';
import { useInventoryActions } from '../../../modules/hooks/actions/inventory.actions';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export const Inventory: FC = observer(() => {
	const { toastsStore, publisherStore } = useStores<MFPPStore>();
	const [searchParams] = useSearchParams();
	const accountId = searchParams.get('accountId');
	const { isLoading, error, data: inventoryData } = useInventoryList();
	const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);
	const { columns } = useInventoryHeaders();
	const { FiltersActions } = useInventoryActions();

	const onDownloadInventoryFile = () => {
		if (accountId) {
			setIsLoadingCsv(true);
			const account = publisherStore
				.getListStore()
				.getData()
				?.find(account => accountId.toString() === account.id.toString());
			const fileName = `Manifesto inventory - ${account?.name || accountId} - ${dayjs().format('YYYY/MM/DD')}`;
			const totalRows = inventoryData?.length || 0;
			if (totalRows <= 0) {
				toastsStore.addToast({
					msg: 'Cannot export empty file.',
					type: DispatchLogsTypes.Info,
				});
			} else {
				useDownload({
					httpStore: new HttpStore<undefined, File | null>({
						httpFunc: () => InventoryApi.downloadCsv({ accountId }).finally(() => setIsLoadingCsv(false)),
					}),
					fileName,
					extension: 'csv',
				});
			}
		}
	};

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Manifesto - Inventory</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }} debugProps={{ dataAttrs: [new DataAttribute('id', 'pagebar')] }}>
				<PagebarTitle>Inventory</PagebarTitle>
			</Pagebar>
			<ControlledTable
				onExportToCsv={() => {
					sendGtagEvent({
						action: TrackingActions.Click,
						category: EVENTS.CLICK.INDEX_PAGE.ACTION_LINER.EXPORT,
						label: 'Export Inventory CSV',
						value: window.location.href,
					});
					onDownloadInventoryFile();
				}}
				isLoadingCsv={isLoadingCsv}
				currentRoute={'inventory'}
				classes={{ wrapper: styles.padding }}
				label={'Inventory'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'inventory_table')] }}
				columns={columns}
				data={inventoryData || []}
				isLoading={isLoading}
				isError={Boolean(error)}>
				{() => {
					return {
						FiltersActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
