import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IPublisherPerformance, PublisherPerformanceModel } from './performance.model';
import { Devices } from '../enums/devices';

export enum PublisherColumnsNames {
	AccountId = 'id',
	AccountName = 'name',
	Type = 'type',
	CampaignId = 'campaignId',
	CampaignName = 'campaignName',
	Date = 'date',
	Device = 'device',
	Domain = 'domain',
	Geo = 'geo',
	IsDeepLink = 'isDeepLink',
}

export interface IPublisher extends IPublisherPerformance {
	id?: id;
	name?: string;
	campaignId?: id;
	campaignName?: string;
	domain?: string;
	geo?: string;
	deviceType?: Devices;
	isDeepLink?: boolean;
	type?: string;
}

export class PublisherModel extends PublisherPerformanceModel {
	id?: id;
	name?: string;
	campaignId?: id;
	campaignName?: string;
	domain?: string;
	geo?: string;
	deviceType?: Devices;
	isDeepLink?: boolean;
	type?: string;

	constructor(publisher: IPublisher) {
		super(publisher);
		this.id = publisher?.id;
		this.name = publisher?.name;
		this.type = publisher?.type;
		this.campaignId = publisher?.campaignId;
		this.campaignName = publisher?.campaignName;
		this.domain = publisher?.domain;
		this.geo = publisher?.geo;
		this.deviceType = publisher?.deviceType;
		this.isDeepLink = publisher?.isDeepLink;
		this.type = publisher?.type;
	}
}
