import { ApiDocumentaionRoutes } from './api-documentation.routes';
import { AuthRoutes } from './auth.routes';
import { BillingRoutes } from './billing.routes';
import { GeneralRoutes } from './general.routes';
import { InventoryRoutes } from './inventory.routes';
import { ReportsRoutes } from './reports.routes';
import { UsersManagementRoutes } from './users-management.routes';

const routes = {
	auth: new AuthRoutes(),
	general: new GeneralRoutes(),
	reports: new ReportsRoutes(),
	usersManagement: new UsersManagementRoutes(),
	apiDocumentations: new ApiDocumentaionRoutes(),
	inventory: new InventoryRoutes(),
	billing: new BillingRoutes(),
};

export default routes;
